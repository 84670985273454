
import React from "react";
import PropTypes from "prop-types";

import "./mjdbgModeler.css";

class MjdbgModeler extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="93fb0be7-8334-478b-a48e-0b3cdc90148b" className="mjdbgModeler">        <div data-layer="eab077fe-fb87-4692-a88b-71a20efa6472" className="layer1">            <div data-layer="f5a63a99-79bb-4d8a-9e9e-59605fc6be51" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="efe7a7e3-8b36-42a8-9be0-6b9290530e65" className="x10981">                <div data-layer="ddbd9f9b-c30b-41df-aaf1-07f3201e87e3" className="xc1bca96c">서류 전형</div>
                <div data-layer="0a1d4c58-7092-431e-b4ac-1eff1c3ead8e" className="x6b5e5d20">실무 면접</div>
                <div data-layer="822ad083-fd87-4357-bbbd-05cad974b409" className="x02c830d8">최종 합격</div>
                <div data-layer="67dba544-c648-4259-a7c3-c0d1f7243ac2" className="x10980">                    <div data-layer="995e4a1c-a37d-4572-9fb9-fe06acb349f0" className="x10979">                        <svg data-layer="4074f42e-2c35-4375-9a83-a3c25e9c2bda" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="531e92b3-1252-485b-a987-302c33d21f09" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="c8aa68b2-00fa-4c1f-866c-82aa7b875458" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="db6d1c99-ac9e-4711-8681-c055f33c8827" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="823980a2-cf43-4346-ba75-fdd4587e1d8b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="69f45597-237c-4ce1-b10b-389aeaa46267" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="9f735567-b14d-4161-872e-ca0744a3404b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="ca156a24-ab44-4b43-b6a2-96021b928453" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="89aa0414-8b94-4a6c-9496-2917fd8d74f9" className="x80b7ec4a">전형 절차</div>
</div>
                <div data-layer="0154fccf-a1d2-4864-96ce-778ac8243e4f" className="x74e366e0">임원 면접</div>
</div>
            <div data-layer="ca997a08-777a-4bf3-96cf-68a0f8ffe7e3" className="x10983">                <div data-layer="bdae25ae-11ef-4f18-9fba-143ee333f4cc" className="xdd10587c"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="dbb80e54-f9f4-4ad6-acce-513ee21473cc" className="x7fb219af">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="384188b8-708f-4af0-9361-3ab38f40737d" className="x4e9fcaf7">지원 방법</div>
                <div data-layer="0dac822b-75bf-480e-95ed-95badbf2589c" className="linesb12371e2">                    <svg data-layer="752d9246-4bc9-47a1-9fcd-280b7319cd71" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148ad7ea4ef"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="449b6f97-282b-49a4-a922-f28e82b9aaf5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1427f292329"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="1e305947-2210-4d2f-9e55-069d237e9a9c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149403aeb67"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="f53e48fa-1956-417b-9836-10f1d87e2bab" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143133d20d7"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="c69431a3-f84f-4743-b614-fb3b0c4a1e04" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1441135c7ef"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="217d6926-800b-47b1-9d1c-0c4496961eb6" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="f1f4bae3-2820-4c96-bfa6-89410b7f91cc" className="x10987">                <div data-layer="87431509-b8a1-4575-8010-5f42622cecd0" className="x3Ex3dEx">관련 업무 경력 3년 이상<br />스타일라이즈한 배경 제작에 능숙하신 분 ex)지브리, 원신<br />3D툴 활용능력이 좋으신 분 ex)섭스탠스, 스컬핑<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="a5e376d5-36df-4dda-9f1f-d7db6260c234" className="x2905bc5e">자격 요건</div>
                <div data-layer="9c576784-6064-4491-bf96-2d3149523a19" className="lines747374e0">                    <svg data-layer="3bc3dbb2-910e-4716-9b4b-00b671eb5097" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148a3eebab9"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="7c2398b5-adbd-4231-83cb-2c61b130cb02" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14284fddc8b"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="3fa5aeb9-45fc-42c0-a918-b6fbf46a797f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149ebfb0cdc"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="ba297e81-0f50-49a6-9d80-64987781a506" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143d17a313b"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="7e14bb41-f132-4dc3-a8f6-27d5c06ffe2e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1446d5ea887"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="d1f29fd3-6089-461a-9b1b-7e71c367c3e9" className="x10985">                <div data-layer="8bf3bd23-559c-42bc-bb88-4398fc02755d" className="x1133e959">아트에 대한 기본기가 좋으신 분<br />프로젝트 완수 경험</div>
                <div data-layer="eee65833-e1c6-4810-a6de-cbd73891f47d" className="xd9f0e93f">우대 사항</div>
                <div data-layer="a76abdb5-11ab-4486-b56e-30dc4662e419" className="lines42a8b9c1">                    <svg data-layer="d2c06d18-579c-4bf0-9475-12957fa4ab5c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14814ecbbf2"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="7bd94855-763f-4921-9377-9db91a86ef42" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142603ebd26"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="3d824583-4f94-4a5b-a7fe-aba354c02832" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x14926bd2d7b"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d9256c6d-ccd1-4e4c-a2bf-f0a96994d8f4" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143a51e297a"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="633f7c81-7a2b-436c-9024-a83bb1abd824" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1443f240fe7"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="55a7cdba-a25d-4dd4-b362-c3d9156c51cb" className="x11012">                <div data-layer="b5aab2c8-e3c6-4426-a125-e5d6b66892ca" className="xL3d">하늘섬세계를 배경으로 하는 글로벌 프로젝트 L의 3D배경 제작 업무</div>
                <div data-layer="7295af5c-bd36-4a27-ad76-a7742612e070" className="x">주요 업무</div>
                <div data-layer="be7c4eb8-8f6d-44e1-8a42-f92d57c0a24b" className="lines">                    <svg data-layer="b47151ca-64c9-490f-ba05-d127322eaeb7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="35e8a9ba-e3a7-4359-a387-620ab55e48a2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e9b36b0c-46f3-4bf3-8fe0-dabb05e047a7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d09f85a5-3778-4224-82a7-ce04d77222f1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d56b4e5e-e3bc-41be-9c91-5e0bd6923559" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

MjdbgModeler.propTypes = {

}

MjdbgModeler.defaultProps = {

}


export default MjdbgModeler;
          