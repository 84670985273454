
import React from "react";
import PropTypes from "prop-types";

import "./jdcharacterartistmsl.css";

class Jdcharacterartistmsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="a15f437d-1e5a-469c-8984-5597c049e0a0" className="jdcharacterartistmsl">        <div data-layer="41dba332-cf53-4c61-b2d1-5e10def47173" className="layer1">            <div data-layer="b8b2e13b-1ec3-4ec6-bb84-4c9cf2b7e1e7" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="f687c867-705d-486c-98d0-e3b86ccd75ec" className="x10981">                <div data-layer="e579045b-61f3-469c-ba44-ab498e269ae9" className="x8e67a0cc">서류 전형</div>
                <div data-layer="2841ce83-cd48-4ba5-a8aa-0898358abfb6" className="x29fbb9c7">실무 면접</div>
                <div data-layer="03227988-bf85-46e8-b07d-c8d4bd71b814" className="xf651c1e3">최종 합격</div>
                <div data-layer="f532f5eb-0534-433b-9182-b002effb9989" className="x10980">                    <div data-layer="68235c9e-1771-4857-9712-baae8d66429b" className="x10979">                        <svg data-layer="564d68f7-ca6a-4b2f-b65a-700cb0b3c4eb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="80946325-46fd-478c-bed0-d8bbd8aee511" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="eab82e4b-b9ab-42cf-8fbf-22e0ed8ec415" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="27c45e7f-94e6-49b4-9b6c-ee2917dee42e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="fd25b9d2-c490-48ce-b433-d0b98b9dc8d8" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="6ba0678f-7d0c-46c3-bc07-9dfb86ddbbec" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="4024664e-ff2d-4e35-92b5-479c58c3750f" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="31663a57-5024-4b8e-9605-3a8849a90b0d" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="c32a0bbf-6510-4460-967f-8fc6ea9c18ce" className="xc573191e">전형 절차</div>
</div>
                <div data-layer="04340780-c065-40d9-9ea2-62aa5c2d2a85" className="xcb9362a3">임원 면접</div>
</div>
            <div data-layer="c0a403fe-4f7b-4520-bf47-177f23b352d0" className="x10983">                <div data-layer="858238a7-cca2-4402-9b53-dc9b52f99688" className="xd3b994d1"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="7211d308-848b-41e4-b5d7-397c9ba6446d" className="xca2c540a">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="82bea577-a065-4e04-82a0-5d38ee26e0f4" className="xa2a34545">지원 방법</div>
                <div data-layer="09c53790-47a9-467b-a4db-61f6d755e9f4" className="linescf3ff70a">                    <svg data-layer="23c1606e-2635-4c3e-bac5-5fc722b4235a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148566184ce"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="aab8d964-bab2-4cb1-a1ec-a8da72224317" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142b6db9c4b"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="48a3d139-5e8c-470c-822f-e40da6d80cdb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1492e47b1e6"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="95d5dd26-cd4e-4be4-8b61-4dc6925724f2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143d33c68e3"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="c034e48b-2020-43a8-9731-af1c5f0e14a6" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144a1bc66ed"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="642dd351-c5fa-445a-ab23-47ebbd911e76" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="1850e607-214d-4d2f-b1bb-49bd3dede30c" className="x10987">                <div data-layer="ca6e160d-411f-44f0-b18d-e8b25153ad0a" className="x4">관련 경력 2년 이상인 분<br />다양한 컨셉작업 가능한 분<br />재패니메이션풍의 매력표현에 능숙한 분<br />화사한 색감과 조형감 좋은 분<br />원활한 커뮤니케이션 가능한 분</div>
                <div data-layer="6c805802-542c-4025-bfa7-c3ea92476041" className="x738a8511">자격 요건</div>
                <div data-layer="b43d9754-0395-4f87-9dd6-975509c2089d" className="lines04a1f32b">                    <svg data-layer="6e6bd3ee-6265-402c-a194-2dade8e9c73a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1485cb4a286"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="5a68b249-dd06-4ca5-b863-50cbcd622f8a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142ab13a273"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="bbccf2d2-4542-4fb1-97f3-a2da20a95461" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149693ad5ca"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="964c5c93-4764-49b1-ab9a-414c44fcd40f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143cd3f32b6"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="0e76f29d-c317-4734-91f9-9cd2b17d2949" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1449a380b03"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="61647857-f65a-402e-9f25-ddf242a43f02" className="x10985">                <div data-layer="e98fd9ac-617e-459a-a3d5-a6d757042b0b" className="x3d">캐릭터를 3D로 구현하는 과정을 이해하고 원활한 소통이 가능한 분<br />평소 애니메이션을 즐겨보시는 분<br />관련학과 전공자</div>
                <div data-layer="e45aa229-ae53-422d-900d-d1e25738ca3e" className="x31ff45cc">우대 사항</div>
                <div data-layer="ddc3e60a-363b-4ace-a266-f98fd33d83e5" className="linesfe0cd17f">                    <svg data-layer="d233897b-6e72-4db2-af4c-6689a73b6304" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14898f41927"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="ace815c0-abf0-44cb-a9b7-6645debbbb05" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14298a5b352"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="947c94cd-ae8c-4748-bbc6-6a40bfec8224" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1492275f5f3"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="bd225f53-3aad-4df7-964d-a42b4da7cf5d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143fc0ca70d"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="143896ec-1c20-494d-80cf-37d5f3268304" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x14480ed27fd"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="2d30296d-87f4-49c1-8e89-e996ebe2f5cd" className="x11012">                <div data-layer="e0063ed3-ce7a-4485-9529-4bad76f6d0d9" className="xRpg">글로벌 원빌드 수집형 RPG ‘몬스터 슈퍼 리그’의 캐릭터 및 몬스터의 컨셉 원화</div>
                <div data-layer="cc3be346-3996-49f2-9a7d-3284d2e48cc8" className="lines">                    <svg data-layer="f9585810-55d8-4cac-b959-09233fea5c2e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="12747b64-3f9e-4c16-88f3-bd2ff1ce290c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="bd528078-c553-4684-9f14-e491d9e510ce" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="409507fe-51cf-47ee-91eb-792b273844be" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="8bd33fe5-1f5f-4b10-a817-baf023bc6273" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="1c1c41cf-6872-47f5-bd52-1567636b1f3d" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdcharacterartistmsl.propTypes = {

}

Jdcharacterartistmsl.defaultProps = {

}


export default Jdcharacterartistmsl;
          