import React, { useMemo, useState, useLayoutEffect, useEffect, useCallback } from "react";
import { throttle } from 'lodash'


const YoutubePlayer = (props) => {

    const [width, setInnerWidth] = useState(0);
    const [targetH, setTargetHeight] = useState(0);
    const [targetW, setTargetWidth] = useState(0);

    const handleFit = useCallback(() => {
        // target width
        setInnerWidth(window.innerWidth);

        const innerW = window.innerWidth;
        const innerH = window.innerHeight;
        const h = innerW*0.5625;
        if(h>=props.targetHeight) {
            setTargetHeight(h);
            setTargetWidth(innerW);
        }
        else {
            setTargetWidth(props.targetHeight / 0.5625)
            setTargetHeight(props.targetHeight);
        }
    });

    const throttledResize = useMemo(
    () =>
        throttle(() => {

            handleFit();

        }, 300),
        [width]
    );

    useLayoutEffect(() => {
        window.addEventListener('resize', throttledResize);
        return () => {
            window.removeEventListener('resize', throttledResize);
        }
    }, [throttledResize]);

    useEffect(() => {
        // initial hit
        handleFit();

    }, []);

    useEffect(() => {

    }, [targetW, targetH])

    /* top 화면용
    return (
        <div className="videoframe" style={{width:`auto`, height:`${props.targetHeight}px`, position: 'relative', overflow:'hidden', display:'block'}}>
            <center>
            <div className='videoframe_inner'>
                <iframe src={`https://www.youtube.com/embed/${props.videoid}?controls=0&autoplay=1&mute=1&loop=1&disablekb=1&iv_load_policy=3&playlist=${props.videoid}`}
                    scrolling='no'
                    frameBorder='0'
                    allow='autoplay; encrypted-media'
                    allowFullScreen='0'
                    title='video'
                    style={{width:`${targetW}px`, height:`${targetH}px`, pointerEvents:'none', marginLeft:'50%', transform: `translate(-50%, ${(props.targetHeight-targetH) / 2}px)`}}
                />
            </div>
            </center>
        </div>
    );
    */
    return (
        <div className="videoframe" style={{position:'absolute', width:`${props.targetWidth}px`, height:`${props.targetHeight}px`, top:`${props.targetY}px`, transform:'translateX(-50%)', marginLeft:'50%', overflow:'hidden', display:'block'}}>
            <center>
            <div className='videoframe_inner'>
                <iframe src={`https://www.youtube.com/embed/${props.videoid}?controls=${props.controls}&autoplay=1&loop=1&disablekb=1&iv_load_policy=3&playlist=${props.videoid}`}
                    scrolling='no'
                    frameBorder='0'
                    allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen='1'
                    title={`${props.title}`}
                    width={`${props.targetWidth}`} height={`${props.targetHeight}`}
                />
            </div>
            </center>
        </div>
    );
};

export default YoutubePlayer;