
import React from "react";
import PropTypes from "prop-types";

import "./jdprogrammermsl.css";

class Jdprogrammermsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="bb74ce8e-7802-4034-8285-f13b75411d06" className="jdprogrammermsl">        <div data-layer="a195aed9-ec68-495a-991d-dddc000bf6f7" className="layer1">            <div data-layer="90ff62f3-2cf5-47e8-983d-4bdeedb8e3fa" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="56b8d228-eaf1-4a18-b715-e12c60092ee5" className="x10981">                <div data-layer="a9d5d256-d35f-4f5a-921b-df7fb354f56d" className="xdd08faad">서류 전형</div>
                <div data-layer="cfc51acf-7c60-4791-981c-5ea9ba815cac" className="x68780eb3">실무 면접</div>
                <div data-layer="564638ec-b3c9-4a2c-ae21-67889af57e8a" className="xc2084c59">최종 합격</div>
                <div data-layer="25882c1a-b3b5-43ec-bfda-4cd6ba09b58b" className="x10980">                    <div data-layer="78a557fd-f96d-47f6-a3c0-28bc1ac1f9a0" className="x10979">                        <svg data-layer="ccc93e00-7646-4439-ad78-65565793a78f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 896.95703125 3.19000244140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="4ddd60a7-620b-441f-ae87-55cb15a0e766" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 248.8359375 3.19000244140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="c78fda06-657e-4f00-90fc-231c0910cedd" preserveAspectRatio="none" viewBox="-1.5966796875 -1.59710693359375 356.603515625 3.6617431640625" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="3f7bf379-95ea-42e7-8e4c-566f658e14a9" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 80.427734375 3.19000244140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="695b8112-6c0d-4d06-8cca-1467876f6db1" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="7178f1d5-98c7-4299-b94d-5411d25da825" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="5595d717-6524-495c-ac31-04429790f633" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="5091550b-fd50-43ef-97d1-363f2d2ae133" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="0ebfac4f-5e49-4cc3-8e2f-9192c2648a58" className="x2a02440b">전형 절차</div>
</div>
                <div data-layer="72216cc8-86ba-4fd0-917f-123010bf0d17" className="x25778df9">임원 면접</div>
</div>
            <div data-layer="45d5c3de-c659-4220-a24b-f07106ff464f" className="x10983">                <div data-layer="bafb4b8c-34a9-410e-800b-2bc11818483a" className="x8e8b77e3"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="4b54997a-7196-44f2-8203-e9a733a75d47" className="x4f077057">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="240189b7-9107-48c2-be6e-8463178e1cb5" className="xefe9ade1">지원 방법</div>
                <div data-layer="df391bff-ac17-4930-aeab-6fe8e0cb2a1c" className="lines93517395">                    <svg data-layer="7afe0393-b0fb-44f8-95c1-ca3bfe1eea9f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 248.8359375 3.19000244140625" className="x1482b29e236"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="0448e9cc-9a00-478d-b69d-945233ac5109" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 248.8359375 3.19000244140625" className="x14299efbf04"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="99fbed7b-19c2-4dd1-8562-4d10e38b7561" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 310.4921875 3.19000244140625" className="x1492277bc5d"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="43c5d95c-2d2d-4646-9a9d-40e6f52edbe7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 310.4921875 3.19000244140625" className="x143aa265ac0"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="ac817af3-7bd6-4430-b7c9-cacba005c3f9" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 70.8076171875 3.19000244140625" className="x144809d474c"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="89f962ef-0d7a-42d1-a1e5-5091e9aa543b" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="d13bc3b7-e438-4538-9b50-9a44bc175ed0" className="x10987">                <div data-layer="fbb5d29a-f8cf-4f69-9970-635e85dd5f05" className="x3CC">미드레벨 이상의 업무 능력 / 3년 이상의 모바일 게임 개발 경력<br />C++, C#, Python 언어 중 하나를 능숙하게 사용하는 분<br />개발에 대한 열정이 있고, 문제 해결에 재미를 느끼는 분<br />꼼꼼하고 책임감 있는 분<br />새로운 언어, 환경에 대한 두려움이 없는 분<br />팀 단위 작업 및 타 직군과의 협업에 대한 경험이 있는 분</div>
                <div data-layer="df49fbb1-6f8e-46d4-9961-9e54a371c5c7" className="xd04d70af">자격 요건</div>
                <div data-layer="1d41751c-c00b-450d-b7d7-beb45c90ab7e" className="lines3a412152">                    <svg data-layer="eb10b0d5-f86c-48e8-8d86-d432024e7825" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148f9699047"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="7c55b82c-a532-4a6f-8da3-b1031434d694" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142c8cddd32"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="d594a13b-3360-4364-a317-6047eaaf5f02" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x149a2da2fdf"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="339b31b0-5c11-4131-b051-184a42f51f9d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x14384c2cb5b"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="2a24a60e-82cb-4448-9439-114a7e6b97e7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144eeb60dac"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="66d25eda-bc44-49ab-9e84-8dd818461fc2" className="x10985">                <div data-layer="be6b497d-7729-4ba1-b768-c72d4b59e8b8" className="unityAndroidiosDbDbAwsGraphics3d">Unity 또는 기타엔진으로 서비스 오픈 및 유지보수 경험<br />모바일(Android/iOS) 플랫폼에 대한 이해와 개발 경험<br />모바일 상용 프로젝트 라이브 서비스 경험<br />서버 개발 경험 및 DB 최적화등 DB를 능숙하게 다룰 수 있는 분<br />AWS 사용 및 실 서비스 경험<br />Graphics 및 3D 경험이 풍부한 분<br />각종 개발 및 형상관리툴 이해와 경험<br />해외 서비스 경험<br />하나 이상의 외국어 커뮤니케이션 능력</div>
                <div data-layer="f1a4ad95-7000-4c31-91fb-51778964d0c5" className="x3bb62014">우대 사항</div>
                <div data-layer="b19e30ff-f009-4b85-aaa7-398af9b01911" className="linesae7337f0">                    <svg data-layer="3ada8515-ef4d-4552-9d8d-47a338330072" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148e9b00c37"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="bea88cfd-e6c0-4aaa-b076-e7be34cca4f2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142ebcc5972"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="8d3faa56-81fd-4359-8aea-ec06a1c29295" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x1493140f405"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="5d381277-2a64-40b6-9983-0598d57014ed" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x14368cf3be5"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d849b62a-c261-4b2f-8727-2352621e3165" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x14468359475"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="016f7687-4cc6-4b03-9d54-0256af4f8eeb" className="x11012">                <div data-layer="7db615a4-575e-444c-a474-0aca11024777" className="xAndroidiosRpgUnity">글로벌 서비스 중인 모바일(Android/iOS) RPG게임 몬스터슈퍼리그의<br />클라이언트 및 서버 개발 (Unity 기반 어플리케이션)</div>
                <div data-layer="7959206b-1b3d-46d5-be0e-6a5422a60531" className="lines">                    <svg data-layer="b356e360-0d3a-4e0b-ba4a-eb11abc334d2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="2565837d-d833-471e-9057-131afdbfc3a0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="dc59ad0a-a8bd-4cda-8309-847120cb4c28" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="a568d850-2ef2-44f0-b594-137431b50cdc" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="27b86047-0446-49d7-aadf-8025f128934f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="ece1846f-01cd-4a03-9832-e98382f25e5b" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdprogrammermsl.propTypes = {

}

Jdprogrammermsl.defaultProps = {

}


export default Jdprogrammermsl;
          