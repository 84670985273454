
import React from "react";
import PropTypes from "prop-types";

import "./mjdgameDesigner.css";

class MjdgameDesigner extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="b18da9fa-25d6-4c8c-9b96-7a2a05e08d65" className="mjdgameDesigner">        <div data-layer="82afdaf2-8a67-46fd-9f75-da2c13b722d5" className="layer1">            <div data-layer="5ee67179-cb02-4f9a-8aa5-0576e91729b2" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="daeedb01-d19d-421d-ba9d-961ff0f04f14" className="x10981">                <div data-layer="b4625fde-d066-4479-a3e1-6077e988a87b" className="x3c805f8c">서류 전형</div>
                <div data-layer="0fb1821c-f576-40eb-8ae8-bf14145ef7d9" className="x02df6c04">실무 면접</div>
                <div data-layer="49425d38-4526-40e2-ba60-0e684254312e" className="x9d3ce415">최종 합격</div>
                <div data-layer="40cf3d30-f510-4c4a-af8e-695f8c3d2360" className="x10980">                    <div data-layer="9c6bfe17-a98d-4439-9a05-9c3d419e9b58" className="x10979">                        <svg data-layer="e6f336ee-6294-4041-84bf-b0b2eee4d898" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="762c446e-096a-48a1-a303-785701ca9968" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="a5e435e8-dbb9-458e-b266-12d91884a9e7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="728aa661-84a8-4fdd-9f60-93f0462364de" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="70a4627a-f185-416d-9d93-fc7b17b294cf" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="75686b2b-5a28-4df9-8513-186bd01bba69" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="ed804328-da88-4c90-a1e7-ae21dc2b8a8b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="bbe58f3e-fb4e-4d9f-94ad-8f9bc0ab8fbd" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="27f92c04-a758-499b-8ec1-3697b3576b38" className="xb4070c70">전형 절차</div>
</div>
                <div data-layer="d60f49de-f41c-4326-906d-da922e4176db" className="x47d49caa">임원 면접</div>
</div>
            <div data-layer="a9c202de-41b2-4228-8541-35206ec87e85" className="x10983">                <div data-layer="92dc06f7-25a3-4755-bc27-12c613af2624" className="x9bb15843"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="64707a25-8bca-4a55-821c-7ce4a7d6833b" className="x8f622597">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="c3601bb1-3d2c-4c03-a96c-57ec0b00cc59" className="xe8c67c51">지원 방법</div>
                <div data-layer="ac7437c0-afdb-4ee5-9509-7b6119be88f7" className="lines939baf6b">                    <svg data-layer="c4762af8-6363-4c07-9487-7cf137a59728" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1488ba117b7"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="8d382f5d-d76b-4f45-9cef-c91d5b73d940" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1423c147b41"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="56ffbd4c-724e-45f2-afa2-3771b99d734e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149a34525c8"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="1aa410f8-15e8-419a-b38c-85c2c03325fa" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143a41e9d8f"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="df0e3c93-c29f-4fb0-9e0b-5effc44f9d17" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144c3367630"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="036825a6-64a6-492f-8d54-1b184c5b305e" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="ce5eea04-3e0b-4199-bd00-3363282262ff" className="x10987">                <div data-layer="196c5c5f-2790-4f47-b2cb-b01fc5633985" className="x3">관련 업무 경력 3년 이상의 분<br />환경 및 우선순위 변화에 신속 적응<br />문제 해결 능력 및 분석적 사고<br />구체적인 업무 내용이 포함된 포트폴리오<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="dcb48d1b-58db-45b7-bf00-b87e0d260da7" className="xb79e96d2">자격 요건</div>
                <div data-layer="bf18e92c-6847-4dba-bc1e-c05d3dfed849" className="lines89aec1ba">                    <svg data-layer="2750d1e0-a2c3-41ed-9431-becceb47a947" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148b490ab47"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="fe068ce4-f9d1-4a49-8c82-053c94b4af80" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142c3b7d346"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="a2d89a35-5da0-4707-a655-d4f5277d3521" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149bf4edcb9"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="2b9a6f2d-bc6f-4106-be3e-e33615446f68" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1430ba553ca"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="283c67f1-3b3f-4c60-840e-9974df78fbfb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144e4c1c7ab"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="ee05eb6d-0330-4b28-a897-51e71e0b0a74" className="x10985">                <div data-layer="8f10a615-7924-456d-a2af-24195b6cf419" className="xRpg">모바일 RPG/전략 게임 라이브 경험자<br />최초 개발 단계부터 런칭까지 경험자<br />모바일 게임 산업 및 게임에 대한 관심</div>
                <div data-layer="bb93354f-47b3-45ba-8833-19f0375ca85c" className="xb8a59c71">우대 사항</div>
                <div data-layer="13aa4d11-e8d1-491c-8c95-ca55637486e1" className="lines08a69ae4">                    <svg data-layer="04a2724e-ac4a-441d-9851-7fa72aa7ad78" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148534d71da"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="f44fe32a-f40c-4bb9-ac67-8ea00803c5b6" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14209ffbe9c"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="72894166-9ebd-4f34-8c91-593267993947" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x14999bbd951"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="14f710cd-0357-43d4-b51d-5c984fcfec8f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143f8956edc"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="74c36d33-6acb-42a1-9c23-4bae519a0e9b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x14499960640"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="dade1e24-552c-46d4-9b5f-4ae4738b0859" className="x11012">                <div data-layer="0b437f4e-2bb1-4015-91b3-5abf5fa8e3d1" className="xL">신규 글로벌 프로젝트L의 전투 스킬/시스템/밸런스 기획</div>
                <div data-layer="17495c39-ffe7-4117-8d72-a0429f82f02d" className="x">주요 업무</div>
                <div data-layer="9eb65e54-3a26-41d4-adb9-d27f7e4e640a" className="lines">                    <svg data-layer="e6329595-61d6-4636-80d3-654606585a75" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="850a9725-9f67-4e1c-bd5b-eff8f82af4d2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="bf1cb9c3-0d02-4afe-94a6-b4335a1b44b9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="72701379-1a0c-41a1-a183-5dbbe19b1fdd" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="97a67ab2-0134-4b70-b6ae-0887d526ee8d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

MjdgameDesigner.propTypes = {

}

MjdgameDesigner.defaultProps = {

}


export default MjdgameDesigner;
          