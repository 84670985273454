
import React from "react";
import PropTypes from "prop-types";

import "./mjdmarketingLead.css";

class MjdmarketingLead extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="b9214e41-561b-4155-8b26-6f598b3e7747" className="mjdmarketingLead">        <div data-layer="79e3dd93-4ff7-4a98-898a-c9fcccaf21a2" className="layer1">            <div data-layer="5acd4f1e-d41a-4b53-ab2e-14625b42be30" className="thinkSmartStudyGamesIp2024Rpg">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="555f12a0-970e-4554-b25f-d4e3b1e159f4" className="x10981">                <div data-layer="6e190e04-332d-4763-9216-ae05b2c45428" className="xb98fe8e4">서류 전형</div>
                <div data-layer="5408c0a4-ef54-413a-a1f4-a95491c684df" className="xb125cbbd">실무 면접</div>
                <div data-layer="955829d8-a607-48fb-b49b-ec55e3871529" className="x67ce1ebf">최종 합격</div>
                <div data-layer="aab3680b-2e33-4ab4-a887-e238dd5f9547" className="x10980">                    <div data-layer="0445c0e6-e7e3-4d7e-a77a-0a17297129c4" className="x10979">                        <svg data-layer="56cd426f-7577-4b88-8b7d-9533bcdc9f4b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 501.189453125 3.19000244140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="646a25e1-0de9-4688-958c-506d07bb9f8d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 126.189453125 3.19000244140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="616f4040-71eb-43ad-a46b-b9eaeef37fdf" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 213.189453125 3.19000244140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="4cfe8293-42ac-437f-8221-16861ad49223" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 53.189453125 3.19000244140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="1da3014e-1bb9-47a7-b638-bac06e248c24" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="4dcd6c98-68c6-4a74-94f9-5341a0255f44" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="8eef3744-5d65-49b4-9a3b-8fc2d750c176" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="bb9d7a9c-c5df-4227-8990-09c3e35d315f" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="7196f5a6-7279-4764-8941-ee2667dcb6ab" className="xde689e2f">전형 절차</div>
</div>
                <div data-layer="82d83d72-94b8-4bdf-9704-33e8c1afbc7c" className="x71e294d7">임원 면접</div>
</div>
            <div data-layer="4cb79e53-912d-4aef-a4fe-95172a6af570" className="x10983">                <div data-layer="e6388f09-9576-49b9-915b-61082588535b" className="xe18e288c"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="1eacb269-8347-4cba-9902-d8e6fc01b3ce" className="x789c25b7">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="0a9f410c-2b3a-440e-ba6a-e973505eb702" className="xcbc353ff">지원 방법</div>
                <div data-layer="71456c10-dd78-48cc-bb64-7036084affbe" className="lines19357a70">                    <svg data-layer="f077daac-e9ba-4143-9d72-5d269ce74237" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x148e5e9d931"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="f42563d7-58fb-426f-bd0c-8b1db2ec9cb3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x142b894d1aa"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="9a580efb-6b00-455c-82f8-a7053a7c7afa" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149323ecd1e"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="4680f56d-4c08-4297-81f2-2ff99091336e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x143d6d0864b"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="5602befa-8396-4355-abc2-3839123e2fbd" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x144214e9bf5"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="6f06f6f9-c614-4d26-be91-ad634112f9d8" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="dd656e33-a5ed-4775-96fa-40d1ac5a27fc" className="x10987">                <div data-layer="e58ea7a8-432d-45f9-8927-6e55f70f6686" className="x5DataDrivenDecisionAttributionToolAppsFlyerAdjust">관련 경력 5년 이상인 분<br />마케팅 전략 수립 및 집행 경험이 있는 분<br />Data-driven decision에 대한 이해도가 높은 분<br />Attribution Tool 사용이 능숙한 분(Apps Flyer, Adjust 등)<br />글로벌 트렌드에 대한 이해도가 높은 분<br />비즈니스 영어 구사가 가능한 분 (이메일, 문서 작업)</div>
                <div data-layer="43e05195-d8a4-4d5b-ab06-9c60b101bbfd" className="x087ccabe">자격 요건</div>
                <div data-layer="f38a543f-e2ef-4359-8945-f757aca3d6ad" className="lineseb7cdcbb">                    <svg data-layer="2006905b-1bf0-47c1-9a92-e134feb0003a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148baccdc4a"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="2476281e-93b1-4216-991f-608637f7c4f4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142b9f06b04"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b669cc26-9e36-4018-8db6-1eb02c9bbfd3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149bc38a11b"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="6e507ce9-50d9-42a3-8852-d957f386f6eb" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143c69605b7"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="8bcb25ad-8f65-44a0-bbc3-ef27b6ec6351" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x14428f6a18f"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="ac3f42d9-76d5-4850-89dc-e74e2e440bb5" className="x10985">                <div data-layer="e59b1c28-e4ba-4dcd-a831-9c435ad50a50" className="xd5189f94">비즈니스 영어 회화가 가능한 분<br />디지털 마케팅 대행사 또는 미디어랩 근무 경험이 있는 분<br />소셜미디어 콘텐츠 전략 수립 및 콘텐츠 기획 경험이 있는 분</div>
                <div data-layer="a3a5847f-1e90-40cd-892e-8a0743fb8481" className="xd229714a">우대 사항</div>
                <div data-layer="1923798d-c2ef-4072-85ce-6c61222415b7" className="lines7600620f">                    <svg data-layer="625b49ca-731a-426e-b3eb-1f5911d5c7ac" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x1480dd7cfb4"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="192ef102-f001-43c2-b072-226cac44acc1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x142bc107f98"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b7e6343c-8c69-4117-ad8b-b90538776737" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x14971a9bc20"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="0ce8ac3b-8a06-4278-ac1c-ec6c82dc84e6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x143bf6024b1"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="dcd4cbc2-1e69-4754-80f4-adc9b320e12d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x14473c3c443"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="02b9f932-f4c6-4a71-8b88-aef8471cd20c" className="x11012">                <div data-layer="2d9daa25-f67a-4080-bd86-484935648f21" className="xUa">글로벌 마케팅 전략 및 UA 계획 수립 및 실행<br />게임 광고 크리에이티브 기획<br />글로벌 광고 네트워크를 통한 광고 집행 및 리포팅<br />마케팅 지표 관리 및 효율 분석</div>
                <div data-layer="e3e54f53-9c4d-4bed-aadf-b3f2173ed7e0" className="x">주요 업무</div>
                <div data-layer="c6350c10-48a9-46c1-a79c-dad7e391e2e9" className="lines">                    <svg data-layer="dc3811a6-676a-49f5-be2f-2e4ead5f7fa0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="29261c32-b98d-40f3-92a8-14e3eda1ae20" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="2123f916-c8a5-4bc7-87d7-bc32faa50a72" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="8b5b5406-7344-412e-80bc-215d849e5644" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="a1004440-b86b-4664-b13b-fbca540d7fe7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

MjdmarketingLead.propTypes = {

}

MjdmarketingLead.defaultProps = {

}


export default MjdmarketingLead;
          