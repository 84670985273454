
import React from "react";
import PropTypes from "prop-types";

import "./mjdbgArtist.css";

class MjdbgArtist extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="52b19380-8e24-43b3-b12a-5b0fcfbe5578" className="mjdbgArtist">        <div data-layer="b36f4df7-2b2b-476f-9e8e-27394e11ad52" className="layer1">            <div data-layer="0dba0aad-0d34-4949-a53b-1eb9b2a5e2bf" className="thinkSmartStudyGamesIp2024RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="850fc9f8-05d3-4408-a115-f8db7a50049b" className="x10981">                <div data-layer="a81a6a25-51d3-4376-b3ad-ca689b63d217" className="xb29da396">서류 전형</div>
                <div data-layer="b69b3688-ad4b-4b8e-9f50-84f538ed58d5" className="x115dd3f8">실무 면접</div>
                <div data-layer="3e6bb0fe-794e-4b0a-acb8-1a947020ed71" className="x9f8fa594">최종 합격</div>
                <div data-layer="2a9c9685-3e11-4204-a516-57c1be6f604f" className="x10980">                    <div data-layer="3d267029-aa96-4a10-b523-9f35af7f8ca8" className="x10979">                        <svg data-layer="1e107062-b691-4b83-87be-ac27bdefd1c7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 501.189453125 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="a6e4482b-5871-4b47-830b-cb8bc5f8019f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 126.189453125 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="728ebc8f-9490-456f-8b37-5696f0238afc" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 213.189453125 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="dbdad792-c62d-40e7-a324-a47a43d7a44d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 53.189453125 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="7e6e8732-e439-4eb9-8857-8d8ca48c07b2" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="673af4e4-616d-4ed4-9bc1-250e3cef8bd0" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="3a6a154e-9442-4a90-8284-98bc932835dd" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="1333ba80-47d6-40de-8095-1ae67d6d033c" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="2a2b72c1-4ecb-4b29-8880-313ec5973aa0" className="x301e8e74">전형 절차</div>
</div>
                <div data-layer="3ca4e395-8bc8-491e-ae96-e5d5ca796c12" className="xb4c093a9">임원 면접</div>
</div>
            <div data-layer="3b4eabae-b17a-44d8-bd04-9c860159d60b" className="x10983">                <div data-layer="eaffe8fb-b62d-4f7c-b3b3-a443145cbcf9" className="x32b6e9fa"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="3e0565fd-9efd-4c57-b6af-efe731f2e980" className="x6b154917">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="524f3b4f-5ee6-45d8-9ff8-c0f3c9656d02" className="xf825c9ca">지원 방법</div>
                <div data-layer="0f46f04d-e726-4267-9b56-1f4ab11bc591" className="linesdc359cbe">                    <svg data-layer="44e6c8cc-c9be-40c2-b385-579b07ed891e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x14812e389ed"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b9d21512-3046-452c-a684-f269648fd61d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142e0cbc812"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="913f8526-2693-4a19-a4ad-0bf5acd1b857" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1498750f7b8"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="42afb862-952c-4b11-8afd-c6c37629a580" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1435c6375a2"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="506de371-e6f0-40b2-8baa-9517ef363bbb" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x14414c4662e"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="51d54aaf-5a71-4740-b849-cd9f341aeae7" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="1ce8b906-5662-4b89-a03b-c8be5061accd" className="x10987">                <div data-layer="49a29b88-78c2-4feb-9b6a-752300584a40" className="x42">관련 업무 경력 4년 이상인 분<br />지브리 풍의 밝고 화사한 그래픽 디자인에 능숙한 분<br />다양한 컨셉을 제시하고 새로운 시도를 추구하는 분<br />게임개발 경력 2년 이상이신 분<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="4d85d023-0fba-4166-89dd-4cc02af9d06f" className="x0d8a3912">자격 요건</div>
                <div data-layer="8e98919c-8db7-451d-a6b2-6197ed9478bd" className="linesa42b955c">                    <svg data-layer="074bbb74-fa56-44f1-a9a7-08edda173f92" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148dbf26c03"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="0cf83401-1bf2-41c4-8a8f-f1ac316c2de5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142827c207a"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="286c1f5c-12c9-4f6a-aa1d-326ad24d2923" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149ed23633e"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="b1fd7d1c-30df-4af6-8d9f-63374e23fbfc" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1432ebcac23"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="018aebb4-dc30-4022-977f-f0c92b8d7084" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x1440b3a7852"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="293e793e-c54d-4b2e-bdaf-0dd29ade94d4" className="x10985">                <div data-layer="e686c5c8-95c0-457a-b407-b025191ea8a2" className="x46abb03f">아트에 관한 기본기가 좋으신 분<br />프로젝트 완수경험</div>
                <div data-layer="f4609317-f174-474a-9199-f7d13381599b" className="x302d7661">우대 사항</div>
                <div data-layer="28b5b948-3ecc-4270-b819-2faf4fe85110" className="linese552d2d8">                    <svg data-layer="f549d87c-5773-4d60-8a35-16fc3d3330e1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x1487f9d9aed"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="9d200253-995b-4aa9-a305-60017286d253" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x1420f34cce7"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="9b990fb5-2a7c-404a-be47-571800892834" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149e7798b4c"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="718e9327-f311-4c74-aef2-92d888c94fab" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x14371198eaf"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="89b1ac87-019f-4bcb-a8f5-8f723038e2c0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144588424a0"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="fe5c005c-e14d-4047-a8ff-6dc64a2bb290" className="x11012">                <div data-layer="ec40b2a5-f7ff-44a0-9867-c5df4e732746" className="xL">하늘섬 세계를 배경으로 하는 글로벌 프로젝트L의 배경 컨셉</div>
                <div data-layer="f4d98c82-77de-4bb1-a41c-7ddf9d2452b0" className="x">주요 업무</div>
                <div data-layer="2e81e6b4-6f83-4c25-b396-13f8e5470b73" className="lines">                    <svg data-layer="f649e349-5b79-423e-a5ab-2ef10a55c266" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="24904c3c-d1ec-4956-a83d-e1dc65ec69d1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="451f00eb-664a-4cb1-94a2-af8814fba663" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="8e24930e-e0c8-4142-b4f6-5f694c40cba0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="c064d6e6-3630-49c9-8c88-746cf8bf2a1a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

MjdbgArtist.propTypes = {

}

MjdbgArtist.defaultProps = {

}


export default MjdbgArtist;
          