
import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import "./mstickyheader.css";
import StickyheaderImpl from '../../stickyheader_impl';
import Mmenu from "../mmenu/mmenu";

class Mstickyheader extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        isStick: false,
        navi_class: "hide"
      };
  }

  handleNavibar = (isStick) => {
    this.setState({isStick: isStick}, () => {
      this.setState((this.state.isStick) ? {navi_class: "show"} : {navi_class: "hide"});
    });
  }

  onNavibar() {
    this.handleNavibar(!this.state.isStick);
  }

  onCloseNavibar = () => {
    this.handleNavibar(false);
  }
  
  render() {
    
    return (
      <div>
        <Mmenu onCloseNavibar={this.onCloseNavibar} isStick={this.state.isStick}/>
      <StickyheaderImpl>
          <div data-layer="59a05925-9b67-4018-ab2c-55ea6ecab0d6" className="mstickyheader">        <div data-layer="211dba28-24f5-4f7a-8560-7ac36a4c43ad" className="x17612"></div>
        <div data-layer="8ead965d-cb58-44a3-8e15-43174b92b8f5" className="x17636"></div>
        <Link to="/"><div data-layer="59647d23-3713-4575-9246-8b9b6172b0f3" className="logosmartstudygames2500x640"></div></Link>
        <div data-layer="134ec570-442a-4dda-b0d5-49d85b22e450" className="navibar" style={{cursor:"pointer"}} onClick={() => {this.onNavibar();}}>            <div data-layer="e73fe953-4688-4d4f-888c-7edaf9684604" className="x17579"></div>
            <div data-layer="f4c3eb18-d280-45d5-a38a-b1048877f9a6" className="x17580"></div>
            <div data-layer="fe88ba01-bf9d-4d40-ba65-9c4c2891528d" className="x17594"></div>
</div>
</div>
</StickyheaderImpl>
</div>
    );
  }
}

Mstickyheader.propTypes = {

}

Mstickyheader.defaultProps = {

}


export default Mstickyheader;
          