
import React from "react";
import PropTypes from "prop-types";

import "./jdeffector.css";

class Jdeffector extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="86e3792b-cbb7-459c-a423-10462de6d9cb" className="jdeffector">        <div data-layer="ec8cc468-30f7-4c67-b6cf-daf22ba231e8" className="layer1">            <div data-layer="e3406aae-5eaf-42d8-a27c-494bd9c33fc8" className="thinkSmartStudyGamesIp2024Rpg">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="163c0817-c786-4498-a702-0edcb523dff4" className="x10981">                <div data-layer="cd0cc2a0-487f-440a-bcf7-cedde858a7f4" className="x43d8f068">서류 전형</div>
                <div data-layer="1797d96a-d8d9-4467-a58b-31454e6029d1" className="x980079c5">실무 면접</div>
                <div data-layer="c3e5bfa7-b6b6-46f3-b739-d30a33ca8b62" className="x10d4478b">최종 합격</div>
                <div data-layer="2ef28593-dbcc-458e-843c-4c445c197e63" className="x10980">                    <div data-layer="20fccfbc-3b94-4d8d-87e4-199ce5b6de82" className="x10979">                        <svg data-layer="961f7526-0fc5-48fa-bc83-599ba78fbefc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="eec1bf93-4733-4397-8127-4c315e95e20c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="776d2964-c052-46c2-8d95-7983697c98b7" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="853efb93-9529-4269-9a9e-ab7034c8a561" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="09bf8327-f05e-4ec1-b301-9e05bd8cab6f" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="fb75c142-bafc-47d7-b0b6-178597182f5e" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="2d29269f-42f8-4f6a-adbd-91a919a465ca" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="723ef310-847f-454c-91c2-2ce01b04685a" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="ced9d00d-87e9-4ddc-a14c-bd636606ea42" className="xf39eb87b">전형 절차</div>
</div>
                <div data-layer="541e5fab-cbcd-409e-a93e-2f551757e9de" className="xb1c83232">임원 면접</div>
</div>
            <div data-layer="0977d4c1-593e-44ba-a350-d66cf51a9e28" className="x10983">                <div data-layer="689b16d4-0b23-4203-826d-3677652333f0" className="x51e2df5b"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="265059c8-87d1-44a5-a8ed-8c21b38d944f" className="x86e63766">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="7d3b246e-f580-48fc-926e-f925d5803070" className="xc6570ac0">지원 방법</div>
                <div data-layer="a439d73f-ef22-4594-ac85-51f90c712e47" className="lines90a34d4a">                    <svg data-layer="f848f3db-92e8-48fc-a137-e2ab1fdf6aa4" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1486b1cf058"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="6b580296-1315-4e67-9998-000b56977c53" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142e5c715fc"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="6f7c54d3-3888-4175-a799-81c92feefbf0" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14967812b2b"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d8351339-fa3b-4b9a-9ac3-9a02b415bd99" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14316422f37"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="1a893ab6-aa9f-47ae-93b8-b0466e0756ea" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144f74b2f56"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="6b1bc5d7-e92f-43a1-936d-610b889bfa43" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="7d0cc5c4-c1eb-44db-8d9c-3cb931b325a7" className="x10987">                <div data-layer="f4dbf032-1d39-4c02-ba67-a4b5a5e6f52f" className="x3Unity">관련 업무 경력 3년 이상<br />Unity 엔진에서의 이펙트 제작 경험<br />카툰 스타일 이펙트가 포함된 포트폴리오<br />자율과 책임기반의 자기주도적인 분 <br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="00cdd561-5b2d-44e6-8ca0-12b3f8754c87" className="xa112d504">자격 요건</div>
                <div data-layer="0f2cf09b-cceb-4a38-8fbb-90528c36cc92" className="linesc1cd9fba">                    <svg data-layer="eb83efa7-f83c-42f3-84f3-5d106be70fce" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14835f4e538"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="d9c862e2-e976-4ab4-a7f3-60201b4006d4" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142fb4d3199"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="194b30fa-8f90-4d52-a1c3-c146a44fa446" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149e129778c"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="5c002430-a445-40dd-956f-86dc62171cc5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143f42c6007"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="4a1d8a22-da6e-4e67-8b35-ef216b7baeba" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x14444e9f902"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="774aee74-51e1-4ed1-9f05-b5195149ce35" className="x10985">                <div data-layer="78c56483-89ca-4aa6-b57d-534e480f16c2" className="x355a9b16">최초 개발 단계부터 런칭까지 경험자<br />이펙트 리드 경험</div>
                <div data-layer="354b59cc-3cd5-493a-9935-b443178a4f5b" className="x1124978e">우대 사항</div>
                <div data-layer="b5e3019d-a2e6-4aae-aef3-01e005f2cce6" className="lines2f8863ad">                    <svg data-layer="b986ca8f-b68e-4a67-821c-171598763b11" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1489321b8b7"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="73af1164-2a05-4b77-86f5-c99b9b1528bf" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14265978414"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="11bb6b10-32bf-4df6-9b69-caad587687a1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14906d88102"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="565c8543-5e78-455b-b1f9-23d41bc3a68a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14395d3ce62"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d1685daa-1110-43a0-ab18-aa0d4aebb656" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144ef39634a"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="58cd6360-a4e9-49fb-b66a-b4029c8172a1" className="x11012">                <div data-layer="847334c5-e3c1-4b65-a87e-1d18721a5d0a" className="xL">글로벌 신규 프로젝트 L의 이펙트 제작 업무</div>
                <div data-layer="69ccf615-23f9-4f3c-b313-9a5507538017" className="lines">                    <svg data-layer="4c93b160-f720-4cfb-a59d-28f9d4093f30" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="ae0758ab-8330-465f-8fa4-d1936d3f7a53" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="936e3d3a-be0c-413c-aaf6-79aad98b98ca" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="70256169-7926-45d2-86c4-9f4328cb4043" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="37e5be75-104e-49ae-a076-c2054f9f6772" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 70.80859375 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="fa619043-c072-41a5-b15d-c22214d4e6b0" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdeffector.propTypes = {

}

Jdeffector.defaultProps = {

}


export default Jdeffector;
          