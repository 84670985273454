import React, { useRef, useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { PromptProps } from 'react-router-dom';

import './imageslider.css';

const ImageSlider = (props) => {

    const thbMargin= 30;

    const [currImageNumber, setImageNumber] = useState(0);
    const [parentSize, setParentSize] = useState(0);
    const targetRef = useRef(null);
    
    useLayoutEffect(() => {
        if (targetRef.current) {
            setParentSize({width:targetRef.current.offsetWidth, height:targetRef.current.offsetHeight});
        }
    }, []);

    const onChangeImage = useCallback((idx) => {
        idx %= props.imagecnt;
        if(props.imagecnt <= idx || idx<0)
            idx = 0;
        
        setImageNumber(idx);
    });

    // const willMount = useRef(true);
    // if (willMount.current) {
    //     const e = document.getElementsByClassName('imageslider' + props.slidername);
        
    //     console.log(props.children);
    //     if(e!=null && e.length>0)
    //     {
    //         const viewColltion = e[0].getElementsByClassName('imageview' + props.slidername);
    //         console.log(viewColltion);
    //         if(viewColltion!=null && viewColltion.length>0)
    //         {
    //             props.imageview = viewColltion[0];
    //         }
            
    //         console.log(props.imageview);
    //     }

    //     willMount.current = false;
    // }

    useEffect(() => {
        // re render

        //console.log("Rerender for image no." + currImageNumber);

    }, [currImageNumber]);


    const recursivelyMod = (children) => {
        const slidername = props.slidername;
        const imgSizeW = parseInt(props.imagewidth);
        const imgSizeH = parseInt(props.imageheight);
        const thbSizeW = parseInt(props.thbwidth);
        const thbSizeH = parseInt(props.thbheight);
        const ext = props.ext!==undefined ? props.ext : "jpg";
        const isPortrait = (props.portrait!==undefined && props.portrait==="true");
        return React.Children.map(children, (childNode) => {
            
            if (typeof childNode === "string")
            {    
                return childNode; // cover case: <div>text<div></div></div>
            }
            if (typeof childNode.props.children === "string")
            {
                if(childNode.props.className === "sliderleft"+slidername)
                {
                    if(isPortrait) {
                        return React.cloneElement(childNode, {className: "sliderleft", onClick: () => onChangeImage(currImageNumber-1), style: {cursor:'pointer',transform:'rotate(-90.00deg)',top:'-55px',left:`${imgSizeW/2}px`}}, recursivelyMod(childNode.props.children));
                    }
                    else {
                        return React.cloneElement(childNode, {className: "sliderleft", onClick: () => onChangeImage(currImageNumber-1), style: {cursor:'pointer',top:'278.50px'}}, recursivelyMod(childNode.props.children));
                    }
                }
                else if(childNode.props.className === "sliderright"+slidername)
                {
                    if(isPortrait) {
                        return React.cloneElement(childNode, {className: "sliderright", onClick: () => onChangeImage(currImageNumber+1), style: {cursor:'pointer',transform:'rotate(90.00deg)',bottom:'-40px',left:`${imgSizeW/2}px`}}, recursivelyMod(childNode.props.children));
                    }
                    else {
                        return React.cloneElement(childNode, {className: "sliderright", onClick: () => onChangeImage(currImageNumber+1), style: {cursor:'pointer',top:'278.50px'}}, recursivelyMod(childNode.props.children));
                    }
                }
                return childNode;
            }

            if(typeof childNode.props.className === "string" && childNode.props.className === "imageslider"+slidername)
            {
                return React.cloneElement(childNode, {ref:targetRef, style:{width:'100%', height:'100%'}}, recursivelyMod(childNode.props.children));
            }

            if(typeof childNode.props.className === "string" && childNode.props.className === "imagelist"+slidername)
            {
                //--------- calculate selected position
                // selected position
                const thbSize = (isPortrait) ? thbSizeH : thbSizeW;
                const parentSz = (isPortrait) ? parentSize.height : parentSize.width;
                const margineSz = (isPortrait) ? 27 : thbMargin;

                const posInit = (thbSize+margineSz) * currImageNumber;
                const frameSize = (thbSize+margineSz) * 5 - margineSz;
                const contentSize = (thbSize+margineSz) * props.imagecnt - margineSz;
                const margine = (parentSz-frameSize) / 2;
                let transAmount = 0;

                // contents 이동
                const stdLine = frameSize/2 - thbSize/2;
                transAmount = stdLine - posInit;
                const endLineAmount = frameSize - stdLine - thbSize;
                const correctPos = endLineAmount - (contentSize - (posInit + thbSize));
                if(transAmount > 0) {
                    transAmount = 0;
                }
                else if(transAmount<0 && correctPos>0) {
                    transAmount += correctPos;
                }
                // console.log('trans.' + transAmount);
                //--------- 

                //--------- get style
                const buildFrameStyle = () => {
                    if(isPortrait) {
                        return {
                            width: `${thbSizeW}px`,
                            height: `${frameSize}px`,
                            right : '0px',
                            top: `${margine-17}px`
                        }
                    }
                    else {
                        return {
                            left : `${margine}px`,
                            width: `${frameSize}px`,
                            bottom: '0px'
                        }
                    }
                }
                const buildContentStyle = () => {
                    if(isPortrait) {
                        return {
                            width: `${thbSizeW}px`,
                            height: `${contentSize}px`,
                            transform: `translate(0px, ${transAmount}px)`
                        }
                    }
                    else {
                        return {
                            width: `${contentSize}px`,
                            transform: `translate(${transAmount}px, 0px)`
                        }
                    }
                }
                const buildListStyle = () => {
                    if(isPortrait) {
                        return {
                            top: '0.00px',
                            right: '0.00px'
                        }
                    }
                    else {
                        return {
                            top: '0.00px',
                            left: '0.00px'
                        }
                    }
                }
                //--------- 

                return(
                    <div className="imagelistthumbframe" style={buildFrameStyle()}>
                        <div className="imagelistthumbcontent" style={buildContentStyle()}>
                            { React.cloneElement(childNode, {style: buildListStyle()}, recursivelyMod(childNode.props.children)) }
                        </div>
                    </div>
                
                );
            }

            if(typeof childNode.props.className === "string" && childNode.props.className.indexOf("thumb")>-1)
            {
                ///----------- get style
                const marginSize = (isPortrait) ? 27 : thbMargin;
                const buildStyle = (idx) => {
                    if(isPortrait) {
                        return {
                            position: 'absolute',
                            top: `${(thbSizeH+marginSize)*parseInt(idx)}px`,
                            left: '3px',
                            width: `${thbSizeW}px`,
                            height: `${thbSizeH}px`
                        }
                    }
                    else {
                        return {
                            position: 'absolute',
                            left: `${(thbSizeW+marginSize)*parseInt(idx)}px`,
                            width: `${thbSizeW}px`,
                            height: `${thbSizeH}px`
                        }
                    }
                };
                ///----------- 
                ///----------- get file if exists
                const tryRequire = (path) => {
                    try {
                        return require(`${path}`);
                    }
                    catch(err) {
                        return null;
                    }
                };
                const getAppropriateImage = (idx) => {
                    const org = `./assets/imageview_${slidername}_${parseInt(idx)}.${ext}`;
                    const thb = `./assets/imageview_${slidername}_${parseInt(idx)}_thumb.${ext}`;
                    return tryRequire(thb) ? tryRequire(thb) : tryRequire(org)
                }
                ///----------- 

                if(childNode.props.className.indexOf(slidername) > -1)
                {
                    const index = Number(childNode.props.className.substr(5, 1));
                    let selected = currImageNumber===index ? " selected" : "";
                    //return React.cloneElement(childNode, {className: "thumb"+index+selected, onClick: () => onChangeImage(index)}, recursivelyMod(childNode.props.children));
                    
                    // index==4인데(5개째) props.imagecnt가 5이상이면 추가 아이템을 render
                    const renderitem = () => {
                        const result = [];

                        for(let i=5; index===4 && i<props.imagecnt; i++)
                        {
                            let subsel = currImageNumber===i ? " selected" : "";
                            
                            result.push(
                                <div className="thumb" onClick={() => onChangeImage(i)}>
                                    <img src={getAppropriateImage(i+1)} width={thbSizeW} height={thbSizeH} align='center' style={buildStyle(i)} className={`${subsel}`}></img>
                                </div>
                            );
                        }
                        result.push(
                            <div className="thumb" onClick={() => onChangeImage(index)}>
                                <img src={`${getAppropriateImage(parseInt(index)+1)}`} width={thbSizeW} height={thbSizeH} align='center' style={buildStyle(index)} className={`${selected}`}></img>
                            </div>
                        );
                        return result;
                    };

                    return renderitem();
                }
            }
            else if(childNode.props.className === "imageview"+slidername)
            {
                ///----------- get style
                const buildStyle = (imageIdx) => {
                    if(isPortrait) {
                        return {
                            width: `${imgSizeW}px`,
                            height: `${imgSizeH*props.imagecnt}px`,
                            transform: `translate(0px, ${imageIdx*-imgSizeH}px)`        
                        }
                    }
                    else {
                        return {
                            width: `${imgSizeW*props.imagecnt}px`,
                            height: `${imgSizeH}px`,
                            transform: `translate(${imageIdx*-imgSizeW}px, 0px)`
                        }
                    }
                }
                const buildFrameStyle = () => {
                    return {
                        width: `${imgSizeW}px`,
                        height: `${imgSizeH}px`
                    }
                }
                
                const buildViewStyle = () => {
                    return {
                        width: `${imgSizeW}px`,
                        height: `${imgSizeH}px`,
                    }
                }
                ///--------------

                const imagerender = () => {
                    const result = [];
                    for(let i=1; i<=props.imagecnt; i++)
                    {
                        if(isPortrait) {
                            result.push(<img src={require(`./assets/imageview_${slidername}_${i}.${ext}`)} width={imgSizeW} height={imgSizeH} align='left' style={{position:"absolute", top:`${imgSizeH*(i-1)}px`, left:"0px"}} key={`imageview_${slidername}_${i}`}></img>);
                        }
                        else {
                            result.push(<img src={require(`./assets/imageview_${slidername}_${i}.${ext}`)} width={imgSizeW} height={imgSizeH} align='left' style={{position:"absolute", left:`${imgSizeW*(i-1)}px`, top:"0px"}} key={`imageview_${slidername}_${i}`}></img>);
                        }
                    }
                    return result;
                }

                const imageboxName = (props.portrait===undefined || props.portrait==="false") ? "imagebox" :"imageboxportrait";
                
                return(
                <div className={imageboxName} style={buildFrameStyle()}>
                    <div className="imageview" style={buildStyle(currImageNumber)}>
                        <div className="imagecontent" style={buildViewStyle()}>
                        <picture>
                            {imagerender()}
                        </picture>
                        </div>
                    </div>
                </div>
                );
            }
            
            return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
        })
    }

    return (
        <div style={{width:'100%', height:'100%'}}>
            {recursivelyMod(props.children)}

        </div>
    );
};

export default ImageSlider;