import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useMediaQuery } from "react-responsive"
import PageTracking from './pageTracking';

import Main from './pages/main/main';
import Mmain from './pages/mmain/mmain';
import Culture from './pages/culture/culture';
import Mculture from './pages/mculture/mculture';
import Games from './pages/games/games';
import Mgames from './pages/mgames/mgames';
import Support from './pages/support/support';
import Msupport from './pages/msupport/msupport';
import Humanres from './pages/humanres/humanres';
import Mhumanres from './pages/mhumanres/mhumanres';
import Recruit from './pages/recruit/recruit';
import Mrecruit from './pages/mrecruit/mrecruit';
import ResponsiveModule from './ResponsiveModule';
import StickyHeaderWrapper from './stickyheader_wrapper'


function App() {

  const isPc = useMediaQuery({
    query : "(min-width:1200px)"
  });

  const isTablet = useMediaQuery({
    query : "(min-width:768px) and (max-width:1199px)"
  });

  const isMobile = useMediaQuery({
    query : "(max-width:767px)"
  });
  
  return (
    
    <div className="App">
    <ResponsiveModule />
    <Router>
        <PageTracking />
        {isPc && 
        <Routes>
          <Route path='/' element={<><StickyHeaderWrapper /><Main /></>} />
          <Route path='/main' element={<><StickyHeaderWrapper/><Main /></>} />
          <Route path='/culture' element={<><StickyHeaderWrapper/><Culture /></>} />
          <Route path='/games' element={<><StickyHeaderWrapper/><Games /></>} />
          <Route path='/support' element={<><StickyHeaderWrapper/><Support /></>} />
          <Route path='/humanres' element={<><StickyHeaderWrapper/><Humanres /></>} />
          <Route path='/recruit' element={<><StickyHeaderWrapper/><Recruit /></>} />
        </Routes>
        }
        {(isTablet || isMobile) &&
        <Routes>
          <Route path='/' element={<><StickyHeaderWrapper /><Mmain /></>} />
          <Route path='/main' element={<><StickyHeaderWrapper /><Mmain /></>} />
          <Route path='/culture' element={<><StickyHeaderWrapper /><Mculture /></>} />
          <Route path='/games' element={<><StickyHeaderWrapper /><Mgames /></>} />
          <Route path='/support' element={<><StickyHeaderWrapper /><Msupport /></>} />
          <Route path='/humanres' element={<><StickyHeaderWrapper /><Mhumanres /></>} />
          <Route path='/recruit' element={<><StickyHeaderWrapper /><Mrecruit /></>} />
        </Routes>
        }
    </Router>


    </div>
  );
}

export default App;
