
import React from "react";
import PropTypes from "prop-types";

import "./jdgameDesignerConcept.css";

class JdgameDesignerConcept extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="192b4a72-0e8e-4324-b1fb-8ef6527bb8e4" className="jdgameDesignerConcept">        <div data-layer="dc32e942-d88e-43c4-8a4a-92f3517d2139" className="layer1">            <div data-layer="fa158ff2-0191-4381-93a1-a57e0214011f" className="thinkSmartStudyGamesIpRpg">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />현재, 스마트스터디게임즈는 신작 RPG 게임과 라이트코어 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="2a3049a1-d5ad-468d-aa13-c09bdc37e598" className="x10981">                <div data-layer="1410b4af-1d59-44fe-a924-63c3243791bc" className="x2cad4106">서류 전형</div>
                <div data-layer="cf59c416-3a30-45d7-b323-f5e0f562a870" className="x32b34b6b">실무 면접</div>
                <div data-layer="adafdf88-ca4a-4bb8-8131-4398cd737431" className="x93930016">최종 합격</div>
                <div data-layer="ff58f007-1cbb-4f71-9ca8-dc4bae145e01" className="x10980">                    <div data-layer="03266138-7c48-4b39-8859-aee85af2a144" className="x10979">                        <svg data-layer="68be36af-e1bc-46ef-893a-815e9e64cb68" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="52f73098-abf6-415b-9329-9607da86da98" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="337893a8-d495-435b-aa4f-f7bb6984e60b" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="249f2fe8-019c-4dfc-a7a1-0b223073b7be" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="41892b97-4615-42f6-ad75-18ac4ffc8928" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="74f62800-91c0-4527-87c2-dea40a5ff975" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="8c8fcb27-8336-4798-b370-3337fa31f870" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="bd51771f-3d7d-441d-b5dc-7df1d96dc209" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="9bebc7bd-754f-48ff-9a09-0829a74c3e9c" className="x6bef8b0e">전형 절차</div>
</div>
                <div data-layer="dc4ab8d3-c8e2-4cc5-9a7b-d32c812d2a72" className="xfeaa5d8d">임원 면접</div>
</div>
            <div data-layer="beee0446-d8b1-43e9-8225-bfd9aebce300" className="x10983">                <div data-layer="175a486b-9c11-4862-ae4d-82f8693113ef" className="x42c8f9e8"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="ee4b3217-cc95-49be-8938-38523373fafc" className="xd3d93fbc">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="6ec99f81-201e-4a27-9d8a-7d01f91867c8" className="x02d9a589">지원 방법</div>
                <div data-layer="dec9a931-b973-4a9a-bb1f-8e46b9a11137" className="lines9af4cb91">                    <svg data-layer="178bca77-e94c-46e9-ae2e-07f72149b060" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148428b325d"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="5ece6b7b-0004-4ee8-aabf-b7fabb3208cc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142cbfc48d3"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="2e202edc-690c-4c4f-95e8-2d55b8e3d954" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14953c841be"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="ebb083d0-06c4-4b26-8ce9-51a2f2415d8e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1439f283e51"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="11d19f35-ecef-41eb-a0f3-bc859c9533f7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x14449437ec9"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="8808878a-b531-448b-9076-9996ab441f45" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="bc851f74-563d-4041-a8ef-5653f37fa020" className="x10987">                <div data-layer="6f8938f2-1d57-41e1-ac60-ef848ec5d6db" className="x1Rpg">관련 업무 경력 1년 이상인 분<br />캐릭터 컨셉 기획 경험이 있는 분<br />대사 스크립트 작성 및 관련 테이블 관리 경험이 있는 분<br />모바일 수집형 RPG에 대한 깊은 이해를 가진 분<br />자율과 책임을 기반으로 자기주도적으로 일하실 수 있는 분<br />원활한 의사소통과 협업 능력을 중요하게 여기는 분</div>
                <div data-layer="2c993ce4-29fe-4154-8f73-18803b359671" className="x8e280c29">자격 요건</div>
                <div data-layer="3e812fc6-4a6b-4a22-ba68-26e4d03e43c5" className="lines1a2a93b8">                    <svg data-layer="182fa5fd-a688-479b-9795-d950a7aeea89" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148cdd9bc74"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="fc160e34-6083-45e4-a8f6-e71049b25d78" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142afcf1f76"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="393cb385-9463-4ecb-aed3-c4fcc1cdb43f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14942874ff7"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="49c0c7f0-2705-4efd-b99d-635af0dc0416" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14372f7661c"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="263ab0f5-0b13-4577-b089-bb135b67a17c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1444d5c0492"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="60ed7363-67d8-436d-b93c-9b9cc43596b5" className="x10985">                <div data-layer="814666d0-7c9c-4bc1-a7a8-86864807be5a" className="xNpcRpgRpg">세계관에 맞춰 지역을 만들고 그 안에 살고 있는 NPC들을 기획해본 분<br />배경 제작을 위한 설정 기획 경험이 있는 분<br />최근 출시된 캐릭터 수집형 모바일 RPG 게임을 다양하게 플레이한 분<br />모바일 수집형 RPG 게임의 라이브 운영 경험이 있는 분<br />최초 개발 단계부터 런칭까지의 경험을 보유한 분</div>
                <div data-layer="5f096702-0bf2-4274-b60b-90778d6fadfe" className="xce16d2c3">우대 사항</div>
                <div data-layer="5da36c32-5eb7-4fec-87e3-12cfa9acd31c" className="linescb2bb20b">                    <svg data-layer="76093a11-441d-408f-867a-4e756823c13c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1480321cb46"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="5123e27e-0523-4b6e-b97d-27349a59d663" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142f263f2dd"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="da81f65f-8d1a-449f-aebc-966ad5d545b0" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149e95526aa"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="41e6af24-4122-4bb2-a193-14041100c209" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1438858b9cc"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="8cb74d6e-4375-43fe-ab01-daf5f1f9dba1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x14448a39725"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="88ebac32-a3ed-4767-9641-37cab287cd94" className="x11012">                <div data-layer="48b0a3bb-4a7d-4d72-98c5-9e245296c2ea" className="xe79ab6c3">캐릭터의 컨셉 기획 및 대사 작성<br />세계관의 깊이를 확장시키는 세부 설정 기획</div>
                <div data-layer="49ed6a1e-ca71-4736-b480-08113db6284c" className="lines">                    <svg data-layer="d3fc05a7-819d-4455-8c33-5d72f3203bf2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="a2b10a46-c1ac-41da-8bb1-4ff05f70df4a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="0db7b271-e05b-47c8-b1dc-a4002df084d1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="06e45e3b-113c-4bf5-b605-ab49b28543bb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="f498df94-f161-477e-9950-412deaab2e41" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="509b8202-af87-4a32-b0a9-ccf9783b7428" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

JdgameDesignerConcept.propTypes = {

}

JdgameDesignerConcept.defaultProps = {

}


export default JdgameDesignerConcept;
          