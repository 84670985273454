import React, { useState, useCallback, useEffect } from "react";

import "./main_impl.css";

const MainImpl = () => {

   /*
    useEffect(() => {
        window.onscroll = () => {
            if(timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(() => {
                if (scroll >= window.scrollY && window.scrollY > 10) {
                    console.log("Set Sticky");
                    // document.getElementById('header').classList.add('sticky')
                } else {
                    // document.getElementById('header').classList.remove('sticky')
                    console.log("Reset Sticky");
                }
        
                scroll = window.scrollY
                }, 10);
        }
    }, [scroll])

   */

    const initPage = ((e) => {
        if(e!=null) {
            // console.log(e.length);
            for(var i=0; i<e.length; i++)
            {
                e[i].classList.add("initpage");
            }
        }
    });

    useEffect(() => {
        
        initPage(document.getElementsByClassName('contentbody'));
        initPage(document.getElementsByClassName('footer'));
        

    }, []);
    
    return (<div></div>);
};

export default MainImpl;