
import React from "react";
import PropTypes from "prop-types";

import "./mjdsupport.css";

class Mjdsupport extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="66790abf-0524-470c-bb4d-d0a2d31cfdf6" className="mjdsupport">        <div data-layer="b62aba4e-fb46-4c23-bbc7-df789588fba1" className="layer1">            <div data-layer="771b86a6-c504-4a74-8fc7-345a94127aba" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코 어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="48a437ae-fe34-4b8f-aecd-34b7bd581137" className="x10983">                <div data-layer="faaaea50-7ab1-4c94-9311-931bd80aea28" className="x7c3a63e2"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="dcf449ad-5208-4034-abd3-465541d4d694" className="x38b912bb">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="f23c4e16-eaad-438a-93cf-4f37e91aaa36" className="x199becab">지원 방법</div>
                <div data-layer="23abd1ad-101e-4241-a3b3-1b78492398e9" className="lines3738513d">                    <svg data-layer="1028f6ce-b4fc-489a-b66a-6cd3ac4b1fb0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x14864f3ac59"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="3b938d0e-5c89-4ded-b85f-f14d21969995" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142f529a58c"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b607732c-f346-4a66-b788-c8c784380457" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1498f6157fb"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="beb07865-4df9-4aff-8398-90e4ef0cd12c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1431daa0deb"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="e350961b-549d-4383-a2e1-8f406180a9d9" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x1445cc6764b"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="7da097fb-aa5f-437b-9a23-8da45baf026a" className="x10981">                <div data-layer="353733f9-b304-4080-a458-baca66fc99db" className="x7df1329e">서류 전형</div>
                <div data-layer="f34cae73-69d2-4a86-823f-c5c042f75867" className="x6a40cd00">실무 면접</div>
                <div data-layer="f5097900-6aad-42b4-8e82-0af580d5ac23" className="xa3985dcd">최종 합격</div>
                <div data-layer="88e1ee67-1ec8-4110-a38f-d090898029f2" className="x10980">                    <div data-layer="64708ccc-0412-44f2-a9e8-9697436e7c5d" className="x10979">                        <svg data-layer="fcde6351-8008-4339-9373-81743dfb5187" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 501.189453125 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="868752d4-45b3-4a5e-8f9a-1394b4f5024b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 126.189453125 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="f8c65c7f-46f4-45af-b7ee-e92d8916065b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 213.189453125 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="3ef07360-ceb0-4e4f-9422-6a1b247d0a68" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 53.189453125 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="f48326e1-9d99-466b-9864-babdc6be5edd" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="80a6c4c2-80e4-4a27-95a7-1b4e7ef85015" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="b6efd2a7-5933-4138-80ad-052fca30d116" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="a292c2e0-9c85-49a5-b7b5-655437c0c043" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="cccda531-c268-4930-b3f9-95eb6e1a55b9" className="x3ca56b4f">전형 절차</div>
</div>
                <div data-layer="32a74052-9b84-49bd-82cd-fede0ca42780" className="xa66fcd40">임원 면접</div>
</div>
            <div data-layer="0bfb637a-a7e6-4386-a070-516efa40a275" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="457a4652-9781-4783-96dd-2e2702e797a6" className="x10985">                <div data-layer="1cafe0a5-09a2-4cd1-96bc-54f49bd49d05" className="androidios">모바일(Android/iOS) 플랫폼에 대한 이해와 개발 경험이 있는 분<br />새로운 언어, 환경에 대한 두려움이 없는 분<br />개발 문서를 읽을 수 있는 영어 독해 능력이 있는 분</div>
                <div data-layer="42e59542-3aa7-4487-a0bc-32c9ba0356e2" className="x6e2b2eca">우대 사항</div>
                <div data-layer="d5ffc33f-4c37-495c-b9e1-776d8fe236ef" className="linesc6d56d3a">                    <svg data-layer="df05e226-0bc2-4836-93ef-c8ad94edbbb9" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148153a4c27"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="438e7eba-c273-4094-9616-2acf7520cd23" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x14270903947"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="02c53bcc-d817-4265-85eb-c73d0f2b37da" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149603c9b98"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="14f72faa-1b0c-49cf-a3cc-ac92b341c617" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143b7328683"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="8978467d-b813-4f15-a394-53f57aa634c2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144afcd1d37"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="9b366a0d-5820-495c-aac6-1a27605e1e27" className="x10987">                <div data-layer="21b17931-ab95-4c73-987d-5e70060e4472" className="xbd91d9c4">컴퓨터 활용 능력<br />뛰어난 의사 소통 능력</div>
                <div data-layer="a3fc8734-1324-4968-80eb-c9d6c02a86d3" className="x0c08fb13">자격 요건</div>
                <div data-layer="4a15fbb3-4ab7-4478-b63f-3ad1b7bc1370" className="lines0c6f29e1">                    <svg data-layer="8c52492c-33ab-4a27-b7ee-5ae568075bda" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148e034318d"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="1a5d4ce2-c5ff-4675-a2f8-91d9e397b172" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x1421e91df74"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="892adaa4-bdc4-4476-9490-8ce36ecc8055" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149ab914fa7"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="a2739538-c964-4178-8774-63bd0b1bee02" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1432d19f2de"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="7a59fcb1-aee2-40fe-abe0-e5d84cfcc15b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x1448995e050"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="608934b1-30b2-4771-a077-5b570cd8d01c" className="x11012">                <div data-layer="bb274d7a-3795-4bf4-83b0-f621de448eab" className="x527f3d28"><span className="x527f3d28-0">৹ 경영관리 보조 업무<br /></span><span className="x527f3d28-13">    급여/세무신고/원천징수세액 신고 관련 자료 정리 및 취합 전달<br />    매출&매입관련 거래 관리<br />    본사의 연결회계 서포트<br />    기타 회계관련 업무 보조<br /><br /></span><span className="x527f3d28-106">৹ 복리후생 제도 기획 및 운영<br /></span><span className="x527f3d28-124">    건강검진, 독감 예방접종, 명절 선물 등<br /><br /></span><span className="x527f3d28-152">৹ 시설 및 인프라 관리<br /></span><span className="x527f3d28-166">    사무환경 관리<br />    자산 관리(소프트웨어 및 하드웨어)<br />    중고 장비 매각, 폐기 및 자산 재활용<br /><br /></span><span className="x527f3d28-229">৹ 조직 구성원 서포트<br /></span><span className="x527f3d28-242">    복리후생 제도 운영<br />    채용/신규 입사자/면접자 안내<br />    조직 내 설문 및 조사 진행<br />    리뷰 및 보상 서포트<br /><br /></span><span className="x527f3d28-315">৹ 직장 내 법정 의무교육(ex. 직장 내 성희롱 예방 교육) 실시<br /></span></div>
                <div data-layer="649d3557-2c3f-4898-b7bd-59d7ca50c9ad" className="lines">                    <svg data-layer="2a9f8c7d-63ff-4c92-bcf9-2a9b79085d11" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="8016e6c4-e905-4343-b873-53e0b74e15c3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="0e6321d8-d61d-4d43-9aa1-f73383cdcbe2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="bf985270-2870-414d-bd01-5dcf78fd9b86" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="8130f851-fb50-4bcc-a7d9-a7b659a7fcb1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
                <div data-layer="565f69b4-2255-45c4-89a9-78e2b007faa1" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Mjdsupport.propTypes = {

}

Mjdsupport.defaultProps = {

}


export default Mjdsupport;
          