
import React from "react";
import PropTypes from "prop-types";

import "./jdgameDesigner.css";

class JdgameDesigner extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="44439200-1951-4009-be91-f064bd7a9f72" className="jdgameDesigner">        <div data-layer="8b00d1b5-092e-498b-a237-dbfb9bcb7e73" className="layer1">            <div data-layer="3d9a2e9a-8579-45f5-8e34-9acafa660c8d" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="0e6945a8-e6d2-42dc-943c-cf721b5cbdb1" className="x10981">                <div data-layer="729c36b2-eb90-4d4f-a3b6-189369b2bf0f" className="x2231bd6b">서류 전형</div>
                <div data-layer="aec86efa-e163-4492-8e3e-e2a6e7084c8b" className="x89472053">실무 면접</div>
                <div data-layer="58e94c62-3d70-4673-bace-cd6dd33a31a9" className="x9717d069">최종 합격</div>
                <div data-layer="037199bf-51ed-4e67-8639-104c2d1880fe" className="x10980">                    <div data-layer="b5b9d5d2-e3de-49c3-9410-2653b75e508a" className="x10979">                        <svg data-layer="7d39949a-55d6-41db-9a37-8873b6536dcb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="a67e7610-0906-42c3-9162-7b2275888814" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="f5717058-4cad-45a5-893b-62f6e664d437" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="a7e5422e-3233-48f2-8d8b-4782ec2c22fd" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="a3da7fcb-bb32-4c4a-a9b2-b720e3525a0e" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="06b882bd-b52d-405c-8c3d-5447b9da4931" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="97e95162-6396-4980-9463-7784628dc9c8" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="9eea08e1-e2e7-48a9-81ce-23a8ca010265" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="e922b0f7-c972-47cb-ae67-45b16da1a6ed" className="xa13664ce">전형 절차</div>
</div>
                <div data-layer="3a8abd1c-eff9-4059-8b5f-85d5209d238a" className="x61006512">임원 면접</div>
</div>
            <div data-layer="cf93c6a1-6937-4459-afec-544c76e43460" className="x10983">                <div data-layer="2895c7ae-4cbf-43df-8b45-6fe4ee5444f6" className="xd0d6c601"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="df0cf3c4-5e17-427a-bf5b-343aec1fa965" className="xf9d46ab5">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="38a202da-fd74-4e54-af6e-16d43d12a6d1" className="xbca0fac1">지원 방법</div>
                <div data-layer="305644d7-d601-4cca-ae29-ce42ae54ace0" className="lines6f0fc2e8">                    <svg data-layer="26f8b3c9-58c8-42e8-a0e4-ff4b1090023d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1484c94e8fa"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="775ae7ad-93f7-4ba8-8304-b1a64ab618fe" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142d706f177"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="2507dd6b-af10-4a28-b620-5148bc839310" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14989be9d85"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="34af34e0-f10e-4e55-ab5d-31fe856ef7bb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1436cad8020"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="6e2eacc5-a877-4031-94ed-67cf6ded8bfc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x14423065775"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="4de30ca7-623f-4abb-9c98-55becbd7c101" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="074e8fa0-e2e9-47c7-b839-fe2f37ba7593" className="x10987">                <div data-layer="42851caa-8957-4f35-8007-4054ae960fee" className="x3">관련 업무 경력 3년 이상의 분<br />환경 및 우선순위 변화에 신속 적응<br />문제 해결 능력 및 분석적 사고<br />구체적인 업무 내용이 포함된 포트폴리오<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="5c9378fc-e2f9-447f-9fc5-76824bf3dfc3" className="x97774cdc">자격 요건</div>
                <div data-layer="40e8aa47-4425-4b93-b739-88c70314d274" className="lines5adaaf30">                    <svg data-layer="1160ca5a-08d6-4d0c-9f24-0cfb9b623185" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1484e512804"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="e9176006-2809-436a-a194-854b54173369" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14241ce7ff3"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="a6f07238-a940-4012-9bad-f789c0423858" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14964e0a7da"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="528c5faf-698c-46cb-a2b3-14cf9741b4e9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1437d9d0b41"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="f5b8ab0b-fd3f-41af-b023-5d3107da8443" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144262c30cb"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="bde842fd-f4e5-4665-8dec-baec494c165f" className="x10985">                <div data-layer="91bd0451-362a-4e48-9ebe-697f078bc789" className="xRpg">모바일 RPG/전략 게임 라이브 경험자<br />최초 개발 단계부터 런칭까지 경험자<br />모바일 게임 산업 및 게임에 대한 관심</div>
                <div data-layer="73a9ce72-ad05-4bc6-b138-aa01754eeb98" className="xb465b661">우대 사항</div>
                <div data-layer="ff949c73-2a62-40f9-ae4a-ee78c9738e21" className="linesb2423043">                    <svg data-layer="7d831044-9283-449c-8648-a5245413141c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1483d75ddd4"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="5e5dca80-a333-43b3-90f2-174daf661d36" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14260fbffca"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="c164847a-383e-40f5-8215-aa84c4d5404f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1496da9469f"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="9ef3549e-0b03-4a8f-b70a-5ad247978e24" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143141c7e42"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="7d147408-83e9-4230-9758-abf7826dfa87" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144b6dc93be"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="452252c4-ddf0-4edd-a5f8-7e95686f87e4" className="x11012">                <div data-layer="4f27f244-c314-43b5-91cc-edd72133b7ed" className="xL">신규 글로벌 프로젝트L의 전투 스킬/시스템/밸런스 기획</div>
                <div data-layer="15f7bd06-2ad2-4eaf-9046-00ba9d2e8566" className="lines">                    <svg data-layer="16a96dac-d5bc-48ce-93fb-d404ef72195c" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="403894d2-694b-473d-a144-edc516eeb049" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="a75375c4-3e90-46f9-8421-2f5e11ce3d55" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="ed7b67b6-c745-4504-801e-8fe4d6cc152d" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="eb9f5353-03ee-41f3-acf5-c7889ca7c209" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 70.80859375 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="e05c325f-fab6-483c-afef-bd704e316de6" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

JdgameDesigner.propTypes = {

}

JdgameDesigner.defaultProps = {

}


export default JdgameDesigner;
          