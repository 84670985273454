
import React from "react";
import PropTypes from "prop-types";

import "./jdanimator.css";

class Jdanimator extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="d662f8a6-525c-4567-b5fc-adc586961894" className="jdanimator">        <div data-layer="7c583ad0-8dd1-4f34-a7bd-c0fc6091ff67" className="layer1">            <div data-layer="a6333f95-537d-4af4-9161-fb3e46c221a8" className="thinkSmartStudyGamesIp2024Rpg">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="84c21468-4758-42c4-ae1a-7a35041ccd42" className="x10981">                <div data-layer="af07a840-98a5-45b6-968a-e8610fa1e822" className="x3db003b7">서류 전형</div>
                <div data-layer="ea981509-0498-451b-aa79-cdcbf6826c5c" className="x38923168">실무 면접</div>
                <div data-layer="1cd41648-6b4e-45c9-a4dd-cffed1a058c5" className="xe1ca9616">최종 합격</div>
                <div data-layer="e2311772-6616-4ea8-9d37-0e3fdb1f1ee0" className="x10980">                    <div data-layer="0e364dc3-7707-41b4-b755-27aa1b2926a7" className="x10979">                        <svg data-layer="e491b73a-0976-4b97-acd9-4ec836de0be7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 896.95703125 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="ecfe7702-5a33-450d-8999-ddbece812b77" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="7a4b0846-a999-4c73-8c20-2b14a34d625c" preserveAspectRatio="none" viewBox="-1.5966796875 -1.59716796875 356.603515625 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="9dda18e0-a4b3-4f06-9429-4c717f004d24" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 80.427734375 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="858ad26f-401e-4b91-984e-8e22d1b4404f" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="41056d95-bebf-461f-b7a6-691ff7da9f0d" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="f142cec8-5bcf-4a87-ac22-901acca429be" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="c045717b-bbad-4101-abf0-3210b10cce91" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="e7ed613f-9406-4161-bfec-4b4d85fd5f53" className="x517b3cac">전형 절차</div>
</div>
                <div data-layer="6bde848b-4abd-48fd-be66-fd2a14c4fc4e" className="x8e02a2eb">임원 면접</div>
</div>
            <div data-layer="7cd04f5f-5884-4271-b154-cdce553ec099" className="x10983">                <div data-layer="c7e61756-4c8f-433c-be98-5b735512a221" className="x052f22ae"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="19480efa-23d7-4e25-978c-44682162d3e8" className="x04a3ac9a">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="1a6f99d3-68f7-470f-9458-6071d80df865" className="xd42e72d0">지원 방법</div>
                <div data-layer="903db6ff-3e8b-4c0a-b0b8-9eb7ee926e16" className="linesfadb19ab">                    <svg data-layer="5901273a-3ce7-4ed8-b76c-26f596641d77" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1483a35d4b0"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="11093941-06c6-467f-bd87-bf6c2770fc5b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142ba0231b3"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="52850044-6fc2-44dd-8e12-c064565f7de6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x14999eab9e9"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="a0921d34-75be-47bc-804e-66151265a441" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x143d6d4e47a"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="93bdb1d0-c018-4f37-871e-2bf37c2a23a8" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144e85c44d0"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="8acf407d-530a-4aef-aa0e-c26f9897b75f" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="a38dbb17-2ab1-439b-9cc3-5b49d6fd7638" className="x10987">                <div data-layer="2426192e-e1e3-412a-bc1a-dce2c5acd167" className="x3Unity">관련 업무 경력 3년 이상<br />다양한 컨셉을 제시하고 새로운 시도를 추구하시는 분 <br />Unity 개발 경험 있으신 분<br />자율과 책임기반의 자기주도적인 분 <br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="32eba7ae-788e-4432-899f-c2426390c9e2" className="x13c018e8">자격 요건</div>
                <div data-layer="147e0694-679e-4fb7-a6bd-f0eea420517f" className="lines1d8e30d8">                    <svg data-layer="0fe17f14-5c53-462c-8dfe-e8a33db76479" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148e1d4db82"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="3d21f60a-38ff-4409-8a51-74d45ab3d73e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142bfbfafe7"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="72277023-e4c4-4759-a0a6-2db4386b8e4e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1496d63e8e1"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="c800293e-838e-4642-8d2c-7f079be9ecaf" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x143522ea1b9"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="69fa8612-4240-46ee-99c2-13c4ff8c1762" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x14415f779dc"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="e56cff0f-3e19-41fc-97c2-3271ff895b4d" className="x10985">                <div data-layer="d17a9f3b-cf6f-4ce1-81fb-12ba1df1a455" className="x227764d4">게임 개발 애니메이션 리드 경험<br />카툰스타일 캐릭터 작업 경험<br />프로젝트 완수 경험</div>
                <div data-layer="a3b0f5b5-0731-4cf9-baee-8b076a0d2347" className="x3a982d1e">우대 사항</div>
                <div data-layer="7dfeeca7-5e17-4cf2-a203-82a956ad3005" className="lines48903f0b">                    <svg data-layer="cf55a588-223f-4eeb-9ba8-4415c1721bba" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148722df3cc"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="33832994-71b2-400b-beb5-de27f39e50d5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142a6927d53"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="b3035cb0-6c3a-404d-8f61-cc6ecf977b2a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x14953ab9ba2"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="55ab312d-7f90-4d0d-96e8-7d088a76ee8b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1436df296cb"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="81a93327-9cd1-4e3a-afe3-14ac23c08706" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144c6fc110b"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="eb0160df-9c51-41ee-b3c7-f05d13ef6035" className="x11012">                <div data-layer="cc70b233-a4dc-4ab8-9b01-66adbdeadfe7" className="xL">신규 글로벌 프로젝트 L의 캐릭터 리깅, 애니메이션 및 연출</div>
                <div data-layer="16796811-f279-4c6a-9f62-53a759e69b42" className="lines">                    <svg data-layer="f8c109d2-a209-4ef6-9c96-d2a8120b7975" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 248.8359375 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="731d24d0-537c-4f51-a33b-8ade01f08890" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 248.8359375 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="03649ca3-9923-45a1-8986-1505ffcaa65b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="a3c48c61-e4fc-48c2-bd5b-b7d849c62718" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="cb0ee884-5bf7-41ee-bd0f-d4718383d34c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 70.8076171875 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="fcb7ff15-5b1b-4fea-a5af-7c1979c88cea" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdanimator.propTypes = {

}

Jdanimator.defaultProps = {

}


export default Jdanimator;
          