
import React from "react";
import PropTypes from "prop-types";

import "./jdmarketingLead.css";

class JdmarketingLead extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="b131986f-4bde-4b4b-9101-eac9bc41d6a8" className="jdmarketingLead">        <div data-layer="acebc928-faf9-44be-b459-b3d123b3ece0" className="layer1">            <div data-layer="4d6dbb3c-9237-4686-9a91-ca651c3514bd" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="dff5cf97-2e54-4343-adc2-2cd9839c9866" className="x10981">                <div data-layer="7a642fdd-6cbe-49e6-9dbc-bc1bee7cc472" className="x78b598f1">서류 전형</div>
                <div data-layer="97f17d2e-8271-4f35-87f6-a418d83d6f63" className="xbef5fa51">실무 면접</div>
                <div data-layer="6099bde7-3cfd-4c94-aa2f-49014f74dbdd" className="xe5aca902">최종 합격</div>
                <div data-layer="a0c056e3-eb87-48e8-b634-c4cce9a2cc4d" className="x10980">                    <div data-layer="42b57931-1135-4200-a7ac-8684cdfa6197" className="x10979">                        <svg data-layer="8eabd719-7233-45f4-9548-568bec9f2b45" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 896.95703125 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="bd79f5bf-caf1-4484-a134-b9ab5e72a114" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="fb48af57-f36b-446e-a6dc-1efdd621a2cd" preserveAspectRatio="none" viewBox="-1.5966796875 -1.59716796875 356.603515625 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="f65a4e8b-78bc-4bb7-870e-718ad61df51a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 80.427734375 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="57111a9e-d343-4922-b850-2fb214221eda" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="372002e7-a300-46fc-8ef9-eb777cc63261" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="a505e2ed-0cd3-472d-8ce3-4203a501689b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="23bfc2c2-ff50-4cad-9751-40759fa837b2" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="4d6d5f43-794f-463e-a2ca-ca6b256a42be" className="xa25208ff">전형 절차</div>
</div>
                <div data-layer="6a1574da-c45d-4f33-8cfb-a00812d456e3" className="xeb550bad">임원 면접</div>
</div>
            <div data-layer="fd62dadd-8825-4d3b-9788-4798b4e63472" className="x10983">                <div data-layer="3e407603-7441-450b-b6d5-a410ed7fb629" className="x295fe2b8"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="2ffc8db9-1cb9-4ee9-a0c5-bdc7b41e4dd5" className="xc879eaa8">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="c3699077-c723-40ee-8c1c-125b36727e96" className="xb6ba5af3">지원 방법</div>
                <div data-layer="8492640a-da78-4b26-b027-4d995bdf2d81" className="lines40446d27">                    <svg data-layer="c0abe53b-3afe-46ff-8497-0541b8bebfd2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1489170b6d9"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="ff1fe72e-84f2-44c0-94e5-2ba7b4b65444" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14227013f1d"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="e0454e86-e84f-4ed0-b568-4f46b16b52f7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x14998920d38"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="eff055ea-1777-4f1b-9c23-c6c4a972f7f8" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1436ac50c76"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="b6b25ccd-c318-4c77-b82d-fdddd91581ee" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1446e7e05f3"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="33b8f649-aa1a-4adc-871c-29b5e3ef2906" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="a8fb3f4b-4cf2-4d9d-8e1b-8e23da8a98b0" className="x10987">                <div data-layer="7b6ee294-a6cd-427c-8595-8b9843c2e1b5" className="x5DataDrivenDecisionAttributionToolAppsFlyerAdjust">관련 경력 5년 이상인 분<br />마케팅 전략 수립 및 집행 경험이 있는 분<br />Data-driven decision에 대한 이해도가 높은 분<br />Attribution Tool 사용이 능숙한 분(Apps Flyer, Adjust 등)<br />글로벌 트렌드에 대한 이해도가 높은 분<br />비즈니스 영어 구사가 가능한 분 (이메일, 문서 작업)</div>
                <div data-layer="70307ed5-99fc-4f81-842e-6b7337dd5049" className="xe298248d">자격 요건</div>
                <div data-layer="716e54e5-5cf2-4b3f-bfbe-b6a9e8d94b30" className="linese9315524">                    <svg data-layer="e895b492-375a-456e-8053-2cc8ff53e56f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1481334f97d"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="091b4d4a-3448-4867-817c-f8b2f5d17810" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1427a3d8893"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="36e1cb7d-1fe8-40db-8efc-d11204d87411" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x1494bde68d6"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="36e1b9a1-30ed-4960-b58b-2cdd113ee5b3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x1430d049879"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d78c8606-587d-42b1-811a-ce08aeea826c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1442bd835d8"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="a7c5ddfd-713a-4400-bdf3-b707867c2973" className="x10985">                <div data-layer="94a827df-b6f4-46b2-b19f-73842ca1a6b4" className="x28942c4c">비즈니스 영어 회화가 가능한 분<br />디지털 마케팅 대행사 또는 미디어랩 근무 경험이 있는 분<br />소셜미디어 콘텐츠 전략 수립 및 콘텐츠 기획 경험이 있는 분</div>
                <div data-layer="dbb481e2-3faa-4a90-b357-d516539a635e" className="x5c117675">우대 사항</div>
                <div data-layer="8854da8e-063b-47db-a39c-40e74fca301c" className="lines7277ed15">                    <svg data-layer="73008b10-826e-47ad-8b22-9e32c4aca5f0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14835d22a1a"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="69c33ce0-234e-448c-830e-2cf59bba27a8" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142ef674199"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="858a6aa3-ecb3-4679-bf61-321f7f8a6486" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x14988c0ffc2"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="69a09677-9770-4d30-a556-5441ff0066cc" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x143b80623cd"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="4eadebe5-8712-4978-b0e3-c8542d9ee9e6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144ce8326d9"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="d0ec9d25-eb88-4bd4-bf14-29247995f5f8" className="x11012">                <div data-layer="fbdc05d5-bf26-4681-9bdb-a3364f294c5c" className="xUa">글로벌 마케팅 전략 및 UA 계획 수립 및 실행<br />게임 광고 크리에이티브 기획<br />글로벌 광고 네트워크를 통한 광고 집행 및 리포팅<br />마케팅 지표 관리 및 효율 분석</div>
                <div data-layer="678f05f0-62e8-4191-b71d-5feae21f87af" className="lines">                    <svg data-layer="19272c5a-97f6-4174-a47c-b9fe3691ab3a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="b0a01aae-a760-4a8e-9c3b-b5df717e8a6e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="815c032e-0501-495a-bb18-36f6e2d7bea0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="c7c61d1b-7d3c-49c2-a4ac-a320c2afd5b5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="3757a1c4-4a88-4106-b3e9-dd8bbca93909" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="b4454424-14bb-4b15-b0b0-a371e531c5e7" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

JdmarketingLead.propTypes = {

}

JdmarketingLead.defaultProps = {

}


export default JdmarketingLead;
          