
import React from "react";
import PropTypes from "prop-types";

import "./mjdnewproj.css";

class Mjdnewproj extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="ef4871f3-b466-4cb5-96c0-54bd58c7b637" className="mjdnewproj">        <div data-layer="b302216e-e4d8-4db5-ab0e-d723113989d3" className="layer1">            <div data-layer="3043f9e6-4533-45ff-ad24-c70db30d4bb2" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="0674e817-7ff4-4568-989e-c84fa25e61e3" className="x10983">                <div data-layer="fda6f517-9db0-4a97-87e3-ac7433cb8ab0" className="x9cd954a1"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="7fe21c7f-5a97-4014-b50a-2cd6c7a05615" className="x350a6eb4">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="96473b9f-1acf-4ba7-a870-478c3fe29eed" className="x3c0d5c74">지원 방법</div>
                <div data-layer="c5522e6c-c880-4570-8d18-3ed4d91a9120" className="linesfedec056">                    <svg data-layer="44d1ae69-3d98-4423-aa0c-e7bcdfef1f15" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x14842fe6aa7"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="904f132c-2835-4476-a395-f39378d0cc0b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142bdcdc25c"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="9c297b45-7473-444d-ac99-d2519fb3711e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1495779e794"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="84d6a1e0-90f3-4ecb-9d9a-d94f3c7cf98c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143182c0cea"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="3cda7652-e78a-43cb-9a36-ea677e27019a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x1445d5a4f66"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="a045606f-2242-4b16-9877-6060175817e0" className="x10981">                <div data-layer="e6dfbaf2-6359-4d59-8ee7-40c9ac9de653" className="x4dd0831c">서류 전형</div>
                <div data-layer="f92138e4-ed20-4091-8eec-c8d16ff0f8fc" className="xf9af815b">실무 면접</div>
                <div data-layer="6fe3ed18-e4d4-4953-9960-6d04b897ffa4" className="x7594a683">최종 합격</div>
                <div data-layer="401ced1e-6ed5-4796-bad7-2c07c8664413" className="x10980">                    <div data-layer="a5e4379a-956a-40bb-a8fb-00cbf016bf32" className="x10979">                        <svg data-layer="deb248b6-04d9-4540-bc28-89e1b5de7db9" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 501.189453125 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="86a6ec63-37d3-4aeb-83af-88c50d5d54bc" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 126.189453125 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="fd145a8a-f8ad-4b83-8381-cdbed41ad19f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 213.189453125 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="5207bb5a-e6c7-4eb7-93b9-b8879d9e572d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 53.189453125 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="82463a77-324d-4f98-b092-af8b9232bc02" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="e305605e-02d4-41e4-901e-0c8fc5106141" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="4cbe5d89-8d43-4349-920f-8bd123d30b9e" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="62a0b622-c3f6-47e2-a195-0e9936b72659" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="633eccf7-7ee0-4fe3-b85c-ff76611d2402" className="x38b3e879">전형 절차</div>
</div>
                <div data-layer="c96c12ca-1fc8-4ceb-9b25-3520bfcf49d7" className="xc9b5051a">임원 면접</div>
</div>
            <div data-layer="cc3eebce-4449-401f-a8c5-d4a185973841" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="696d91cf-9b93-4a26-b887-bed17b2b712f" className="x10985">                <div data-layer="e39ecf1e-1dc5-47d1-aa5b-ad648554ad93" className="androidios">모바일(Android/iOS) 플랫폼에 대한 이해와 개발 경험이 있는 분<br />새로운 언어, 환경에 대한 두려움이 없는 분<br />개발 문서를 읽을 수 있는 영어 독해 능력이 있는 분</div>
                <div data-layer="0c933996-66fa-4392-994c-75fa53023a60" className="x88cd5d54">우대 사항</div>
                <div data-layer="46b4f031-0465-4d4c-b0f1-af6b75f260f4" className="linesf0052b36">                    <svg data-layer="2f147a24-d576-4ae7-97b4-8f640df0fbb7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148c8eb42d7"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="3f1ab374-8d30-4eea-a7a1-76647979ca7a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142ca821377"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="53f3b39b-b398-46b0-9554-ecad07ef4a7a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149e88c8c60"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="91aa3e35-1e72-4a0e-a214-474c21165448" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143f6e99626"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="8470a542-f05d-46cd-8904-b834dbdc0b9c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144b50d0fdd"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="a0db3a83-8c36-4006-8d0b-aace23322be0" className="x10987">                <div data-layer="c3772a3f-1c8b-4da7-ad61-c95384ffd715" className="x1CC">모바일 게임 개발 경력 1년 이상<br />C++, C# 언어 중 하나를 능숙하게 사용하는 분<br />개발에 대한 열정이 있고, 문제 해결에 재미를 느끼는 분<br />타 직군과의 협업에 대한 두려움이 없는 분<br />다양한 게임을 많이 하고, 의견을 내는데에 주저함이 없는 분</div>
                <div data-layer="5eb81cec-308e-4c96-88c3-174c932ede84" className="xe604858b">자격 요건</div>
                <div data-layer="1b977e2a-94ed-489d-a733-e9ebc79befc3" className="lines33381c2c">                    <svg data-layer="b51ed220-d1d2-4577-a04a-f60eaaf07c61" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x1483677f793"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="57c776dc-3564-4f27-9f50-0ba23d7457d9" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142e201e5bd"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="2c47d89d-b9df-448b-8dea-3c2d5995e100" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149284d3089"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="c60a5b22-9ae0-4921-a7fc-fae171c5b1a1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143bfe42b5e"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="9f005934-bc29-4bf4-8e55-b06a16ac06bf" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x1441d07a78e"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="b25056e1-599d-4fdc-b24b-1f73fee8e643" className="x11012">                <div data-layer="8fe84a8e-6f1f-45ce-951d-13ddaf86350b" className="androidiosUnity">모바일(Android/iOS) 게임 개발<br />Unity 기반 게임</div>
                <div data-layer="b0182207-e8f0-4cb5-95f6-e27f508a5938" className="lines">                    <svg data-layer="918e6395-b3fd-4ccc-9773-2549906e5d2e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e08d6f4b-96b6-4373-a38e-84d21670c763" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="32c0d78d-83d1-4b4d-80ff-1bcdeae74232" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="cc0d9d1b-825c-4175-a04d-5215d6e47bd5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="f06e8559-20ce-4a07-b776-c8fa78229e9f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
                <div data-layer="0815867e-a7f7-4948-8aa6-dd9828e384b7" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Mjdnewproj.propTypes = {

}

Mjdnewproj.defaultProps = {

}


export default Mjdnewproj;
          