
import React from "react";
import PropTypes from "prop-types";

import "./jdmobileui.css";

class Jdmobileui extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="c89f2bb5-4eb1-4008-8d6b-866a64dbe2e6" className="jdmobileui">        <div data-layer="d4f2b254-0caa-49d7-ade6-c3806c247bb2" className="layer1">            <div data-layer="7c0e9966-96a6-482b-b83b-cdb2f20e5a66" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="d78d8e4c-7c40-41bc-8385-e439a6901d67" className="x10981">                <div data-layer="77d1f6f3-9151-4f18-a576-9426bdf03974" className="xc8bb2dc1">서류 전형</div>
                <div data-layer="faebb08a-8db0-4ca2-9d98-47137fb65541" className="xa8ad3157">실무 면접</div>
                <div data-layer="ac3ec571-4740-4e05-aca5-e03ec6911302" className="xf994ddd1">최종 합격</div>
                <div data-layer="0fdc0991-b12e-4085-8330-23d1296214cf" className="x10980">                    <div data-layer="11b5eaf1-9f64-4a83-a985-fd5c82500aef" className="x10979">                        <svg data-layer="6d3e2400-24a4-44c4-8565-6fcb67f95659" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="2d0a1d8d-924e-4a95-9e87-4570d4204169" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="27e95ea1-5360-4a7f-bfc2-aa76abdeff76" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="48c9cfa8-39e2-4a7e-b642-70b3f8bc1471" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="e92f236b-374f-40ac-8ddc-3793cddf8e93" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="e9b7cf54-5bc4-4257-b69e-a0f8d0ab637a" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="50082ea9-afac-412f-81b7-82cd4c8c3bc2" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="3e854641-2c84-462b-b84b-278c5954dced" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="afc16d4a-e332-41bc-8294-93725d13bf74" className="x2bf2f01e">전형 절차</div>
</div>
                <div data-layer="ddf157af-5bb8-4f71-b289-4a962ca12c9f" className="x94fcb3b0">임원 면접</div>
</div>
            <div data-layer="a0e26926-c82f-4e59-a667-cc0d4c1b5d00" className="x10983">                <div data-layer="08fb9df5-6992-4022-a57c-1c8e9b8de7a6" className="xd3d4b164"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="5d477574-c544-4fa1-9d4a-9c8283e4cf02" className="x20524483">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="5ecfed50-cb96-4f26-b695-c35a40d4c863" className="x2127f1b0">지원 방법</div>
                <div data-layer="a5fae719-0476-4065-871f-fa4ef9a2fb93" className="lines0beb86be">                    <svg data-layer="bb813890-d3bd-4523-bb23-6c97f17dd4a2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148ef114e2e"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="90250c64-43c5-4f6e-a694-56e6ac7e71cd" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14250f94d26"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="3bbd1f3e-aa30-498d-b02f-f179b072f8ef" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1490145e1ee"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="4d95c38b-caba-4ea7-aef8-f97ec8394ef7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143c2aff2d6"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="efb0282f-0d25-4f7c-9405-e75a711f9a89" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144f359e01f"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="15605f8c-e5af-4594-8191-67596bb9ff60" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="5ca007e9-743d-46f5-b83d-bf6f37497fea" className="x10987">                <div data-layer="4f1d28eb-a1a5-4c20-88f9-84ecfdb1d283" className="xUiUi3Ui">그래픽 (포토샵, 일러스트레이터)툴들을 능숙히 다룰 수 있는 분<br />UI 설계 및 인터렉션 디자인에 대한 이해가 있는 분<br />UI 리소스 드로잉 및 이미지 편집에 능한 분<br />3년 이상의 모바일 게임 UI 디자인 경력</div>
                <div data-layer="8a3f5bea-93c6-4c3c-bb9f-c3d8306ef72a" className="x3deb46aa">자격 요건</div>
                <div data-layer="a262afb5-1650-4fa0-ba77-f0d2bb5ac07e" className="lines1e0380ba">                    <svg data-layer="744ff88d-2604-4113-8d77-4a83a8205369" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14802fa0b01"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="4bb69436-e14a-4d26-a60a-c0c34195470e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142d08b7efc"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="ecdf0ccd-e0d6-4364-a9e2-65b712c575ab" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14972dc18db"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="2b92f534-c749-46b9-b0ca-b4e837a5cec4" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1439624e22b"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="9c2a1b26-58ea-4c4a-abbd-7777f1e764c0" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144d1f680bc"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="544828f4-490f-4081-b50d-3dcfc98ec045" className="x10985">                <div data-layer="315dd302-0504-432c-9b7c-0d9860144f1a" className="xRpgUiUnity">캐주얼 또는 반실사 풍 RPG의 UI 제작 경험<br />모바일 게임 프로젝트 라이브 서비스 경험<br />Unity 사용 경험</div>
                <div data-layer="c2979a77-90dc-4642-8b45-6850c2b60b69" className="x84ac589e">우대 사항</div>
                <div data-layer="17b4da8b-3110-43a8-bcba-dd72add5b832" className="lines95a8d2f6">                    <svg data-layer="a4226df8-f851-4c90-b48f-1b3338388ef3" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148418b2826"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="6062ae26-4c37-4fd2-b0a9-b3f9d64b1eae" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14252d33211"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="dab6562f-dcc9-4cae-b9b8-f389b132d130" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1495a43379f"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="703427d7-88a4-46ce-89ad-1c9715b21db7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143a1400423"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="15bd67a2-7ad3-4ecd-81e1-983cb9fa5c4d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144a96dda5a"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="051a55a1-f0b3-4b16-ad61-c7838974b109" className="x11012">                <div data-layer="0dbd9156-a4cc-47c1-ba3d-f8afa56b6a3d" className="xLUi">글로벌 서비스를 목표로 하며 하늘세계를 배경으로 하는<br />모바일 게임 프로젝트 L의 UI 디자인 및 제작</div>
                <div data-layer="0e9909da-0f97-45af-81a9-8092cc3db9de" className="lines">                    <svg data-layer="931dce9b-678f-4311-8ca7-26caba7077c7" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="56f37240-08c6-4cf9-976c-d051dbbd8c6a" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="afdbe311-9323-4400-aa4c-c5373321caf6" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d0d7a7f4-8016-415e-8d09-7734cabb533f" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="25503ab5-1714-4726-ad53-d47967172c87" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 70.80859375 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="58cebf92-287f-4355-8ff9-8de34ab46855" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdmobileui.propTypes = {

}

Jdmobileui.defaultProps = {

}


export default Jdmobileui;
          