import React, { useRef, useState, useEffect, useCallback } from 'react';
import './culture_impl.css';

const CultureImpl = (props) => {

    const [isExpanded, setExpand] = useState(false);

    const onChangeExpand = useCallback((isExpand) => {
        setExpand(isExpand);
        // console.log(isExpand);
    })

    const recursivelyMod = (children, bSet=false, strTo="") => {
        return React.Children.map(children, (childNode) => {
            
            if (typeof childNode === "string")
            {
                return (bSet) ? strTo : childNode;
            }
            if (typeof childNode.props.children === "string" && childNode.props.className === "moretext")
            {
                return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children, !isExpanded, "더보기 +"));
            }

            if(!isExpanded && childNode.props.className === "culture")
            {
                const styled = {
                    height: '5137px'
                }
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children)); 
            }
            else if(!isExpanded && childNode.props.className === "expandablecontents")
            {
                const styled = {
                    height: '456px'
                }
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            else if(!isExpanded && childNode.props.className === "expandableinner")
            {
                return ("");
            }
            else if(childNode.props.className === "expandableend")
            {
                let collapsed = !isExpanded ? " collapsed" : "";
                return React.cloneElement(childNode, {className: "expandableend"+collapsed}, recursivelyMod(childNode.props.children));
            }
            else if(childNode.props.className === "buttonmore")
            {
                return React.cloneElement(childNode, {style:{ cursor: 'pointer'}, onClick: () => onChangeExpand(!isExpanded)}, recursivelyMod(childNode.props.children));
            }
            else if(!isExpanded && childNode.props.className === "navitogames")
            {
                const styled = (!isExpanded) ? { top: `4516px` } : { };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children)); 
            }
            else if(childNode.props.className === "contentbody")
            {
                const styled = (!isExpanded) ? { height: `4431px` } : { height: `6044px` };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            else if(childNode.props.className === "footer")
            {
                const styled = (!isExpanded) ? { top: `5021px` } : { };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children)); 
            }

            return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
        })
    }

    return (
        <div>
            {recursivelyMod(props.children)}
        </div>
    );
}

export default CultureImpl;