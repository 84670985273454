
import React from "react";
import PropTypes from "prop-types";

import "./jduiDesigner.css";

class JduiDesigner extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="b70a1420-d25f-4697-9924-e128ae2caee7" className="jduiDesigner">        <div data-layer="62c8434b-847a-4843-9622-b0e901a44b7a" className="layer1">            <div data-layer="0b9e33f9-628e-41c8-a0d5-9934d7c91d73" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="d9621ebd-ebd8-42ee-a749-8f5e33880d89" className="x10981">                <div data-layer="4481e933-07e3-4b4f-aefd-22bd9aac82cc" className="xc5619e7b">서류 전형</div>
                <div data-layer="490ac0d6-3f36-453d-a3e0-a26e32028d45" className="xcc9125f9">실무 면접</div>
                <div data-layer="c70a18cf-b1ed-4caf-b206-d5bc401081c2" className="x95f29f10">최종 합격</div>
                <div data-layer="1e471b8f-d1db-4eba-9581-cd8e8b78ac8c" className="x10980">                    <div data-layer="3a4bec20-643f-430f-b86a-926cef5179f4" className="x10979">                        <svg data-layer="dbb0a4bc-090c-432a-b436-da1d8f2d7e0c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="52e70043-c1fd-4001-8457-7bed4d15040e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="20d6f358-c54f-4a3b-a614-1de98adfbcb7" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="565c6fa4-29bb-4739-8c7d-9bd4a5d54e8b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="cede2888-7f6f-450d-aa02-5bdb9fcba354" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="194868d6-2bbe-44f5-944d-cbc4d9c560d9" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="40aa0133-343d-4c58-8a92-745f04203f5b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="fb20c6c6-9c4f-42fe-8803-8011e53a492b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="6c6f8c8b-b9ce-4d06-b99d-02bf33499194" className="x86f77af9">전형 절차</div>
</div>
                <div data-layer="decd42f9-14fc-4e4d-9898-375664522a2b" className="x7f59383c">임원 면접</div>
</div>
            <div data-layer="ba86cb1f-cb74-43e0-a50c-d52c93f35961" className="x10983">                <div data-layer="428562ef-e9bb-478b-acac-678e5053d782" className="xd4365b18"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="58adbb20-c8cf-42dd-858a-1cd59e687f5e" className="x220ad440">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="e2147898-666f-4bf9-b5fc-18f8676ae91d" className="xae83f867">지원 방법</div>
                <div data-layer="9a84bb85-1624-4813-bfe3-e6af583fe959" className="lines1bb135cb">                    <svg data-layer="f8608ad2-c29d-4da5-bc8b-a50e0c7065ea" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148ccd7a86a"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="49465d46-60ed-45df-bef7-381390066655" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142d430b375"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="dc7fecf4-87da-4fb4-93a2-315f45f93277" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14961199378"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="8b119e90-6e0c-40e6-9a6d-35fea33d6e09" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143dfd98e27"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="e641fda3-8a4f-49a1-a980-2abeba7a3b2a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1441a70acc5"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="52720ba2-96de-4191-a5b9-3537e43a7594" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="4cfebae4-8aef-4086-aa05-0abd40d633b7" className="x10987">                <div data-layer="1940da91-851e-44f3-903b-d2333e2db73a" className="x2Ui">2년 이상의 모바일 게임 디자인 경력<br />그래픽(포토샵,일러스트레이터)툴들을 능숙히 다룰 수 있으신 분<br />UI 설계 및 인터렉션 디자인에 대한 이해가 있으신 분<br />구체적인 업무 내용이 포함된 포트폴리오<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="c2cc1437-6e8c-4a5c-a030-c3d90241d9fc" className="xf823b4ac">자격 요건</div>
                <div data-layer="8685fded-7d45-4582-bac7-dfdc0992d926" className="lines8b7733cb">                    <svg data-layer="9c52ab85-b2bd-4873-9cc0-fff8ef86a755" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148f66316d8"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="17b56db4-9392-4a14-9b93-88df85bc123a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142f3ce398d"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="810d1b46-c670-4c34-be28-13b6e08f1b76" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149aa5c16d7"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="36e23af2-8e55-4524-a9f6-4ba1fd1206a8" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143bfe554f6"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="bbcc9588-15f0-43f9-bd8a-f916a05d63c1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144ac04cfaf"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="99d90b94-e754-434e-bf9c-d95cff38f153" className="x10985">                <div data-layer="95b94e7b-6b5d-4a20-95d3-19043a14ecf0" className="xRpgUiUnity">캐주얼 또는 반실사 풍 RPG의 UI 제작 경험<br />모바일 게임 프로젝트 런칭 및 라이브 경험<br />Unity 사용 경험</div>
                <div data-layer="5afb036a-6790-4e47-ac48-5fba0f1c1d47" className="xe1c9509d">우대 사항</div>
                <div data-layer="6310b395-bc71-41dc-9482-04f4fdc6949d" className="linesec2817b0">                    <svg data-layer="22ef4b17-86f8-44ec-8233-c94e951d01f5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148544e3359"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="6ebb81d5-8b30-4b1f-93c9-f06b1240321d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1428a4db860"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="34d1c3b0-0825-48fa-9901-095f6b7ed360" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1496fdb79df"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="9e0a0db4-d415-4766-9fbb-f032e0ae7180" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1434acca93a"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="05ced0c8-7509-4dc4-8257-6f5a5ed1e320" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1448d4c8804"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="438472ec-997b-4be7-b9ad-2ec603d0b90e" className="x11012">                <div data-layer="f18f47c8-c0c0-41f3-a4b8-83053d22cf67" className="xLUi">글로벌 서비스를 목표로 하는 신규 모바일 프로젝트L의 UI 디자인</div>
                <div data-layer="45068fef-e6c2-4684-9105-fde49985a9be" className="lines">                    <svg data-layer="c4e14c48-edba-4830-b481-50357bd11b06" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="ab3e22dd-6ef0-46b4-8622-fd894565541e" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="9c1f9e1f-9372-4186-bcb3-18b72f16017f" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="80388ab3-e905-48e7-8c16-49a7dabb6896" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="bc055771-ab0c-423e-9877-0cd33f73840f" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 70.80859375 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="2488ccc9-f44c-4712-898d-4344fdade87a" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

JduiDesigner.propTypes = {

}

JduiDesigner.defaultProps = {

}


export default JduiDesigner;
          