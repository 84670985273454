
import React from "react";
import PropTypes from "prop-types";

import "./jdbgModeler.css";

class JdbgModeler extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="22f5987d-1751-402e-ac20-6f20247bb1a9" className="jdbgModeler">        <div data-layer="75c5ef06-6ce9-4a94-a09b-26e427014efa" className="layer1">            <div data-layer="ea348ba7-9fd9-49a8-a24b-22ae5e434064" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="e206d1db-b52f-4cd3-b799-81a792a1fa4f" className="x10981">                <div data-layer="9a4987a3-801f-4feb-9bda-5593cea537bd" className="xe73ca9b2">서류 전형</div>
                <div data-layer="a16e8f61-e0b8-4d28-825a-3ca768752334" className="x7b5a2e37">실무 면접</div>
                <div data-layer="84fc705a-bdde-49b3-930e-b3a2568ef232" className="xbf11c7cf">최종 합격</div>
                <div data-layer="de9104a9-5211-44da-9233-26743cc7d1d2" className="x10980">                    <div data-layer="dad099d3-94f4-4a11-a0dc-77814bfcb2d3" className="x10979">                        <svg data-layer="5e8baef8-9755-44c9-a69a-b6b0fc22d4ed" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="7056cb1b-5238-4b49-9bd6-3eb7df1aea82" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="d783c249-828c-4b98-9e0e-ed4e1327e002" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="7bce7398-dd7e-4574-8d56-67ff0fd51d4f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="3e3a7f59-4022-40d2-b4c3-4208f5484178" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="a79e5e83-b99f-474f-afad-48d2675e440c" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="32f1680a-e067-4a58-979e-a11f97786931" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="a5928a3e-82fe-4fb4-bb42-80b95880f9fb" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="cf516fef-7f92-44a3-9748-831078e90ce2" className="x90807a35">전형 절차</div>
</div>
                <div data-layer="3700ca28-6c5a-40eb-af4a-931c3a92313c" className="x6115f321">임원 면접</div>
</div>
            <div data-layer="9b6454ee-9cc8-4e6f-8891-8ab32229b29a" className="x10983">                <div data-layer="040056cf-a68b-4d4a-a9bf-9e028b4088f0" className="x359da750"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="8d204d4d-cc9f-4788-9b6b-2a059ea26efe" className="x4f3b594c">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="c28bac8e-cd81-4f90-ad29-100c05c3cc0d" className="xc5cfbe75">지원 방법</div>
                <div data-layer="83e6c3e3-151e-4fb8-99f1-953b0ddf0af0" className="linesaefdd7b1">                    <svg data-layer="c1f87996-84b8-4fb9-83c5-f84e3ba7fbea" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1485286fbda"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="56c6ff02-487c-4575-be3f-eff9fa56809b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1420ba03d87"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="a2cb86d8-83d1-4626-b639-6febe168c9e5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149dedac7ff"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="256bc8ac-27ba-4de0-b7d4-8a3f9e8d5738" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143f0a556ea"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="9b2ffc74-bbe0-4e9e-b8c9-a8007b0519df" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1444b6243e9"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="d5513c2e-289f-488c-9c73-b220f51eb385" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="ced435ee-e034-4173-ab87-9df4c256b1ec" className="x10987">                <div data-layer="b8734b03-3619-4708-b145-24a42b40472e" className="x3Ex3dEx">관련 업무 경력 3년 이상<br />스타일라이즈한 배경 제작에 능숙하신 분 ex)지브리, 원신<br />3D툴 활용능력이 좋으신 분 ex)섭스탠스, 스컬핑<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="5b55bcaa-fa90-4c06-a249-7609cc110f61" className="x76747291">자격 요건</div>
                <div data-layer="380a8d53-18c4-4466-b923-c5f4fff031f7" className="linesb6e3df26">                    <svg data-layer="9301e7e4-ccc3-42ad-be47-692a7e7f06b5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14855aa128e"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="b16551ba-4b07-4517-9fdd-69ec8cb979c9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14259b09b16"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="2b85d481-1c6c-478a-a1e7-b8b93a622432" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1494e868caf"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="e45f6bd6-a7ec-4036-8292-46b8bef3dea8" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1430c437f19"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="43c6d025-a1d8-4ea1-a287-bedd4647ffd9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1449609e17d"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="fa36120e-e1e1-469f-9016-bb4f5f69103f" className="x10985">                <div data-layer="1ef8f8ac-a5fe-4c83-a86c-c679b753eec3" className="x46afd5bc">아트에 대한 기본기가 좋으신 분<br />프로젝트 완수 경험</div>
                <div data-layer="003447c4-b6c9-45d9-b974-3894a43205cd" className="x7582e005">우대 사항</div>
                <div data-layer="3dc926c9-0b1f-474b-b226-b57604fe24ef" className="lines942bc1e0">                    <svg data-layer="173fb95c-2e31-4273-87c0-642ac18373b8" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148f0e3b8b3"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="2e2a032c-37df-4af0-8d27-8d3c52f301e3" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14280d54969"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="4eb4d20e-8f26-46ee-8ff3-260876480f0f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1495ae835e7"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="f41d904c-4d41-4937-86fe-5e2c5a83b76d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143686df9b0"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="31d66576-adb8-4935-aa7e-735bf333cd6f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1441cc37a0e"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="e7384829-0000-432e-8cf3-51cee379dcd1" className="x11012">                <div data-layer="7c3e6172-f5a1-4e6f-92c5-644973b66f9d" className="xL3d">하늘섬세계를 배경으로 하는 글로벌 프로젝트 L의 3D배경 제작 업무</div>
                <div data-layer="8bf8b5be-6aad-4455-9f77-ce4ebaa67f2d" className="lines">                    <svg data-layer="9b112391-484d-4f6b-b63d-865156bf1b47" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="b78b129d-85b6-4645-8ce2-9e7297d0b8c2" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="9cfbe67f-ce46-4f26-85f9-c798457249c4" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="fba71141-cd1b-4510-abdf-6123fe5b63f9" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="bb494156-65f8-4f4a-af5d-0e3a8288461a" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 70.80859375 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="5d613c0b-ea18-4eb4-b8fc-58bc18299ab1" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

JdbgModeler.propTypes = {

}

JdbgModeler.defaultProps = {

}


export default JdbgModeler;
          