import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";

const MmenuImpl = (props) => {

    const loc = useLocation();
    const [strCurrentPath, setCurrentPath] = useState('');
    

    useEffect(() => {

        const targetname = loc.pathname.substring(1, loc.pathname.length);
        setCurrentPath(targetname);

    }, [loc]);

    const recursivelyMod = (children) => {
        return React.Children.map(children, (childNode) => {

            if (typeof childNode === "string")
            {    
                return childNode; // cover case: <div>text<div></div></div>
            }
            if (typeof childNode.props.children === "string")
            {
                if(childNode.props.className === 'text'+strCurrentPath)
                {
                    const styled = {
                        color: 'rgba(141, 55, 237, 1)',
                        fontWeight: '900'
                    }
                    return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
                }
                return childNode;
            }

            if(typeof childNode.props.className==="string" && childNode.props.className === 'text'+strCurrentPath)
            {
                // 여기는 앞에 요소여서 바꿀필요 없음
            }
            else if(typeof childNode.props.className==="string" && childNode.props.className.indexOf('mmenu') === 0) {
                const hideon = (props.isStick) ? "menushow" : "menuhide";
                // console.log(hideon);
                return React.cloneElement(childNode, {className:`${childNode.props.className} ${hideon}`}, recursivelyMod(childNode.props.children));
            }
            
            return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
        });
    }

    return (
        <div>{recursivelyMod(props.children)}</div>
    );
};

export default MmenuImpl;