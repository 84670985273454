
import React from "react";
import PropTypes from "prop-types";

import "./jduimsl.css";

class Jduimsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="636cc309-ad5f-437a-8424-36cd4190457c" className="jduimsl">        <div data-layer="f23810e8-192e-451a-9112-7633597f9567" className="layer1">            <div data-layer="0e8eeab8-0b4c-4b91-aa4e-4f56e2fe43fe" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="6aac53a0-0de3-423e-ab83-f570ee463199" className="x10981">                <div data-layer="9c0a8171-bd53-437b-8971-74144cf19170" className="xd1897996">서류 전형</div>
                <div data-layer="eb83d626-c855-4753-8575-461646432ab2" className="xaa5a5b7f">실무 면접</div>
                <div data-layer="874b909c-2e86-4d9a-9833-aa72c339c43a" className="x2f1279fc">최종 합격</div>
                <div data-layer="10256587-de3e-4a04-98f4-e342e8207409" className="x10980">                    <div data-layer="6828972d-9493-4960-a2b3-ef631336d118" className="x10979">                        <svg data-layer="06db0c4f-777a-4766-97df-549aa2465b2d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="d9ef4f53-0996-4ff3-8a1b-d1330291d1f1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="9720353f-b224-4e14-ba9b-a73b4390ed49" preserveAspectRatio="none" viewBox="-1.59765625 -1.59710693359375 356.60546875 3.6617431640625" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="37c3d9ef-6936-418d-84e6-2ac4b30755df" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="66be5748-57b9-45e6-a7ea-e6d7a671fd99" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="7dd9e395-dc21-4778-84a9-f2fc41722992" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="1590e7bd-e6d2-49ea-a1f6-f2b323fdfd6d" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="da0625aa-eb4f-4d4e-9701-5af720ea59fc" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="5ebec1f4-e54a-43ba-8c84-8773155526af" className="xdb6d23e9">전형 절차</div>
</div>
                <div data-layer="95e2a931-4fdb-4054-84e0-a039bdac8bf6" className="x5275ab7f">임원 면접</div>
</div>
            <div data-layer="36eebfa2-ed4f-4d25-b461-1e784bcc7f7a" className="x10983">                <div data-layer="b526517c-67e5-4deb-8ac4-9af135a43191" className="x1723a6a2"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="f5cc304e-cccf-4674-8643-c496faf8482e" className="x2670aff6">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="04f04aba-dd15-48b9-bea2-3b5b5970ecb7" className="x8a1fd658">지원 방법</div>
                <div data-layer="5d6d5301-0446-43c9-ba23-55e2f23c2976" className="lines5dfe0e85">                    <svg data-layer="c332811e-6f6e-4e34-90c6-16e409b12cf2" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 248.837890625 3.19000244140625" className="x14870201114"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="4e079f04-356b-437a-8761-769307aafd08" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 248.837890625 3.19000244140625" className="x142203e2bb2"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="056f0d80-c537-4909-a13d-cabce7e11d9b" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 310.4921875 3.19000244140625" className="x14950bad603"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d626a953-4ed0-45ba-80a9-842485d10ae3" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 310.4921875 3.19000244140625" className="x1432dca87ff"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="b42cb2a2-b141-4c20-9df0-df0cec47a89d" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 70.80859375 3.19000244140625" className="x144693b1a68"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="7dfd2d6f-10f8-42e4-b854-c3804b77fb04" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="3a84786d-1ac2-468b-9089-f92d84056a7a" className="x10987">                <div data-layer="a67479f0-a15b-476e-a3e8-a8a453a7b67e" className="x1UiUiUi">1년 이상의 모바일 게임 UI 디자인 경력<br />그래픽 툴 및 유니티등을 능숙히 다룰 수 있으신 분<br />UI 디자인/설계에 대한 이해가 있으신 분<br />꼼꼼하고 책임감 있으신 분<br />UI 리소스 드로잉 및 이미지 편집에 능하신 분</div>
                <div data-layer="0eddd8ba-b633-4ce6-bdaf-34bda447b071" className="xb730aed8">자격 요건</div>
                <div data-layer="4bdd6352-97fc-4da3-be30-1c754467b35d" className="lines378f1981">                    <svg data-layer="482d0c89-6724-4981-80aa-ea2d2b2aead5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148290cec64"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="d48dfbac-7639-4c2d-a593-f15f0cc6ee97" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142f2e40412"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="0367a0ab-48a3-42de-bbef-193a2a981f26" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14915f4490d"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="4d9940af-847f-454a-a1a3-b0415eb9d95b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143f0d3758f"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="156ec9d4-e59b-4ec8-ba8d-492ea270221f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1448bcd4334"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="473b8e5c-c3aa-4d12-a53b-468f361d927a" className="x10985">                <div data-layer="98ff6b91-4463-4593-9d7a-eec2154611ee" className="xRpgUiUnity">캐주얼 RPG의 UI 제작 경험 우대<br />Unity 사용 경험 우대<br />모바일 사용 프로젝트 라이브 서비스 경험 우대<br />원활한 커뮤니케이션 능력 우대</div>
                <div data-layer="1f0c6662-f10f-4c46-b0c2-5d8fff49bbac" className="x575d9c6d">우대 사항</div>
                <div data-layer="f30f838f-f4cd-41ca-8f7c-0f77ed237a09" className="linesd82fb7e9">                    <svg data-layer="6dc2e9fb-01d2-42f7-817d-629e205ab312" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14827a9165f"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="8ff63275-e4cc-43b1-9aad-f9223312dda8" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1428f7538ad"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="45a45422-f68a-4ff8-9b0b-fd37f824864f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1496ceb6f19"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="c4243044-1eeb-42be-9fc5-1ace5a66701c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143a372a308"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="f1789909-f3bc-4b28-902a-0e5e7552a01a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1442e4012c5"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="4d7334bd-51d4-4680-94e6-70ddb7e5c05c" className="x11012">                <div data-layer="54dd6821-56d7-4270-8e1f-188ee7b85250" className="xRpgUi">글로벌 원빌드 수집형 RPG ‘몬스터 슈퍼 리그’의 UI 디자인 및 제작</div>
                <div data-layer="7cca449e-2141-40f9-b566-478588822524" className="lines">                    <svg data-layer="df7fde7c-f9e3-4f1e-b2c9-122363f9f5c1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="9efc9e77-0fea-4b75-9490-ac5b474649da" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="51c33ed4-9155-488c-9458-df6834d2e175" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="eecc9b19-cd60-4de3-b6c2-a0a069498a47" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="81621ce8-0810-4fca-ab85-4ae8dbd4ac4f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="8997d240-db1d-4347-ac2f-da9cae3763bf" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jduimsl.propTypes = {

}

Jduimsl.defaultProps = {

}


export default Jduimsl;
          