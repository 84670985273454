
import React from "react";
import PropTypes from "prop-types";

import "./jdbusinesspm.css";

class Jdbusinesspm extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="bf3832a2-9804-42cd-bbc1-e082297a3afb" className="jdbusinesspm">        <div data-layer="d850b7dd-4225-4edc-aadd-d036a78748a7" className="layer1">            <div data-layer="48adc902-abce-4062-a3f8-2fcad35ee3b9" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="31707164-4bfb-4805-a49e-86f428c83942" className="x10981">                <div data-layer="71836389-9662-4583-83ed-bf4ca361397e" className="x8ca3d01b">서류 전형</div>
                <div data-layer="5dabc277-473c-44d5-bb95-6dc374b0bfc1" className="x345c179a">실무 면접</div>
                <div data-layer="25fb6f82-c4de-4080-b499-fdc5f90ad72b" className="x06c80063">최종 합격</div>
                <div data-layer="87a646f4-5b8e-4265-a8fd-321aa60896ab" className="x10980">                    <div data-layer="f8bcf75e-39b7-42b6-a9c5-187474fdf0d9" className="x10979">                        <svg data-layer="9c8e3659-13bb-4cad-bbf8-dc9afedb29fa" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="1c345d8c-2a36-473f-85a6-39ea20206aab" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="9dd68c00-d7ea-4886-bde2-925ea3df270c" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="486c2c88-164a-43cc-a7bf-4fab08e1cafa" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="79727ae7-fb0c-4c8e-a133-fd25dc0c0c5d" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="9db2c379-fe5f-4c80-9b99-0dd5d5ea1cf0" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="6340d15d-ab07-4c99-954d-995516242a1e" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="adb3df30-8485-4999-a46b-b6130f1dda16" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="502a9ebe-5ba4-465e-8c27-7c336f2ee4c4" className="x11492c13">전형 절차</div>
</div>
                <div data-layer="136384e6-9ec0-4076-8118-255de59c4340" className="x9ea113e0">임원 면접</div>
</div>
            <div data-layer="2b5ca9f3-bd02-4ec8-b64a-ea7961dde00f" className="x10983">                <div data-layer="582aa6ac-1842-4f4f-b67a-d63273bdeaa6" className="x41da7d11"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="a27ff7e6-34a4-4e8a-b5b2-9c10bae45882" className="x539b85b2">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="ca7712e2-89f2-48f9-a53b-d6c1d838a9b2" className="xef415097">지원 방법</div>
                <div data-layer="3ae3866e-e58a-4969-9425-8c137ff7d605" className="lines27165684">                    <svg data-layer="22358937-248a-4f4e-b58b-423f22e6ef53" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148f4097705"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="6ec140a8-7c7e-4273-a549-6f3fabb5224f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142a58866d2"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="cc9a679c-5035-4367-9b52-87cd864e175d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14970dc39c1"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="13211c49-2393-4088-86a3-c095b4036dec" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1431b5f6a38"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="30045a13-391b-4882-b79a-76039440bd21" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144771663e8"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="134f3ffa-be72-4e84-b454-417b0b96371b" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="893a20c4-3e75-4f71-8e47-b3e3c362b1ff" className="x10987">                <div data-layer="9714357b-49c9-414b-b5fa-77313d41f216" className="pmPm3">사업PM 또는 개발PM 경력 3년 이상<br />모바일 라이브 게임 서비스 경험<br />팀 단위 및 타 직군과의 협업에 대해 능숙하신 분<br />프로젝트 관리 툴 또는 소프트웨어 사용 경험<br />커뮤니케이션, 리더쉽, 문제 해결 능력 및 분석적 사고<br />경영진 혹은 유관 부서로부터의 협업 유도 능력<br />구체적인 업무 내용이 포함된 포트폴리오</div>
                <div data-layer="d11e700b-5639-4865-b402-675436ae93ba" className="x1246d8f2">자격 요건</div>
                <div data-layer="88b5efd3-4050-4054-bfb8-c5eaf8b161ba" className="lines830ba654">                    <svg data-layer="79151bc8-c655-4189-9563-383893a4a414" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148ff1da400"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="b87b46dd-e5ac-4fb9-ac03-8f2a8957d2f9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1424e36ef26"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="e2d3b455-f8ce-4fb7-8eb6-d0bdb0a2fd6a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1490cd62e2b"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="44bd16e0-600e-4f4e-bf24-267dd71955ee" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14365a4ed6c"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="79ed7cf0-80bb-479d-9c7a-dbf8b44ee33d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144655e9494"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="a984b1a8-da75-465a-b1d1-e461c4655ab0" className="x10985">                <div data-layer="096f34d6-67a3-4fb9-917d-29a6ba7c8bd7" className="xGooglePlayStoreAppleAppStoreJira">글로벌 모바일 게임 런칭 경험<br />Google Play Store / Apple App Store 마켓 관리 경험<br />애자일 환경 업무 수행 경험<br />JIRA 사용 경험자<br />모바일 게임 광고수익화 및 디지털 마케팅에 대한 기본적인 이해<br />하나 이상의 외국거 커뮤니케이션 능력<br />몬스터슈퍼리그에 대한 높은 이해도</div>
                <div data-layer="83c32a22-a5ea-4c8b-9508-cbf43eda11ce" className="xf452343c">우대 사항</div>
                <div data-layer="feb93823-bd4a-4749-9d2b-1032607e73e6" className="lines6432f147">                    <svg data-layer="613ec934-75b8-4c4f-9ad5-bfb65da4ac80" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148393d8d03"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="c25949f9-f69f-4ecb-aa3f-21c751a65f38" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142074cf50e"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="2b5808fe-1609-4151-95db-64573fd69ef4" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149bac92e3a"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="9cb41565-7a3f-4add-9bba-f4a425880ec1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14311592da2"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d9a376c7-dea8-4c3d-97d1-d23794f7904a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x14417a68467"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="1d6ea5d4-50dd-4c50-95af-52e7c1a4ef12" className="x11012">                <div data-layer="b8173b45-b082-4297-a47b-2ca2436cca58" className="xPm">라이브 모바일 게임 사업 PM</div>
                <div data-layer="84b6bff4-a4da-4363-ae14-736631f93abc" className="lines">                    <svg data-layer="26c44af8-4d2a-478a-a1d9-a78fa3d7703a" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="e3f52864-d035-418f-b348-a0ce0f1c31d2" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="80b9a27f-2bab-4f48-9589-2e4e2d52cf3b" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d7128d4d-01f7-44ff-9152-28bbd98ec804" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="619a9c01-3355-4455-8622-29ccd865bc77" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 70.80859375 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="6629c7c4-ace9-4cf3-b4c5-9bdd0418c435" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdbusinesspm.propTypes = {

}

Jdbusinesspm.defaultProps = {

}


export default Jdbusinesspm;
          