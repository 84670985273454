
import React from "react";
import PropTypes from "prop-types";

import "./mjdcharacterartistmsl.css";

class Mjdcharacterartistmsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="19d0e352-bee6-4008-b879-0ffb5c896de3" className="mjdcharacterartistmsl">        <div data-layer="5a4ea349-fb7f-405b-9f50-1da16794b101" className="layer1">            <div data-layer="382b4e16-6679-4b35-aaab-9b73819225e0" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="68063391-1371-462b-a52b-85f9d4df8e46" className="x10981">                <div data-layer="83a2a472-04cf-47b9-903f-5279b0919c02" className="xe2a9b1e1">서류 전형</div>
                <div data-layer="99bd90d3-8c99-4b4a-9610-c015db1416d1" className="xa44e285f">실무 면접</div>
                <div data-layer="7bf60fcf-8f7a-4ca8-ba25-c20ad8df88cd" className="x72f963c2">최종 합격</div>
                <div data-layer="4fed9082-fa80-434b-ade2-0e6ff6091697" className="x10980">                    <div data-layer="2d1dd4a8-864f-4414-a9e1-5c331f12b843" className="x10979">                        <svg data-layer="d542cd7b-a51f-4bb4-ae2a-f44daf2615e7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="0211585e-45a6-4626-bb75-67d966a53ffc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="769dabe6-8b35-44fc-a797-dcf25f86ad9a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="10d5bfa8-f022-454a-9757-edd00c82a911" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="178a435f-9416-4c8d-a808-a655ea0fbdcd" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="3dd35967-42b3-40bb-be62-9833eb2dc2b5" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="1737d3e8-8ad7-4c3a-930e-c069f36539e2" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="1ffdf01c-792b-4ac0-9b03-695c0260b7c1" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="590d4668-8f62-4672-8975-894dba7c96eb" className="x491187ab">전형 절차</div>
</div>
                <div data-layer="852836b7-956a-4c63-b002-7bcdb55db140" className="x6d4e148f">임원 면접</div>
</div>
            <div data-layer="c3340185-27c6-4da0-af20-1a86874b9d34" className="x10983">                <div data-layer="f6662186-7778-44fc-b7c4-d73b364271a0" className="x43834b92"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="2f1e0ae2-fda9-4e8e-9784-e94bfb903ba0" className="x11e4bf59">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="62b39924-1005-4ab4-afd5-4e3a326949d1" className="x301cf736">지원 방법</div>
                <div data-layer="8e90235c-e6dd-4e21-9862-4403861fdf72" className="lines6ad8ceef">                    <svg data-layer="64a300ff-be2d-4489-88fc-4eb45b59a9ab" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148072e6d7b"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b45b430f-d59f-4338-912b-b96725a3be8e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1427701a9d9"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="45d07f4a-2782-409c-934b-520b38859b86" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149df243669"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="47f97df3-cbb2-4e91-862b-083c98bcb1db" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143eadea009"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="344d490d-3a97-42f7-88ff-4ef9e52dd818" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144995ded2c"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="8598c264-0aad-4cfe-b409-b8f01927b839" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="f08874e0-e185-4fbd-948f-79881f67348c" className="x10987">                <div data-layer="59be135d-7d87-4c31-806d-159eb181327e" className="x4">관련 경력 2년 이상인 분<br />다양한 컨셉작업 가능한 분<br />재패니메이션풍의 매력표현에 능숙한 분<br />화사한 색감과 조형감 좋은 분<br />원활한 커뮤니케이션 가능한 분</div>
                <div data-layer="7d88d136-d934-454b-a1f6-41a874c4145e" className="x2c7b8553">자격 요건</div>
                <div data-layer="388dc0aa-39d4-4fb9-9976-3ad81eada76b" className="lines685f553c">                    <svg data-layer="e543687a-b392-4146-8bfa-62c117b673d5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1482bbd1128"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="53eaafb0-810a-494a-836b-1f83e6a91325" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1427ff7bfd6"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="09876a21-a593-4ad7-9419-c61db5b28e33" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1490117b9a3"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="c6d5178c-c831-44c4-93d1-e5694d6c4f91" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143710e1180"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="7a676880-a376-4c8a-9b48-338a31433787" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144d941d61f"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="60d31478-6795-4f66-98e0-e9084e47a5ee" className="x10985">                <div data-layer="d6adc53b-0855-46c6-abe4-b5ebd9fdb6c2" className="x3d">캐릭터를 3D로 구현하는 과정을 이해하고 원활한 소통이 가능한 분<br />평소 애니메이션을 즐겨보시는 분<br />관련학과 전공자</div>
                <div data-layer="f1de4fc6-5f95-4121-8438-157a3647c950" className="xdda162be">우대 사항</div>
                <div data-layer="40e118d1-980a-4a41-8db6-30ba8792b929" className="linesa7bb51de">                    <svg data-layer="cd27a1b1-228d-4be5-941d-a82a440389f8" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1482214725a"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e5cc35bd-95ed-40d9-87a9-cf52bfe06194" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142efb74234"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="3377b8fc-0cca-4023-8c60-ee4001276359" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149aa1427d0"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="2b1f7cb3-5435-4518-a259-bb05bb193209" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143e04f1e93"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="4d5dea8a-9d17-4074-ac09-3afbb84908ea" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1446e980ead"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="20ce6c99-47b5-46a2-aeae-966fbaeb3492" className="x11012">                <div data-layer="9ae94081-cd66-48b5-9d45-9336cf34f750" className="xRpg">글로벌 원빌드 수집형 RPG ‘몬스터 슈퍼 리그’의 캐릭터 및 몬스터의 컨셉 원화</div>
                <div data-layer="31421974-a4a3-4e09-ad80-f96c5ecb4e19" className="x">주요 업무</div>
                <div data-layer="08a72a4b-c9f7-40d7-84ce-d93865c68ffd" className="lines">                    <svg data-layer="892f35da-0703-4833-8062-ea68bc26c722" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="355cc456-edab-4338-8f77-98b559303dec" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="9191a027-68b7-4ed4-805b-a88e9152be33" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="99fc3da1-ee7d-4b3a-a06a-532e3f41a9b4" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="20623767-334c-49f5-a3f3-cb023d55b58f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdcharacterartistmsl.propTypes = {

}

Mjdcharacterartistmsl.defaultProps = {

}


export default Mjdcharacterartistmsl;
          