import React, { useState, useCallback } from 'react';

const McultureImpl = (props) => {

    const [isExpanded, setExpand] = useState(false);

    const onChangeExpand = useCallback((isExpand) => {
        setExpand(isExpand);
        // console.log(isExpand);
    })

    const recursivelyMod = (children, bSet=false, strTo="") => {
        return React.Children.map(children, (childNode) => {
            
            if (typeof childNode === "string")
            {
                return (bSet) ? strTo : childNode;
            }
            if (typeof childNode.props.children === "string" && childNode.props.className === "moretext")
            {
                return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children, !isExpanded, "더보기 +"));
            }

            if(!isExpanded && childNode.props.className === "mculture")
            {
                const styled = {
                    height: '5870px'
                }
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children)); 
            }
            else if(!isExpanded && childNode.props.className === "expandablecontents")
            {
                const styled = {
                    height: '1121px'
                }
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            else if(!isExpanded && childNode.props.className === "expandableinner")
            {
                return ("");
            }
            else if(childNode.props.className === "expandableend")
            {
                const styled = (!isExpanded) ? { top: `1023px` } : { };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            else if(childNode.props.className === "buttonmore")
            {
                return React.cloneElement(childNode, {style:{ cursor: 'pointer'}, onClick: () => onChangeExpand(!isExpanded)}, recursivelyMod(childNode.props.children));
            }
            else if(!isExpanded && childNode.props.className === "navitogames")
            {
                const styled = (!isExpanded) ? { top: `5176px` } : { };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children)); 
            }
            else if(childNode.props.className === "contentbody")
            {
                const styled = (!isExpanded) ? { height: `5072px` } : {  };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
            }
            else if(childNode.props.className === "footer")
            {
                const styled = (!isExpanded) ? { top: `5734px` } : { };
                return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children)); 
            }

            return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
        })
    }

    return (
        <div>
            {recursivelyMod(props.children)}
        </div>
    );
}

export default McultureImpl;