
import React from "react";
import PropTypes from "prop-types";

import "./mjdperfmarketer.css";

class Mjdperfmarketer extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="a278a105-f22c-4376-8178-2f0d2700b20f" className="mjdperfmarketer">        <div data-layer="57cd76a3-337a-4439-8615-954de9e217f6" className="layer1">            <div data-layer="2a30ac74-1749-45d7-9f06-976095b25ab4" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PvP 게임 프로젝트를 진행하고 있습니다. 스마트스터디게임즈 마케팅팀은 몬스터 슈퍼리그의 국내/글루벌 광고 소재를 기획 및 발굴하고, 캠페인을 운영합니다. 또한, 새로운 소재 발굴, 운영 결과를 통해 얻은 인사이트로 최적화를 하는 싸이클을 지속적으로 진행합니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="b9c2cb20-d999-459f-b1ac-52f71a618ce2" className="x10981">                <div data-layer="892cbaaa-397e-4969-bba4-af5d1f6ea104" className="x52bd102a">서류 전형</div>
                <div data-layer="01a84479-7419-4901-b72d-9f883d7320b1" className="x0283a8e2">실무 면접</div>
                <div data-layer="48878286-ed28-4464-84ed-6ec4cc4c1a5b" className="x53926556">최종 합격</div>
                <div data-layer="414576c5-2ad3-4dcb-aa8b-51e2bf730417" className="x10980">                    <div data-layer="5a1c4e2f-60e5-40ea-a8e5-8267d45d95e4" className="x10979">                        <svg data-layer="068482ab-feb7-4292-930f-af00b7e3445a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="c202b135-f6f6-4eb6-bbd4-668d1b35932f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="844633c6-d4fa-4166-aaab-dbb91463e9ba" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="07683630-2d4e-4d6b-9afb-c3bf89b6ea5a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="1fc48437-e59f-4d32-9772-1b530083f48b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="d2f09247-1316-45de-9555-c236708b079a" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="02ac08c8-d946-43fe-8934-c77c02682866" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="86c8c79f-d54a-422c-8601-7bbf803f11bc" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="0eb83de3-5757-48ae-a56f-f4c78a0ff2c4" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x244"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="39e0b251-e5be-478f-82d0-615b7e0eb44f" className="x659ba8fc">전형 절차</div>
</div>
                <div data-layer="50bbe8d6-0d4f-44e8-86c8-239f8073510d" className="x68fba961">임원 면접</div>
                <div data-layer="1a7e90e4-70ca-4bdd-a4c6-0f5a756b2662" className="xPdf"><span className="xPdf-0">서류전형</span><span className="xPdf-4"> : 채용시까지<br />* 서류전형 합격자에 한해서 사전과제가 안내될 예정입니다.<br /></span><span className="xPdf-46">제출서류</span><span className="xPdf-50"> : 자유양식의 이력서 및 자기소개서 (PDF파일)<br /></span><span className="xPdf-79">                    마케팅 포트폴리오 제출 필수</span></div>
                <div data-layer="e3b30f1a-2fae-4b61-aeba-428a21e8e956" className="xfef9304c">사전 과제</div>
</div>
            <div data-layer="ee5ea659-f87d-4b92-b905-ee765cdbd90b" className="x11016">                <div data-layer="87a279ad-d237-4686-9d7f-5e282af80a9e" className="x911">‘별명’+님으로 부르는 수평적 호칭문화<br />연차 일수 계산이 필요 없는 무제한 휴가<br />유연한 출퇴근(9시와 11시 사이 원하는 때)<br />어디서든 원격 재택근무가 가능한 합리적인 자율 근무<br />쾌적한 하드웨어 제공<br />스스데이 : 한달에 한번, 구성원들이 모여 다양한 활동 진행<br />나만의 개성이 담긴 명함<br />무제한 간식 제공</div>
                <div data-layer="96b79551-36ce-45aa-ac17-d10a1fedf194" className="culture">Culture</div>
                <div data-layer="7ef223ef-5497-46f2-8ca4-eae0365a87d0" className="lines225648a0">                    <svg data-layer="4f573c55-a8f5-42a2-8561-8d2693d7e27a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1488926a6b5"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="fe02ab4e-55e7-4025-9ef6-538189ee5607" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142ca463530"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="9a26843f-cf4d-4a21-b4db-632cad13d775" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149771290e1"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="83cc2842-a28f-49cd-af8d-8addebb5f75e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1437b5db6c0"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="fdfdf8db-2c05-49a3-9856-5476843fdf99" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144ab7b8e57"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="d0ccfc9e-bbb3-46e5-bca5-e435d1813f0d" className="x10983">                <div data-layer="3fa7e80d-07a9-4440-bc44-da8cb759503a" className="xa650468b"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="fecc259e-6097-442b-8d44-82e6610fb6cb" className="x12a59617">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="489ca29a-b00b-4a5a-8c22-251104876fc7" className="xd12fe522">지원 방법</div>
                <div data-layer="33aa0b92-78b1-4ad6-aab0-ced3df6d797a" className="linesb98b87e2">                    <svg data-layer="1a085693-a1f1-4e9d-a107-42bbb0e3c531" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148a3e7c468"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="8854474a-15f5-448a-bfaf-f8515879e858" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14292558365"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="1ae9a390-9dcd-4af5-9e57-549785ba9a5e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x14989646234"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="80c9b6fb-33ff-4902-bdb0-918e08bc8689" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143234be18e"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="b2fb4094-03e6-4146-b8a7-b18347f96d30" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x14435da3ed4"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="f7320d77-5547-4223-a73d-1872d3750377" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="6add729f-2563-4467-8400-db3281a4239e" className="x10987">                <div data-layer="f9f19863-1a41-4682-83b2-d334db3a37b1" className="xbc6b3a2d">평소에 모바일 게임과 광고에 관심이 많은 분<br />퍼포먼스 마케터로서 성장을 원하는 분<br />숫자에 대한 감각이 있는 분<br />해외시장(영미시장)에 대한 이해가 있는 분</div>
                <div data-layer="49cbdeeb-907d-4376-95aa-79fa73867f77" className="xac7a511d">자격 요건</div>
                <div data-layer="0fce12ce-4480-47f7-8649-8e0e7b68260f" className="lines1025595e">                    <svg data-layer="80d12973-ec8d-47ad-82c3-154f75f2aadb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1480139d464"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="f5a53ea0-b758-4126-99af-a64093c720ca" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1429e92470e"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="f4d67561-4753-4f64-8e71-462b7f667225" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1498e100167"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="280b0923-d60a-4549-a3a2-34413372d6d0" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x14364a38c4d"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d78e3c8b-425c-4173-a38a-0558023062f7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144c528c379"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="4b289792-a2ef-49f4-9418-eb4178904893" className="x10985">                <div data-layer="382228b7-03b5-4d50-8bfb-2f6f0c922324" className="x2">컴퓨터활용능력 2급 이상을 소지한 분<br />셀프서빙 광고매체를 경험해본 분<br />퍼포먼스 마케팅 업무를 경험해본 분</div>
                <div data-layer="335ec730-47bc-4816-a136-b3cf2a7a97a1" className="x2980e24c">우대 사항</div>
                <div data-layer="fffbd286-a11b-4634-9b9e-b31d56f6e6e3" className="linesc392111a">                    <svg data-layer="c2bb05cc-a76d-44a3-96b0-92ace174b24b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14885182c30"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b7b4d34e-b9ba-44f7-8c2a-9bd9feebd7fc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142041762ef"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="1c8f4170-361f-4303-ac61-aaa4c84bdfe1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149f6500da0"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="6b74250e-1ce0-4810-825c-7daa8d6395c9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1433b787d59"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="1bc2628f-d004-4558-b357-e94c5794df31" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144a3232b99"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="e574409f-e821-438e-b4be-9c555b218877" className="x11012">                <div data-layer="b44a2008-f04e-47f7-b3eb-b815840cc32f" className="xAdjust">퍼포먼스 마케팅 소재 기획 및 매체 운영<br />어트리뷰션툴을 활용한 데이터 분석 및 최적화 (Adjust)</div>
                <div data-layer="df90ddbe-52a8-4fc4-ada5-a6e0979cfed9" className="x">주요 업무</div>
                <div data-layer="cb3598de-887b-4616-942b-0362bf8b40eb" className="lines">                    <svg data-layer="5cdc3b05-1f55-45a8-ba72-0442c16c0d37" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="548872fe-11e1-493d-b936-1c97d35f94e6" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="95fd159d-0b14-41d7-9d4b-eef9ee4adabf" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="be0652d0-aca1-497c-941a-1e94ba855c05" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="b0685fde-8d28-48d6-9748-cae78a0a40b5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdperfmarketer.propTypes = {

}

Mjdperfmarketer.defaultProps = {

}


export default Mjdperfmarketer;
          