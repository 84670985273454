
import React from "react";
import PropTypes from "prop-types";

import "./mjdbusinesspm.css";

class Mjdbusinesspm extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="108612ff-aa98-4233-a2a7-3bc1a88beb75" className="mjdbusinesspm">        <div data-layer="2497fef5-68c8-431b-b26d-c227a67a71f1" className="layer1">            <div data-layer="47f3f9cd-2b87-46da-bcb2-2f93f9f81b52" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="093c329e-404b-47c1-962e-9e2ba56c27d7" className="x10981">                <div data-layer="8d57e3c0-8202-4e02-be34-24aa80005b01" className="xf46c4df4">서류 전형</div>
                <div data-layer="aa42cd5b-7b21-4a62-a5c1-4664c30b87b0" className="x5ccb6ef8">실무 면접</div>
                <div data-layer="f1e31ec4-1907-4dc1-aaf8-58f2876ac5d9" className="x0fe51040">최종 합격</div>
                <div data-layer="fee513a7-62b3-4cb9-b4f5-998f470b6aa8" className="x10980">                    <div data-layer="fd770b72-d69e-4db3-a2c3-9e002297c2ee" className="x10979">                        <svg data-layer="39110491-257b-45c9-aa9d-883f72f523e8" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="8a9a15a4-b013-4591-a319-7d77ac753037" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="e72938cd-992f-4f41-822e-81c55da1ad3a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="7573f234-40df-4b90-94ba-77a7fdd6088f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="f832831d-db4f-411f-94b1-05be7464685d" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="3755bbbf-7a6a-4e2a-9cc6-32698174cce8" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="eee69b06-73f2-4cae-b322-f23c820b23aa" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="329f9ce8-419b-47fc-ae0c-55e48bee0a48" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="96a16ba4-f567-4b16-86f5-cfd2d3f322d0" className="xa860881c">전형 절차</div>
</div>
                <div data-layer="d8cd2b70-cae3-4038-903c-c41585d454e2" className="x233802ed">임원 면접</div>
</div>
            <div data-layer="4da49afe-cda1-43d2-bb1d-4ca2a0132854" className="x10983">                <div data-layer="6967f564-d47c-47b4-b5e2-d909c5098b10" className="xb8e9b6d6"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="b7ea0311-cda9-409c-ac9b-ca71c2fe0d1b" className="x1086b035">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="7144f300-5724-48b4-80b2-fc627735a1f3" className="xec175b31">지원 방법</div>
                <div data-layer="f24371cf-6a01-42aa-b123-56808021ae96" className="lines6b62f6b6">                    <svg data-layer="bed88c91-ac32-4af0-9157-f47d809e57da" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14808e152df"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="351e702a-9ee5-46b5-9db2-63b04097ba66" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142fad83e9a"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="7b55d1e4-0deb-4efd-8df1-83a5a986cb4e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149b76b3e83"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="e5a82bfb-14a1-48fc-9955-239342851dd2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143c5039cde"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="909b7509-9c8f-4db9-8d38-906016da5b24" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144b22106e1"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="c6111cf7-2195-4488-847d-7d10803f3dd6" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="e44ad44c-0668-4f10-ac32-00c2c35bee0d" className="x10987">                <div data-layer="a8c18570-f4b1-41ff-8b18-24f43cab9359" className="pmPm3">사업PM 또는 개발PM 경력 3년 이상<br />모바일 라이브 게임 서비스 경험<br />팀 단위 및 타 직군과의 협업에 대해 능숙하신 분<br />프로젝트 관리 툴 또는 소프트웨어 사용 경험<br />커뮤니케이션, 리더쉽, 문제 해결 능력 및 분석적 사고<br />경영진 혹은 유관 부서로부터의 협업 유도 능력<br />구체적인 업무 내용이 포함된 포트폴리오</div>
                <div data-layer="8c0bf748-87be-49de-89ed-827b52ccbbb3" className="x4a459a1c">자격 요건</div>
                <div data-layer="ec55a361-f449-45ae-a4b0-32d145efe60d" className="lines328e8708">                    <svg data-layer="62f3e416-9cfb-4560-b8d7-b32cb8b29105" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148c3db1900"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b796889b-8873-4ff2-9376-6227179124b0" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14271519aeb"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="0e1e0eb9-d394-46b0-b384-7259d3d8dae2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149c27ff3b4"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d0c48cee-0c8d-41a2-955c-6bfbe179eea7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1431375b3bb"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="ff572c9c-56b1-4238-bbca-f9f0957e8214" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1440cbfcdbc"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="1b1a1e45-c8ef-475b-ac1b-e66e1d06b825" className="x10985">                <div data-layer="49dcdb58-292a-45fe-a7bb-424ae39cd3cf" className="xGooglePlayStoreAppleAppStoreJira">글로벌 모바일 게임 런칭 경험<br />Google Play Store / Apple App Store 마켓 관리 경험<br />애자일 환경 업무 수행 경험<br />JIRA 사용 경험자<br />모바일 게임 광고수익화 및 디지털 마케팅에 대한 기본적인 이해<br />하나 이상의 외국거 커뮤니케이션 능력<br />몬스터슈퍼리그에 대한 높은 이해도</div>
                <div data-layer="1abf7729-cd9e-4952-96d5-5f89937f28e3" className="x77c615e4">우대 사항</div>
                <div data-layer="2912be2e-e639-49a6-bd37-623d09182ca5" className="lines14f2d595">                    <svg data-layer="2a136fd6-dfc4-4296-b856-1e7ec974098f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14830c54536"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="999f3846-4c5c-4f96-967b-d3056db9f54b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142bf32ac40"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="f572ba57-5169-47c0-84ce-1d3aa18c537e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149f6dc00ba"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="ad9f6d98-9d9a-4b0f-8744-b0915cd12904" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x14309c87424"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="24044f1b-1709-498d-9cd0-c8a8f6666720" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1448f54bc47"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="6ae2a7cc-62d3-4c1b-a5e1-d68b80f972b2" className="x11012">                <div data-layer="39b90277-bd0e-4963-a945-6ca030542656" className="xPm">라이브 모바일 게임 사업 PM</div>
                <div data-layer="05201edd-1919-4ee8-bf31-60d9e50365fe" className="x">주요 업무</div>
                <div data-layer="d401b7b9-8bb2-4466-8232-9337ea51e9c8" className="lines">                    <svg data-layer="741d01b4-45de-43d7-850e-f81cddcd4a01" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="5e1784b6-f5d7-454a-9f40-d9ac73b76b09" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="a683e913-d66c-4927-9a89-c74e585a0623" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="ac9e70a4-f22a-4668-840e-770e55abe8b2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="ca39c38a-40b7-428e-856e-9d1d0a5b7798" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdbusinesspm.propTypes = {

}

Mjdbusinesspm.defaultProps = {

}


export default Mjdbusinesspm;
          