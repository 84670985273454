import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const PageTracking = () => {

  const loc = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    
    if (!window.location.href.includes("localhost")) {
      if(window.location.href.includes("www-qa"))
      {
        ReactGA.initialize("G-21SRPSC8X7");
      }
      else
      { // prod
        ReactGA.initialize("G-Z3BESNY5C5");
      }
    }
    setInitialized(true);

  }, []);

  useEffect(() => {
    
    if(initialized) {
      ReactGA.send({hitType: "pageview", page: loc.pathname});
    }

  }, [initialized, loc]);

  // localhost 개발용
  //
  //
  
  return ("");

};

export default PageTracking;