
import React from "react";
import PropTypes from "prop-types";

import "./mjdanimator.css";

class Mjdanimator extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="afff907e-90f3-48aa-affe-047934d4612f" className="mjdanimator">        <div data-layer="0a7b6eb1-2861-4239-81db-03f6dc2ddd15" className="layer1">            <div data-layer="a7149ce6-c805-40cf-a53a-db66955cb96e" className="thinkSmartStudyGamesIp2024Rpg">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="2b139dcf-4aa9-4b2d-b1f7-1a6226be274d" className="x10981">                <div data-layer="1c422591-3936-466b-a029-0737b7e7baf9" className="x50b6d958">서류 전형</div>
                <div data-layer="7602741c-4c6f-4993-84f5-aa368d42dfd1" className="xcedfdf55">실무 면접</div>
                <div data-layer="582a202a-1e59-4fbd-a9fb-2e6109bbd12b" className="xdc40e89e">최종 합격</div>
                <div data-layer="9c641b12-10f7-4e66-96c3-4738c338f3c3" className="x10980">                    <div data-layer="3749703d-f51a-46c1-91eb-bfa525929956" className="x10979">                        <svg data-layer="ecf8e606-de60-48e0-af96-74c4697888fd" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 501.189453125 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="60eee334-b7d6-4a4d-ab51-6dd1604c110d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 126.189453125 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="b36fe075-b5cf-4493-a6c0-370e0421238d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 213.189453125 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="c707dd35-7997-42ca-bd49-67f5cdeb233e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 53.189453125 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="d57a5779-6be8-4614-8ead-4e06650799dc" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="ecb0da84-ac48-4c00-8b1c-71c46918c8c3" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="37e0b98c-eca6-4f56-8470-162e3b9b2cbe" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="4cf9578a-f181-4416-9934-6ccebf31626c" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="bd5b4f86-7eb4-42d7-887e-ac9175a62879" className="x18bfe999">전형 절차</div>
</div>
                <div data-layer="37a8b970-5116-41a8-9e75-57a4551e4d7d" className="xc27c5482">임원 면접</div>
</div>
            <div data-layer="7fcaf4ef-30e9-43e6-81e7-3f2b8fd1f5ad" className="x10983">                <div data-layer="3ac48ef0-0169-455f-b129-bd7b507ce88a" className="x8e38993a"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="bf9bc484-1fba-4beb-993a-130a7cc0e043" className="xe3c29b23">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="b6328727-2415-43dc-bc70-733b8f3cbb9d" className="x8cec75ba">지원 방법</div>
                <div data-layer="1523978a-49a0-4145-bd85-28526ed1849a" className="linesa7b8ca44">                    <svg data-layer="94b06b53-d87a-4aa9-9912-cb61bf60d7d1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x1480705271b"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e31bd0c6-2e30-4b3b-93b0-d2ed2a26e54e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x1422de3d6f0"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="332b51a5-3453-4b6b-b197-61c09be39826" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149c6fc3dcd"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="a8bcda8c-af78-4a64-937f-9ed556623264" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143e48508d5"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="e5fdf830-8a31-41e5-92db-1b67ddec1be4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144de9e3f47"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="87b2b177-6b33-4759-9498-d6dd8797fe40" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="7808e1b1-9146-4806-bd94-9d725a9c0a7d" className="x10987">                <div data-layer="caee9e79-a764-4f78-b0fb-6dc44d0acb8f" className="x3Unity">관련 업무 경력 3년 이상<br />다양한 컨셉을 제시하고 새로운 시도를 추구하시는 분 <br />Unity 개발 경험 있으신 분<br />자율과 책임기반의 자기주도적인 분 <br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="fcab713f-5865-49e0-994a-e3160c12d3ff" className="x0dc5a25d">자격 요건</div>
                <div data-layer="6b1ade85-b27d-40b3-8b36-f155e9f8f930" className="lines199045ce">                    <svg data-layer="c70f9b1d-65a5-4833-a763-e4269a3f8037" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148c2e6b3c3"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="168b76ee-c353-4f97-96d1-f333ce972754" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x14288282cd1"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="77b914bd-2dc3-484c-bc10-c1974520ccdd" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149c038ad6c"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="e34fefae-11e6-455b-8bec-2c42a29e3532" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143c106d5c0"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="1b84ab07-8b88-40a6-8339-9fabbd999719" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144570dc0c3"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="8bfc59fc-b788-4798-8dd8-d4b56abf4d13" className="x10985">                <div data-layer="bfcd83db-d739-4acc-ace7-983727f0d1bb" className="x443bb281">게임 개발 애니메이션 리드 경험<br />카툰스타일 캐릭터 작업 경험<br />프로젝트 완수 경험</div>
                <div data-layer="021c426b-1d8e-45c0-8890-8da1926b7995" className="xc684c5e5">우대 사항</div>
                <div data-layer="d7b8f1ed-7d3d-42e3-a4c1-d4771511360d" className="lines9909c321">                    <svg data-layer="2afd0bf5-8306-4495-b6e2-002f05a1e00a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148b60f9836"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="dc3ddf25-f89f-4c50-b3b1-330df84019a1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x14236cfb5ee"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="2452e710-ffbd-4391-b593-2cf36f38cb26" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1497d856cd1"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d8d1f935-eb18-4084-8a30-341a6b71ae45" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143ac1cf59b"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="f2bfe56a-6c5d-442f-8ea6-605af1e7ac8d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x1442838c24e"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="e8dbd9ec-4e0c-4e66-b040-ef31c26dba71" className="x11012">                <div data-layer="39e913fb-e578-4d38-a553-20f4a35698a3" className="xL">신규 글로벌 프로젝트 L의 캐릭터 리깅, 애니메이션 및 연출</div>
                <div data-layer="5d6a8a9a-a65b-4f94-9be5-7502ddc1fd6c" className="x">주요 업무</div>
                <div data-layer="05f39ba2-df5b-465f-bf4a-93ecb9711a6d" className="lines">                    <svg data-layer="83314a28-89e8-4450-8829-57e1eb15298f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="fbaf8d3e-ac98-4b2d-b84f-a6a4ef2ad460" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="433afff1-f7cf-4a1c-99e3-001b93564c80" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d811e608-00ae-47d0-87f8-368a282e2ac6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="2f625b27-5891-487d-a039-85cf8980e591" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdanimator.propTypes = {

}

Mjdanimator.defaultProps = {

}


export default Mjdanimator;
          