
import React from "react";
import PropTypes from "prop-types";

import "./mjdgameDesignerConcept.css";

class MjdgameDesignerConcept extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="54725522-eb71-4745-baed-be83029d8203" className="mjdgameDesignerConcept">        <div data-layer="70020b55-1681-4275-95f0-579d3161ac6b" className="layer1">            <div data-layer="f5f969fc-2a7b-419d-94c8-294fd5aef821" className="thinkSmartStudyGamesIpRpg">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />현재, 스마트스터디게임즈는 신작 RPG 게임과 라이트코어 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="92f8eeca-e86c-49ab-9021-5d92ba43c0f0" className="x10981">                <div data-layer="889c1f66-1d53-4597-a980-54b2d2be2af9" className="xd4dcdba8">서류 전형</div>
                <div data-layer="52679aea-8ef4-419a-b3d9-cc6aa9bd9c6d" className="x19a9b65a">실무 면접</div>
                <div data-layer="60726c41-a176-407c-a9e1-9bc17248b2d6" className="x09732864">최종 합격</div>
                <div data-layer="a5211d06-9c36-434b-9533-4d64030240db" className="x10980">                    <div data-layer="e26e60da-f3a8-4459-92e3-d3d4763f2b42" className="x10979">                        <svg data-layer="1077c748-4c72-43b6-89ad-b5fdde0d8a8d" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 501.19140625 3.19000244140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="c499d2f1-f1cc-4c79-bafd-fa871722c6bb" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 126.19140625 3.19000244140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="6b4ae6ee-4637-4485-9a14-c3f8289aa713" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 213.19140625 3.19000244140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="d082bd14-e4b4-4c6e-9163-a729d14b6eb1" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 53.19140625 3.19000244140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="3b905b9f-6561-4adc-9fcf-754e6bf20788" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="9e94dedc-2513-4ee8-9f99-eac739b7a452" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="e777ce49-1861-4ccb-8272-bc6b17a6b3fd" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="4fa22d72-ae67-46f1-9816-2d2adcca0078" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="ca39d57d-6099-4ee0-9adc-8414ce5bb4e5" className="x3f31487c">전형 절차</div>
</div>
                <div data-layer="223017f4-fb82-48ca-b8c4-2a9cbf326919" className="x7a107083">임원 면접</div>
</div>
            <div data-layer="e56fd2f5-83db-4d61-b75b-31276bf9b463" className="x10983">                <div data-layer="909a7340-a7c5-4405-b5f4-c7ddc020b230" className="x7cc41969"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="1884f923-1fa8-44b6-8619-eca2a8c3d8e3" className="x81b9207f">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="cb833625-e432-4558-99ef-2d5e51e63429" className="xe014c937">지원 방법</div>
                <div data-layer="cba752fd-03c3-44e3-92a2-3267b8a179b8" className="lines505c9181">                    <svg data-layer="083d309c-0f94-4f57-b181-b205f7970f0f" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x14866034254"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="7f8c6e87-a7b8-443b-aea9-426f81dc3fb5" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x1424ab61020"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="dc468b57-9ccb-4aa4-904f-07ddf533bc59" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x149501ce964"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="32a06876-7970-472d-98de-5740733c51c2" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x143757201ad"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="0051ed85-292e-4295-8fe0-d536242246d2" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 47.19140625 3.19000244140625" className="x144c4cc49af"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="a8150215-dfd1-4551-bb23-1754fd6d677c" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="30c8ce3c-02f2-43b0-bece-c119a40dd36a" className="x10987">                <div data-layer="2d8efd32-cc01-4f70-bbfa-989b86f4be44" className="x1Rpg">관련 업무 경력 1년 이상인 분<br />캐릭터 컨셉 기획 경험이 있는 분<br />대사 스크립트 작성 및 관련 테이블 관리 경험이 있는 분<br />모바일 수집형 RPG에 대한 깊은 이해를 가진 분<br />자율과 책임을 기반으로 자기주도적으로 일하실 수 있는 분<br />원활한 의사소통과 협업 능력을 중요하게 여기는 분</div>
                <div data-layer="81c4c422-894f-4444-a964-981d9f6629bf" className="xb27051a3">자격 요건</div>
                <div data-layer="f90ad647-ed29-4813-afc9-b5ce999c86d8" className="lines24d68cf9">                    <svg data-layer="f641dfaf-5014-4a37-a1cc-ed06effb1c28" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1487b90d209"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="18e65815-5717-4842-b79b-f34db5d4b70a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142564ec814"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="1730fefe-cfa7-41d3-8652-57dae280daac" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149c55e16f8"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="2263c2b5-02d1-430c-a878-aa63686540cd" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143fecbcffc"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="f0c1249a-5ca3-4412-a3bb-3743758e49ef" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1440310d48b"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="38e17b02-8fc3-4e34-8e35-4c6c944483f3" className="x10985">                <div data-layer="847491b1-cc82-497e-bbf5-2b7cee2bc2c9" className="xNpcRpgRpg">세계관에 맞춰 지역을 만들고 그 안에 살고 있는 NPC들을 기획해본 분<br />배경 제작을 위한 설정 기획 경험이 있는 분<br />최근 출시된 캐릭터 수집형 모바일 RPG 게임을 다양하게 플레이한 분<br />모바일 수집형 RPG 게임의 라이브 운영 경험이 있는 분<br />최초 개발 단계부터 런칭까지의 경험을 보유한 분</div>
                <div data-layer="cd7c579a-ef2d-49c8-8f33-76794f65578e" className="x9cf27d98">우대 사항</div>
                <div data-layer="82ae570f-9d6f-41ef-9df7-26fc28c320f3" className="linesb54d6d6d">                    <svg data-layer="fcc0505e-9565-4b67-9696-1c725d31d72c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.189971923828125" className="x148607de32f"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="1d93ac50-3dc0-41c0-a7bb-c93bc75107e7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.189971923828125" className="x1425b10e7d2"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="5e225ff5-7df7-4c89-9f9b-ed741089628a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.189971923828125" className="x149c9df8b9a"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="f0015eb0-2001-434d-87a1-c03d7e54b02c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.189971923828125" className="x14376634d05"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="5eae7a56-714a-4255-865b-092345aa7114" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.189971923828125" className="x144debc2c68"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="190ea9b8-18e6-48be-b379-fc968fc62821" className="x11012">                <div data-layer="2d079295-f219-4b13-b007-ba45c6b90980" className="x51b8fed2">캐릭터의 컨셉 기획 및 대사 작성<br />세계관의 깊이를 확장시키는 세부 설정 기획</div>
                <div data-layer="5274d7ab-6cbf-41a0-a30f-4dbe1010359f" className="x">주요 업무</div>
                <div data-layer="5c894250-07a8-4232-8c5a-31b938ae76c3" className="lines">                    <svg data-layer="af23a139-feae-4158-a199-1cc6f9532317" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="bee0e961-d6ed-4cd7-9c9b-83515fbdffab" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="14865aa8-50b4-4aaf-ba50-0c7a7b81bf0a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="6b020daa-742e-47b9-a933-b262906e8892" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="2c280a1b-dd81-4d62-a19e-3683fc4bd539" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

MjdgameDesignerConcept.propTypes = {

}

MjdgameDesignerConcept.defaultProps = {

}


export default MjdgameDesignerConcept;
          