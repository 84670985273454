
import React from "react";
import PropTypes from "prop-types";

import "./jdperfmarketer.css";

class Jdperfmarketer extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="bbdf5f52-a659-496b-b41f-6e0f8818ca2d" className="jdperfmarketer">        <div data-layer="b6b2f65d-8ad4-4f4a-8a8a-191e4a325f20" className="layer1">            <div data-layer="59f0fdaf-7317-408e-84d9-5d87db46d8b9" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PvP 게임 프로젝트를 진행하고 있습니다. 스마트스터디게임즈 마케팅팀은 몬스터 슈퍼리그의 국내/글루벌 광고 소재를 기획 및 발굴하고, 캠페인을 운영합니다. 또한, 새로운 소재 발굴, 운영 결과를 통해 얻은 인사이트로 최적화를 하는 싸이클을 지속적으로 진행합니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="43ec0f69-8af8-4bec-82d0-98e301604e3b" className="x10981">                <div data-layer="4ab7e2bd-1a72-4809-9835-e578e1187579" className="x53c9ed9e">서류 전형</div>
                <div data-layer="e55dcd8b-1673-40ae-a080-f86787794406" className="xd26a45f3">실무 면접</div>
                <div data-layer="b8161eda-627a-4203-9c5e-a39b5a1e4822" className="xeb106661">최종 합격</div>
                <div data-layer="ca6d7446-a85d-4f47-9092-773c9dc95689" className="x10980">                    <div data-layer="a20eda8d-a1a3-4e2a-9873-e1ed60d585d1" className="x10979">                        <svg data-layer="e20db4d0-a279-4c34-a78b-54dc2c5e9376" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="18d35370-3b95-412f-b75a-29de5dfca66a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="767ce1bb-5148-49ff-bc19-7ac43245b1d8" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="668ab633-4e83-48f3-b363-39c464b4099d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="90dff495-7477-4b85-ab86-e139d369ccab" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="b49ea254-a329-4e3a-a97b-1e9afa9218fd" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="29f7c1ea-af68-40a3-9a26-2c1ae5f14b28" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="ef8187c3-9e0a-418f-9572-e946ef9381bd" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="6699fa05-45dd-4a19-962d-bed55d77b91b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73348641395569" className="x243"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="2391a7c8-6604-4a0f-8f33-61b8034436bf" className="x1579d395">전형 절차</div>
</div>
                <div data-layer="21ec46d5-da5d-4816-8237-e7b64d79de45" className="x824b6e9d">임원 면접</div>
                <div data-layer="97f787b9-35ad-4894-b654-904e69fe635f" className="xPdf"><span className="xPdf-0">서류전형</span><span className="xPdf-4"> : 채용시까지<br />* 서류전형 합격자에 한해서 사전과제가 안내될 예정입니다.<br /></span><span className="xPdf-46">제출서류</span><span className="xPdf-50"> : 자유양식의 이력서 및 자기소개서 (PDF파일) / </span><span className="xPdf-81">마케팅 포트폴리오 제출 필수</span></div>
                <div data-layer="6df50a33-1c5c-4c3a-a923-7a391666a049" className="x863e027e">사전 과제</div>
</div>
            <div data-layer="6401cdce-662a-4c94-bdf6-8b991eb93356" className="x10983">                <div data-layer="118086cd-7b56-460e-b621-4bfce7277d8d" className="x5296ee28"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="bf343466-46f1-4f0f-a43c-aa8801c950f3" className="x56743645">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="99e39cd6-e04f-445b-8797-e238df5bfab9" className="xdfca5d42">지원 방법</div>
                <div data-layer="857dafe8-290d-46c0-bac9-b3ae569fbcbb" className="lines80b1fe92">                    <svg data-layer="41802c72-0cf5-4bf4-b782-af1507daea35" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148d928a9fe"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="47b53e5c-c31b-4920-9a10-437f4b2459a9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142a7a289da"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="acec73fe-57b3-430e-8e83-798f9eb991ed" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149389e654e"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="6d0589ec-e305-4cb4-9fb3-44d364ffa27d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143af4cf929"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="1c7f42f6-b2e3-45a1-90a6-51b3020c23a6" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1447a760268"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="421ef7bf-f6b7-49a4-b809-112f14e91d8c" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="6c72ebf5-7927-4e07-82f7-db5e83983f71" className="x10987">                <div data-layer="2e9e8cbb-3cbf-446b-ba06-bf6d92c6a667" className="x9d99d49e">평소에 모바일 게임과 광고에 관심이 많은 분<br />퍼포먼스 마케터로서 성장을 원하는 분<br />숫자에 대한 감각이 있는 분<br />해외시장(영미시장)에 대한 이해가 있는 분</div>
                <div data-layer="360e284e-2110-45a8-81a2-5b529e9ab3ae" className="xc794c963">자격 요건</div>
                <div data-layer="9c11e809-050f-4e91-9574-ee8baadbd8f7" className="lines09dc5f6e">                    <svg data-layer="4973b5c6-1f57-4882-b14f-c41026ea3abb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1483956fb8c"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="7bc147fe-f8b7-4bf6-9bc4-e597b23e7b47" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1421100a1e9"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="8736248a-e367-410b-926b-e6c3d131cc99" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149636575e0"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="fb4a95e4-5900-490c-bf17-25a60c2cd54c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14320a911e8"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="1ab50a4d-d08b-479c-b6da-e91494a74369" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x14406d42cee"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="3208e5f5-c5b5-4015-b2a4-0e9f39b1b0bc" className="x10985">                <div data-layer="a8629cb8-b6a0-47e2-89a8-e68d4d75fd7a" className="x2">컴퓨터활용능력 2급 이상을 소지한 분<br />셀프서빙 광고매체를 경험해본 분<br />퍼포먼스 마케팅 업무를 경험해본 분<br /></div>
                <div data-layer="c2726dc5-2899-4116-8c42-7fa7dac7da7c" className="xd76d7b6a">우대 사항</div>
                <div data-layer="62e279ae-f329-4752-841b-990c3dc6884a" className="lines4dc0cb87">                    <svg data-layer="bda3aded-ff32-45df-82be-02cd542c69b6" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1489cd6c81c"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="f9e06db8-7f4b-4fba-8c86-0c218bc6bded" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1425c63ecb3"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="c31b26d2-c6fd-46ca-9613-ffbdd72d6ba2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149f213fd78"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="421a500d-4740-4db9-8b37-3e82e315a77c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143cae454d4"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="e2ac705c-3379-46bf-880e-991ab3d65098" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144388c579b"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="22db181b-a251-4781-a70d-aeb4da582dce" className="x11012">                <div data-layer="2d1c44e8-27cc-48d1-902d-dbf89a44f2a0" className="xAdjust">퍼포먼스 마케팅 소재 기획 및 매체 운영<br />어트리뷰션툴을 활용한 데이터 분석 및 최적화 (Adjust)</div>
                <div data-layer="9c57c311-b287-4c11-a174-63508aa51fcf" className="lines757a1fd5">                    <svg data-layer="2b53d6f9-3c60-44d8-997f-48820d1537ba" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x14866f1f7b0"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="81b29ff0-1733-4ce4-9ab7-fd831667b29f" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 248.837890625 3.1904296875" className="x142dd223c0e"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="47193c17-b28e-4ed5-9012-1294b6a1401b" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x1499d588282"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="536d5fe9-54c9-452d-b886-bb1d3292dc2a" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 310.4921875 3.1904296875" className="x143c1ffd513"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="b277c2f6-0556-4f1d-94e9-f0f86c40aed8" preserveAspectRatio="none" viewBox="-1.595703125 -1.59521484375 70.80859375 3.1904296875" className="x1440aa6d1a4"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="0bed082f-500f-4cdb-8596-96ecded817b5" className="x">주요 업무</div>
</div>
            <div data-layer="0d9821ed-3740-4747-b8ef-0d03ad1f6a7b" className="x11015">                <div data-layer="8483880c-225f-498f-b9a3-1fa704b113b7" className="x911">‘별명’+님으로 부르는 수평적 호칭문화<br />연차 일수 계산이 필요 없는 무제한 휴가<br />유연한 출퇴근(9시와 11시 사이 원하는 때)<br />어디서든 원격 재택근무가 가능한 합리적인 자율 근무<br />쾌적한 하드웨어 제공<br />스스데이 : 한달에 한번, 구성원들이 모여 다양한 활동 진행<br />나만의 개성이 담긴 명함<br />무제한 간식 제공</div>
                <div data-layer="57a2085a-78ea-4ed8-862b-8d0308ce17b2" className="culture">Culture</div>
                <div data-layer="3aeba896-fb42-4e5e-93bf-90a84fb1c0e3" className="lines">                    <svg data-layer="b9d63545-625c-417e-bf57-dca9e40dbcc3" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="3895e1f6-1b6a-4e7a-8948-6f250b453c01" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="106bcba3-15c5-4d52-a15f-f23c76796684" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="ef9a1168-7bd8-46c8-9aea-c4b7dc55b1cc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="afb824df-21bb-4f4e-9d44-e1f85622a6eb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Jdperfmarketer.propTypes = {

}

Jdperfmarketer.defaultProps = {

}


export default Jdperfmarketer;
          