
import React from "react";
import PropTypes from "prop-types";

import "./jdbgArtist.css";

class JdbgArtist extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="2701afa7-ecaf-4412-9d85-03ff59b884a5" className="jdbgArtist">        <div data-layer="7422a646-9303-4079-9946-7b238156f6d3" className="layer1">            <div data-layer="7723fc4f-b2ed-483a-88de-e63004542197" className="thinkSmartStudyGamesIp2024RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="47933890-c810-411a-8ac4-3320d5bcc716" className="x10981">                <div data-layer="7c1c3e78-d7e7-48f2-a984-6f195f8357f9" className="xe5d25836">서류 전형</div>
                <div data-layer="0bcf6ec6-f424-45ff-b79b-552a3afaa5b2" className="x63d85833">실무 면접</div>
                <div data-layer="6fd53f98-7103-4332-8902-6b2e9fdb117e" className="xc428dd7a">최종 합격</div>
                <div data-layer="cca8022b-9fab-4ce8-9322-0a00f08ab78e" className="x10980">                    <div data-layer="a424c8cb-3a7a-4c54-9900-0c5d43607c82" className="x10979">                        <svg data-layer="2dc9c19c-5497-45a8-9c2c-5d7334762421" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 896.95703125 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="3dd75dca-9fd3-458f-89a5-adedc48b1c7b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="80231e80-97d6-4c28-a942-d494702cabfe" preserveAspectRatio="none" viewBox="-1.5966796875 -1.59716796875 356.603515625 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="c9780371-6364-4b52-bac6-81b85ba1cd1d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 80.427734375 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="b86da313-6739-4263-bb97-afd21102ee38" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="e5e06796-8712-40b5-b666-b81736d5298f" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="2b7ceea5-00b9-4d87-b9a3-b6ce40b9c3aa" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="9e607315-2e51-4663-a20b-efe5fbe87df0" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="a8f66e35-73e9-46fb-a8ad-7926a400e800" className="x7fdaef1c">전형 절차</div>
</div>
                <div data-layer="b06ce5ea-3742-4201-a67c-e5da60861cc9" className="xb12bd50d">임원 면접</div>
</div>
            <div data-layer="94564d02-d8a9-4784-a1b5-20b7f97fe358" className="x10983">                <div data-layer="9adeb53c-30b1-4d5b-8666-3ba286080903" className="x807cb272"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="d53a9ef1-3e17-40ac-a563-ed097ba4df3f" className="x3fcb3f62">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="218621ca-dc46-4394-bcc7-e391746542c9" className="x0609aa60">지원 방법</div>
                <div data-layer="3a2ead3f-c9b9-4c13-98ef-c806a3efb91c" className="linesdc166035">                    <svg data-layer="dca0ce9e-fd4f-44c1-a73b-6228dccc883a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14899f110d3"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="851a064c-3e5b-4f39-8695-7d48388e7bc2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142d388e9f0"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="0c1c5108-81fb-45bb-a675-4e7c24a801b6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149fa732c20"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="6b521e93-0428-4cc5-a399-0abb798d2cac" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x143e9241879"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="fadb0cb2-b0bb-45de-95f6-2df09d8094c3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144e7567a4b"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="c4ff41f3-f471-4a6b-b8cf-2af736a20148" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="1862d58e-e483-4947-9cb9-0a976f55e602" className="x10987">                <div data-layer="67c8c9a0-4766-4162-87e8-cd9df1dc2366" className="x42">관련 업무 경력 4년 이상인 분<br />지브리 풍의 밝고 화사한 그래픽 디자인에 능숙한 분<br />다양한 컨셉을 제시하고 새로운 시도를 추구하는 분<br />게임개발 경력 2년 이상이신 분<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="fb74162c-1c52-491e-b5d7-0c1d7b84ab6a" className="x2cde4c8a">자격 요건</div>
                <div data-layer="b0fa73e0-c1a0-4565-a1d2-265e4a6ad02a" className="lines94fd1379">                    <svg data-layer="97b8af1c-db54-443f-8316-f152dd1efd43" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148b670515d"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="fc9d0c50-0d19-4181-90e1-3f8cbded57ec" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1427312a607"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="216011a2-e9b4-4b3d-9bc8-f1d53378b608" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x149b23f60b7"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="8f24c65e-3669-44b6-ad27-97b8f2d49d16" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1434cc3e3bc"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="a14b63d9-e9a4-47c8-9ff1-3efa9be03e16" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144dba9b021"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="e8dfc09e-840f-446f-8f4c-8731bcec961a" className="x10985">                <div data-layer="479a5b5f-dfe4-43e4-8b0d-376ae4edf7d1" className="x2520b129">아트에 관한 기본기가 좋으신 분<br />프로젝트 완수경험</div>
                <div data-layer="c56bf575-0978-483d-9a52-a9c3eee208f5" className="x9e7b5596">우대 사항</div>
                <div data-layer="3b589dea-29d9-4e8b-b5dd-18788f290069" className="lines58edd7c5">                    <svg data-layer="0199913f-9e0d-45cc-8cc5-4b789a35aaff" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1487b4eaf9f"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="668745fe-fb9f-4216-8d5e-b2709816b810" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142cfb8e8f4"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="85518f4c-6e42-4c36-baea-808429d6846d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x1496e7d99c3"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="62ee6030-2406-40f7-a9b8-a98cfa1880c4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x143f41a9f5f"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="6709a2ac-8fd8-4bd9-99a2-337105f817ea" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1447b426c4b"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="38063b3f-ca89-4de5-a559-1e01a2f0198a" className="x11012">                <div data-layer="c5876bf4-692b-4c83-9dd0-25f141e1027e" className="xL">하늘섬 세계를 배경으로 하는 글로벌 프로젝트L의 배경 컨셉</div>
                <div data-layer="15764c76-9173-4c89-a7f9-249f7579d3ed" className="lines">                    <svg data-layer="c35b6cf3-8097-4ad4-beec-f79beea3c9f1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 248.8359375 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="51292cfb-f63c-493c-8204-98b8142dfd05" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 248.8359375 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="c93a0fbd-3a9a-4a79-9dc4-d71e7e66e549" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="804c25ea-fe52-4059-a0a9-b04e2f294825" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="e7d77f5a-0ab6-4de5-91e7-2836847a5583" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 70.8076171875 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="bb27329b-dd0d-4560-a4ac-e5ddaf80cf12" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

JdbgArtist.propTypes = {

}

JdbgArtist.defaultProps = {

}


export default JdbgArtist;
          