import React, { useState, useCallback } from "react";
import Mmenu from "./pages/mmenu/mmenu";
import "./pages/mmenu/mmenu.css";
import {Link} from "react-router-dom";

const MheaderImpl = (props) => {

    const [isStick, setStick] = useState(0);
    const [navi_class, setNaviClass] = useState("hide");

    const handleNavibar = useCallback((isSet) => {
        setStick(isSet);
        setNaviClass((isSet) ? "show" : "hide");
    })
    const onNavibar = useCallback(() => {
        handleNavibar(true);
    });

    const onCloseNavibar = useCallback(() => {
        handleNavibar(false);
    })

    const recursivelyMod = (children) => {
        return React.Children.map(children, (childNode) => {
            
            if (typeof childNode === "string")
            {
                return childNode; // cover case: <div>text<div></div></div>
            }
            if (typeof childNode.props.children === "string")
            {
                return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
            }

            if(childNode.props.className === "navibar")
            {
                const styled = {
                    cursor:"pointer"
                };
                return React.cloneElement(childNode, {style:styled, onClick: () => onNavibar()}, recursivelyMod(childNode.props.children));
            }
            else if(typeof childNode.props.className === "string" && childNode.props.className.indexOf("logosmartstudygames2500x640")>-1)
            {
                return(
                    <Link to="/">{React.cloneElement(childNode, [], recursivelyMod(childNode.props.children))}</Link>
                )
            }            

            return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
        })
    }

    return (
        <div className="mheader_impl">
            <Mmenu onCloseNavibar={onCloseNavibar} isStick={isStick} />
            {recursivelyMod(props.children)}
        </div>
    );

};

export default MheaderImpl;