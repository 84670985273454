
import React from "react";
import PropTypes from "prop-types";

import "./mjdprogrammer.css";

class Mjdprogrammer extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="78c6c8a1-0dd6-4b2b-b459-148911e4be70" className="mjdprogrammer">        <div data-layer="e5f9bfef-cf03-4595-a38e-49840ad770f9" className="layer1">            <div data-layer="e43b2ac5-89d1-476e-a10b-c1288bc9bfcf" className="thinkSmartStudyGamesIp2024Rpg">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="f026f40f-99c1-4adc-9708-7be9cfcc2d23" className="x10981">                <div data-layer="8fa9be9e-806e-453c-9ccb-7c8e2cc385c6" className="x9ada7683">서류 전형</div>
                <div data-layer="d1932bd4-f12e-4128-beb7-d96b77b00e86" className="x3001e279">실무 면접</div>
                <div data-layer="ef017a89-7ad2-4584-99aa-5fa1510fdb36" className="x3b57913c">최종 합격</div>
                <div data-layer="befb17dd-0ee5-40bd-b79a-299c45f3c36f" className="x10980">                    <div data-layer="52417942-df41-40bd-850e-7a73ab9006e5" className="x10979">                        <svg data-layer="26324831-5c24-4769-b32b-103972cc02c7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 501.189453125 3.19000244140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="cec8890f-60b4-4299-87e9-5aa4f9886b4c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 126.189453125 3.19000244140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="3f5004b6-26f0-4ffd-acdc-cd9eed5b36fd" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 213.189453125 3.19000244140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="9ca0eb03-826f-4e6f-ae23-d5e1e81485ee" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 53.189453125 3.19000244140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="2e6d9f4e-4cfc-40ba-af97-219b41bf69f9" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="5c024ff1-401e-4f15-9a07-95eaf19dc562" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="e2494dad-7319-4adb-94a0-adec27f4b7a1" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="c1c9a334-59da-459d-b074-c390ac8ae8d5" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="d30badde-db8e-44d5-b984-2d0f5d0ef09f" className="x62a5cca4">전형 절차</div>
</div>
                <div data-layer="8329f72a-2b2e-4281-99d9-b8967e1bc979" className="x981ebbc6">임원 면접</div>
</div>
            <div data-layer="dfec4958-97bb-47fd-842c-629aa1a56ea7" className="x10983">                <div data-layer="5e55f5a9-4f45-4a13-b0be-cc706ed7f12a" className="xbcd36864"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="5267cefb-6e2f-40c9-a1eb-a5520f7b2c0e" className="x1c4dae48">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="c78c7b18-8b09-497d-8c65-dd0dbad3df74" className="xe25a6d39">지원 방법</div>
                <div data-layer="d67feed9-f7b4-4032-ad00-61b9053654f9" className="linesfd92252e">                    <svg data-layer="5b7620bf-b15b-4593-9c00-39031b20f740" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x1488cf290e0"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="ca404cb1-a3d3-4356-b7a7-3fc3ef73bad3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x14207d4de51"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="1b2f9da2-8b91-4992-b312-8c295ea75fdf" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149089052b6"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="fe05707c-cb31-429c-b508-8906faf7fdc0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x14380bd625a"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d6de15dd-db8e-4f71-b859-4f5fbd30a750" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x1442a43564d"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="eb4eae2b-06de-4de5-b993-6a039142844d" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="26a1f9ec-02cd-494e-b54b-d01512898b9b" className="x10987">                <div data-layer="8c497f18-1e52-49d8-aa64-920c42af9205" className="c">C#을 능숙하게 사용 가능하신 분<br />개발에 대한 열정이 있고, 문제 해결에 재미를 느끼시는 분<br />새로운 언어, 환경에 대한 두려움이 없는 분<br />개발 문서 이해가 가능할 정도의 영어 능력<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="90492ac5-b2a8-47ca-9095-e734dc33a5a1" className="x82e291de">자격 요건</div>
                <div data-layer="7f19bd62-4b7e-4e2b-b0f1-255952b98560" className="lines3e7b98c5">                    <svg data-layer="97a8c80d-9740-438f-852c-322cfe496f9b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x148be929952"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="aafd52b0-41b3-41dc-9146-4f19c4961931" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x142648bd95c"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="2d1795d7-f85d-4638-a012-ae81916d9742" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149c0d5542c"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="e2d9ba57-33a9-4a6f-bc4d-9b3c2cdb7d90" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x143d9c44406"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="1bb0df57-a9d0-4dfc-b1ef-2260c66e325a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x1448099b7ef"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="7d795ac6-e02b-457a-b9fc-458de099552f" className="x10985">                <div data-layer="fc217203-518b-43df-963c-2a010618488d" className="xUnityAndroidiosDbDbAws">최초 개발 단계부터 런칭까지 경험자<br />Unity또는 기타엔진으로 서비스 오픈 및 유지보수 경험<br />모바일(Android/iOS) 플랫폼에 대한 이해와 개발 경험<br />서버 개발 경험 및 DB 최적화등 DB를 능숙하게 다룰 수 있는 분<br />AWS 사용 경험<br /></div>
                <div data-layer="0f67f53c-f494-46b1-b179-f4e4fb0ff5ca" className="x76896ee7">우대 사항</div>
                <div data-layer="af29bafe-d705-45ad-92e9-9ee5f877f38b" className="lines0d26dd2a">                    <svg data-layer="7960a3c4-401f-4224-bf1f-b101a5bfd6d6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x1483889f744"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="92149c65-dcc9-47c3-beec-e1101d6aa519" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x1421b288dab"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b36bd6b1-78be-4ca4-ae92-a6718df97d74" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149f6240439"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="78e7a414-0ea3-460b-8cbf-bf1d1f12ead1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x143da09ab4c"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="71d53874-ebae-456c-9c4b-a2301a97511b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x14457a188ef"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="069af4c2-ffcf-4192-b643-7434d7481d54" className="x11012">                <div data-layer="3ef83da5-fa73-4bba-9d81-4c1c554bb624" className="xd7dc2d77">글로벌 타겟 모바일 프로젝트 라테시아의 개발 업무</div>
                <div data-layer="46695ca6-3a34-4585-98dc-a02bc7de9eff" className="x">주요 업무</div>
                <div data-layer="65b28a5e-8bc7-4be9-9d19-9a8b650763a4" className="lines">                    <svg data-layer="f0aa2c5a-ee1b-4f9b-87a4-319a04343841" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="06e19399-95e3-4afc-82f3-369c6ca993f6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="7532e4a9-b4e1-4dde-a641-78776e552ebb" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d031603f-4973-4d83-b146-3d9edfc29842" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="02784c4d-f095-40b9-86cb-6aa3b8275c7c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdprogrammer.propTypes = {

}

Mjdprogrammer.defaultProps = {

}


export default Mjdprogrammer;
          