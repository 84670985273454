import React, { useRef, useMemo, useState, useEffect } from "react";
import { throttle } from 'lodash'
import { useLocation } from "react-router-dom";

import "./stickyheader_impl.css";

const StickyHeaderImpl = ({children}) => {

    const [isSetSticky, setStickyHeader] = useState(false);
    const refsticky = useRef();

    const loc = useLocation();
    const [strCurrentPath, setCurrentPath] = useState('');

    // Event
    const throttledScroll = useMemo(
        () =>
          throttle(() => {

            if (!refsticky.current) return;

            if (isSetSticky && 10 > window.scrollY) {
                setStickyHeader(false);
            }
            else if (!isSetSticky && 50 < window.scrollY) {
                setStickyHeader(true);
            }

          }, 100),
        [isSetSticky]
    );
    useEffect(() => {
        window.addEventListener('scroll', throttledScroll);
        return () => {
          window.removeEventListener('scroll', throttledScroll);
        };
      }, [throttledScroll]);

    useEffect(() => {

        const targetname = loc.pathname.substring(1, loc.pathname.length);
        setCurrentPath(targetname);

    }, [loc]);

    const recursivelyMod = (children) => {
        return React.Children.map(children, (childNode) => {

            if (typeof childNode === "string")
            {    
                return childNode;
            }
            if (typeof childNode.props.children === "string")
            {
                if(childNode.props.className === 'text'+strCurrentPath)
                {
                    const styled = {
                        color: 'rgba(141, 55, 237, 1)',
                        fontWeight: '900'
                    }
                    return React.cloneElement(childNode, {style: styled}, recursivelyMod(childNode.props.children));
                }
                return childNode;
            }

            if(typeof childNode.props.className === "string" && childNode.props.className.indexOf("stickyheader")>-1 && childNode.props.className.indexOf("stickyheader")<=1) {
                // 맨 위에 헤더 div임

                const stickon = (isSetSticky) ? "stickyheader_on" : "";

                return React.cloneElement(childNode, {ref:refsticky, className:`${childNode.props.className} stickyheader_init ${stickon}`}, recursivelyMod(childNode.props.children));    
            }
            
            return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
        })
    }
    
    return (
        <div>
            {recursivelyMod(children)}
        </div>
    );
};

export default StickyHeaderImpl;