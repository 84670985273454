
import React from "react";
import PropTypes from "prop-types";

import "./mjduiDesigner.css";

class MjduiDesigner extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="7736923c-7dc3-4d64-a4c0-f2ad20ee51d1" className="mjduiDesigner">        <div data-layer="22c782f5-cbd6-440d-894d-4ecbc6740201" className="layer1">            <div data-layer="b21e3b00-e43f-4263-85ec-039fa007e964" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="43bf00e9-445c-4a54-83a0-0c3ee5080d05" className="x10981">                <div data-layer="3bbf7609-c3d2-401f-b5ce-a0d5ae22998b" className="x1367b292">서류 전형</div>
                <div data-layer="f903dd30-238a-49dd-92bb-d4e1541966f8" className="x0c58a2b4">실무 면접</div>
                <div data-layer="8956b796-5e41-4e74-8203-a7ff44b6ac05" className="xef90ca5b">최종 합격</div>
                <div data-layer="aa4145c2-67b3-468e-b2e0-adeb1bd7ef74" className="x10980">                    <div data-layer="bcf52a2f-10a6-47a6-8a1e-288bcb7f3099" className="x10979">                        <svg data-layer="2fd1d38a-f7e8-467e-a085-76d5b7045cff" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="9b2820f4-dd48-4895-a1a0-23a5155ffff2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="16c680fd-5c60-42b7-98b2-d1a47ddaa714" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="7c4f39ee-ad1f-45fc-9704-b030553f1dc7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="efb8720e-adf2-4739-a5ae-d28b29aa52ce" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="c49fb80e-9d37-461b-8a03-55a9c0610e52" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="214ace40-8fd0-4497-9354-06aa98533889" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="a8fbda56-f8c1-4867-b524-4b624524b4c1" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="75d02fff-d937-48f5-8ed0-45b3541eedb1" className="x2dc211b0">전형 절차</div>
</div>
                <div data-layer="a73c1263-3167-4a04-83be-a4c1fbabfa32" className="xc505fef8">임원 면접</div>
</div>
            <div data-layer="8b5f6b8f-d948-41f1-a245-69fc10cbcabb" className="x10983">                <div data-layer="4a81b321-9f3a-410c-b156-952b29349e60" className="xa19dd443"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="24159bf5-db2a-48cb-8ca9-b93381c977de" className="x9f7c4d9a">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="89417617-4593-4cd4-aa0d-91422ff79169" className="x08acce1d">지원 방법</div>
                <div data-layer="441a58c0-fff6-4f1f-99a7-110c62327b3c" className="linesc575ad4a">                    <svg data-layer="56d6ffbd-551f-4d0a-8495-54b3e80582a6" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1487a0be48f"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="9fd2bed4-08f4-4100-bcaa-be4fbbef22da" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1422247f992"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="00b7f1ce-18d8-4fbb-91cf-b3e0d5184be5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149c51e5a82"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="9690a2d9-21b8-4331-9fe8-f35e842f65d1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1433ac7589a"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="219b4a75-d910-4781-948a-45dfca52ab33" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1440261fa42"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="61fcfafe-cffb-42f0-8612-330d850a4eb9" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="519dc655-1e5c-45ab-b8b5-a57a0def3e3d" className="x10987">                <div data-layer="c9c920da-fbb0-417e-a20c-a79dd962af9b" className="x2Ui">2년 이상의 모바일 게임 디자인 경력<br />그래픽(포토샵,일러스트레이터)툴들을 능숙히 다룰 수 있으신 분<br />UI 설계 및 인터렉션 디자인에 대한 이해가 있으신 분<br />구체적인 업무 내용이 포함된 포트폴리오<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="39547614-65dd-4e83-9450-62b20fc163d2" className="x1b07082f">자격 요건</div>
                <div data-layer="35943214-f1dc-484a-92ed-6a73f7e17ea5" className="linesa94955e4">                    <svg data-layer="6f4da95d-3f85-4279-8e8d-26e08c8cb420" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148d6df0920"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="d5429d4e-ecb7-4f6b-b892-09090a22ad8a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142d1f88613"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="04153415-4605-4127-a54c-5dcd4fb09ddb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1492a674c13"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="dc09071f-da69-4e8f-ab9e-7296dd21f0e5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143fd5fc40c"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="55a2cb33-7f5b-4ffa-804a-1d0233973f83" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144cde386ca"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="10bccb99-6315-42ef-97c5-ce6f58199b37" className="x10985">                <div data-layer="faefe308-3e2d-4197-ab9b-88c9b36673fb" className="xRpgUiUnity">캐주얼 또는 반실사 풍 RPG의 UI 제작 경험<br />모바일 게임 프로젝트 런칭 및 라이브 경험<br />Unity 사용 경험</div>
                <div data-layer="e36f7d19-e7ec-4482-a035-750e53da69c4" className="x36f4125b">우대 사항</div>
                <div data-layer="642bd25c-f118-4f9e-9e10-51be51a2736e" className="lines8fbdb1fd">                    <svg data-layer="f23b1722-61c5-4ed0-ac22-617d5f6e57ce" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1486d510449"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="2eb78a07-5e4c-4808-a44a-7558709afc08" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1423b75d823"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="def315c6-c366-4cdc-9cc4-0a7551da098b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1493da7dc7a"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="60145abb-0b09-412d-b167-87cc4da67e3b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1439b7cc451"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="cea1faac-9107-4599-b29b-6ffed460eb99" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x14459d2cab5"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="4bae716f-3b2a-4ba6-a2ac-95d3b0fb4100" className="x11012">                <div data-layer="4d547791-56ed-4e56-a468-4f867b3043df" className="xLUi">글로벌 서비스를 목표로 하는 신규 모바일 프로젝트L의 UI 디자인</div>
                <div data-layer="43f0dcbf-0567-42d4-824c-484383f5f2b0" className="x">주요 업무</div>
                <div data-layer="3aca58fb-91d3-4ef4-8a65-2c53bb694536" className="lines">                    <svg data-layer="94632d5c-421c-4f01-9e11-59aaa6155c2d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="650db623-5011-4c77-b5ef-8fe3bfaa6faf" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="107386fc-fcdb-4785-827a-33c8d1b8e00b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="594d741a-b4e3-4d9b-890c-631adf641e01" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="746c6ffb-75fe-4555-a19c-58ed7f8629a0" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

MjduiDesigner.propTypes = {

}

MjduiDesigner.defaultProps = {

}


export default MjduiDesigner;
          