
import React from "react";
import PropTypes from "prop-types";

import "./mjdgameDesignerLevel.css";

class MjdgameDesignerLevel extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="06c87287-6f2f-42cb-b2aa-57ff6b7194f3" className="mjdgameDesignerLevel">        <div data-layer="91e201a0-4018-4243-905a-8a116f892017" className="layer1">            <div data-layer="a4ffab14-4a26-4af9-8b39-c9197764789e" className="thinkSmartStudyGamesIpRpg">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />현재, 스마트스터디게임즈는 신작 RPG 게임과 라이트코어 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="8a8c0982-6006-4a7f-a693-ca92a5a1aec8" className="x10981">                <div data-layer="9db71b98-3eec-466e-b51a-b53add7d2198" className="x23674923">서류 전형</div>
                <div data-layer="67c54eb6-c044-43ac-a591-e69f626263cd" className="x191d9517">실무 면접</div>
                <div data-layer="9544c50d-044a-4ee1-ad9b-2ce9e7085480" className="x994dc450">최종 합격</div>
                <div data-layer="a2509bec-5953-43cc-bf0f-17b8759e9992" className="x10980">                    <div data-layer="60ec2098-f27a-4f26-b390-b28a575a90d2" className="x10979">                        <svg data-layer="93b18e59-b6ed-44b5-9a71-8ed469bd5c7f" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 501.19140625 3.19000244140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="a578f89a-d88f-4f69-ab35-f288311e8f83" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 126.19140625 3.19000244140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="cc25e499-f3c9-43f5-bed9-b38d9117656c" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 213.19140625 3.19000244140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="b40bb422-6815-4622-a088-9d5a48ea882c" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 53.19140625 3.19000244140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="de1378b5-8c29-42f8-b2ec-967304652618" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="9fdac6a8-db60-4431-885e-12fa4263aa1b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="20c2a8b0-7218-4cf9-9926-1392f98cd5bb" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="d7cc1803-7ba4-4c63-9086-e5eb012db116" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="b93f2e05-afd7-401e-a2de-655febfbc664" className="xe4346318">전형 절차</div>
</div>
                <div data-layer="7507d874-56bb-494b-b4bf-231ed32dab00" className="x4d1ae936">임원 면접</div>
</div>
            <div data-layer="6595be15-dde7-4784-93ce-b9615a22cf0d" className="x10983">                <div data-layer="06d805f0-4117-4849-83c4-b3201e12ecee" className="x0678c70e"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="82f97dc9-f5db-4bbf-af55-053d805eef93" className="x3da02cdc">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="9c89c80c-8419-4b37-8a5c-4768e3c9bfd4" className="x7a4d595b">지원 방법</div>
                <div data-layer="d3d02caf-c1d0-4c14-a125-cb65025fae16" className="lines79dc266e">                    <svg data-layer="d67e0a3e-3cf4-4d94-9fe2-e30e7800335d" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x14875c42770"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="3b939b99-4c5d-4cd4-820b-7b3e0d442e0b" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x142f9cf6fdb"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e03dc2d5-1ef0-4ca5-a1e2-b1a215cfd666" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x1492c1ba85f"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="a8f4c782-5e6e-48f8-8111-299ba8d4d2f1" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x143aebcbca7"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="fc231b59-ca18-4d67-b1c8-9f71da0293cb" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 47.19140625 3.19000244140625" className="x14483ec909d"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="cce353dd-6e36-4643-a68c-7b8c2aae9282" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="d9720e00-5c04-4356-b98f-dff6da903d38" className="x10987">                <div data-layer="6f462fb9-641a-4ad3-80fa-761e2c689529" className="x3Rpg">관련 업무 경력 3년 이상인 분<br />맵에 다양한 콘텐츠를 효과적으로 분배하는 레벨 디자인 경험이 있는 분<br />맵 탐험에 필요한 재미요소를 직접 기획하고 개발을 진행해본 경험이 있는 분<br />모바일 수집형 RPG에 대한 깊은 이해를 가진 분<br />자율과 책임을 기반으로 자기주도적으로 일하실 수 있는 분<br />원활한 의사소통과 협업 능력을 중요하게 여기는 분<br />구체적인 업무 내용이 포함된 포트폴리오가 있는 분</div>
                <div data-layer="212a8cce-42ae-485e-83db-ff37be35b72e" className="xa37abbb8">자격 요건</div>
                <div data-layer="eb6d7a89-dc02-46d2-ba42-f06daba643c8" className="lines03736656">                    <svg data-layer="f44eaf72-614d-4cc7-91bb-d2d5fcd6fb83" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1480bb5fc9c"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="8f5fd5e4-adb5-4de4-b132-cde21a0f0b54" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14274b62f96"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="996e3e6f-2911-4095-be1d-5e694671e19b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149bfe245f4"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="3add174c-de11-4907-af0f-9dde4e4dd664" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143d36cab6a"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="4dd3225d-b5aa-4ed7-a3bc-7b99b6f22945" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1441d4807a9"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="21aa3de1-6465-4092-bd6c-1b8acee11157" className="x10985">                <div data-layer="454049da-c5a9-4fd4-9d88-40d6148122a2" className="xRpgRpgRpg">넓은 맵을 모험하며 플레이하는 RPG 게임을 다양하게 경험해본 분<br />최근 출시된 캐릭터 수집형 모바일 RPG 게임을 폭넓게 플레이한 분<br />모바일 수집형 RPG 게임의 라이브 운영 경험이 있는 분<br />최초 개발 단계부터 런칭까지의 경험을 보유한 분</div>
                <div data-layer="9351b5f8-ced9-42c9-b424-d2dfe365c7c6" className="x2d395a51">우대 사항</div>
                <div data-layer="95362ac9-a49e-4d74-a8c1-fac1f99f385d" className="lines7e0bffdf">                    <svg data-layer="62d8900f-cd5c-43cd-9ff8-a81e4f119a98" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x148310302b3"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="992dd0d3-a333-4771-bc4d-0e98748a541e" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x1421e4500ea"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="c28ebb38-859a-4a54-99b4-15def8e0c2f7" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x14973fbde00"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="6c4717c7-b7e9-4877-a1bf-e6328ec6e758" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x143739c2d5b"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="ecca97bf-e20f-4257-89aa-da51ea36f2df" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 47.19140625 3.19000244140625" className="x144f707efc2"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="c14267af-dc81-4b3c-a1b0-af10de6c8bda" className="x11012">                <div data-layer="6834f596-e344-46a9-8c8a-5a15497a0981" className="xRpg">캐릭터 수집형 RPG 게임에서 게임을 풍부하게 만들어 줄 탐험 공간 레벨 디자인<br />공간 탐험 경험을 다채롭게 만들어 줄 레벨 디자인에 필요한 콘텐츠 기획</div>
                <div data-layer="859c9129-b51e-4824-b963-4e8c86dd63b4" className="x">주요 업무</div>
                <div data-layer="dd84b1fd-bdb2-4467-9e2c-ac0532df8308" className="lines">                    <svg data-layer="53457015-b630-4de3-aee7-4aa5fcd971cc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="56c2212e-62ca-48b1-8f84-1b7f5bf8dc3d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="59444dc5-c2d3-4542-95c9-65ce7e6e6d92" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="c0857b76-5e85-4e8c-bae4-c9149406359e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="2b14482f-7181-4a7a-8c28-8cf8752cbf1b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

MjdgameDesignerLevel.propTypes = {

}

MjdgameDesignerLevel.defaultProps = {

}


export default MjdgameDesignerLevel;
          