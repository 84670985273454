
import React from "react";
import PropTypes from "prop-types";

import "./jdnewproj.css";

class Jdnewproj extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="5a69c486-39a5-4e8e-9b92-1ba1c5f982ab" className="jdnewproj">        <div data-layer="6c21c74e-f22e-421a-84f5-da6dbd7b8214" className="layer1">            <div data-layer="96a91079-7a21-494f-b96b-fcf15c6164d3" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="17bec999-570d-4b2c-97e4-b4a60fdb1859" className="x10981">                <div data-layer="2d8dd302-3fce-4cf5-8738-abaa3787b76d" className="x2a138614">서류 전형</div>
                <div data-layer="eee9a67b-c391-417f-8c39-7ca1c17f601a" className="x3dde061e">실무 면접</div>
                <div data-layer="60701272-62d5-4b64-ad76-f95e4f1c360b" className="x7d64faea">최종 합격</div>
                <div data-layer="7032a9a7-d8d5-41c7-b7dd-479d14009aa6" className="x10980">                    <div data-layer="8919f9d7-7dc9-43cb-a367-84116dc78bec" className="x10979">                        <svg data-layer="6de4d718-fb5c-41f9-bafb-f0de28ba0135" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 896.95703125 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="bb03e6f0-ada1-44c5-928f-8d50bbbea430" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="3c332850-0960-49e7-977e-86314df520fd" preserveAspectRatio="none" viewBox="-1.5966796875 -1.59716796875 356.603515625 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="e06cfe73-6ed6-4506-a59c-cba0377419ce" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 80.427734375 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="aeacb930-92c0-4e02-a635-4b5d6f81a681" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="19ead4fd-ea3e-4145-9c9d-8c504f8cd7d8" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="bca991f1-44da-4185-a10d-587621687fba" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="365ff23e-49ad-4975-bf6e-04609af8abec" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="4e145cc1-4402-4eed-b206-2dda1c92b755" className="x2c9c6d12">전형 절차</div>
</div>
                <div data-layer="10e3660f-9152-48aa-8e06-0c4a1efcdf3d" className="xbcf2dade">임원 면접</div>
</div>
            <div data-layer="cce1f751-a705-456f-818a-efea864ebd97" className="x10983">                <div data-layer="b99947b8-9839-4e70-ae3f-4a6f9300b0b6" className="x4c3fd4f0"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="ebccc401-c149-42dc-9533-88e6a2ec48e4" className="xe5b91e5b">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="d38b3b72-bf06-41ac-94a0-8d479081851d" className="x6e2527f8">지원 방법</div>
                <div data-layer="2da5d5d0-d622-4cea-a8c9-419ea3941ce7" className="lines901c3769">                    <svg data-layer="87a27eab-f8fd-49d5-9072-74a87fe0ad65" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14878bf60c2"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="cca7ef87-4de3-4b39-a176-b8c83835a379" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142be0939cc"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="0eb4c046-0113-4a84-8270-3a80ad8339db" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x1493bc43fed"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="4b866cf9-6426-4293-9987-0f3de412580b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x14352a1bcb6"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="8d29a581-7982-454c-b814-6a6eb46b7b9b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1445179234f"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="1e9a9898-84bb-406c-bdc5-a16f00b8fd85" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="4885cd2d-d587-42a0-85d1-0b31ac5b7207" className="x10987">                <div data-layer="111c5e0f-4dcc-46f0-9dc1-90a3f37619a5" className="x1CC">모바일 게임 개발 경력 1년 이상<br />C++, C# 언어 중 하나를 능숙하게 사용하는 분<br />개발에 대한 열정이 있고, 문제 해결에 재미를 느끼는 분<br />타 직군과의 협업에 대한 두려움이 없는 분<br />다양한 게임을 많이 하고, 의견을 내는데에 주저함이 없는 분</div>
                <div data-layer="62cad8d3-630d-4a62-a955-0c6a521a71bb" className="xd88d4c74">자격 요건</div>
                <div data-layer="ef4c3f6d-f0c4-45d4-85dc-b2a8aac5b329" className="lines4d40b66b">                    <svg data-layer="f80f0251-d7c0-4ad4-9d93-7416af0f8c9a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148450d5976"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="c2d6b951-d646-4aee-bbec-af378dcaca5c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142f72dd7b0"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="dc9ffc89-6bc8-4509-998f-941fae745c7d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x14959f6e3ba"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d1958762-eed5-485f-bf7d-b0bbb0273e50" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1435b36f428"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="640c0be5-0c66-4a26-b9b4-1dd0d6d21078" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1444f2b8986"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="eac894dc-2340-4566-93b4-d193c15ebacc" className="x10985">                <div data-layer="31a734de-7b7c-462b-980d-e4d9d506476b" className="androidios">모바일(Android/iOS) 플랫폼에 대한 이해와 개발 경험이 있는 분<br />새로운 언어, 환경에 대한 두려움이 없는 분<br />개발 문서를 읽을 수 있는 영어 독해 능력이 있는 분</div>
                <div data-layer="5182c4ce-b0ef-44b9-9d43-0757301f480b" className="x">우대 사항</div>
                <div data-layer="a7b2c71e-8e4b-4698-aa08-4287aefcbcc4" className="lines29552a26">                    <svg data-layer="64ced769-083d-4e9c-8eff-a5afe486d244" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14885f0f501"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="1ddd060b-6ab4-4926-ba87-32b2d46edfe6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14202256d4f"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="717e2020-7b12-4ee2-af5f-792f84614757" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x1493e6fbb06"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="478741bc-0f2c-4af9-ad59-20cb5988cd40" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x143b0bea52f"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d98e4644-37a3-45e2-b7fc-75c4b7c7d56d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144c9849d5b"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="e37e80cd-1c51-4336-a3cc-9c35eb504c42" className="x11012">                <div data-layer="11ab465e-c472-46af-85dd-2d63dc21ad97" className="androidiosUnity">모바일(Android/iOS) 게임 개발<br />Unity 기반 게임</div>
                <div data-layer="dd3d2141-e30a-4644-b208-215ec61f7371" className="lines">                    <svg data-layer="f95a9a58-7e0a-4085-bb2c-8ecb849dc139" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 248.8359375 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="af28e674-2e3c-415f-b622-baef5c2323ce" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 248.8359375 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="77ae1f81-bdf3-4ae5-a95a-246421656f45" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="c217a025-9427-4411-8850-e6d96fe9442c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="5311d818-bd23-43bf-9a47-afe73415baae" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 70.8076171875 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="9becfc04-9992-4874-a2bc-aaf348100eb7" className="x95c0c981">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdnewproj.propTypes = {

}

Jdnewproj.defaultProps = {

}


export default Jdnewproj;
          