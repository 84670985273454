
import React from "react";
import PropTypes from "prop-types";

import "./jdsupport.css";

class Jdsupport extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="80c53680-d2f0-41f8-99ae-176f70c6fcdc" className="jdsupport">        <div data-layer="1dd1cb3b-45b3-45e3-94b2-8124fa59c789" className="layer1">            <div data-layer="c3172ac2-ae6b-4a9b-a4a3-c7b85dc0d3dd" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="4aefeb20-44f9-42e5-a318-3975ca8a2de0" className="x10981">                <div data-layer="f3cf02cf-686f-4b2b-a24e-89ea9dd70587" className="x36a7d87f">서류 전형</div>
                <div data-layer="b2673b3f-c783-48cd-8f05-b6f9d251a9f6" className="x82f3f1e6">실무 면접</div>
                <div data-layer="2362490c-f73b-464f-8ece-e73aef7a72a4" className="x9fb485d4">최종 합격</div>
                <div data-layer="849afdf6-7047-438d-931e-71f844c2aac9" className="x10980">                    <div data-layer="effeb6b3-1beb-405e-996b-e721c5a85551" className="x10979">                        <svg data-layer="98b83a0b-9ade-4991-8de3-0c76af70caf5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 896.95703125 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="86b7d56a-f928-4ad8-84ea-36fd7b44f8c7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="887ac4ee-2f8c-4fc2-af74-ca75233dd19c" preserveAspectRatio="none" viewBox="-1.5966796875 -1.59716796875 356.603515625 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="e9943dbf-c439-4b4c-aecd-eda6930f3da4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 80.427734375 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="aa68ca1e-cdb2-4d2c-9433-20151e231e81" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="9232008d-516f-4b5c-b28b-e84d3e1a1002" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="0e01b4a7-ce4c-472f-85c7-b39d9d628114" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="34717a46-667a-4125-9ec2-331d615c7e08" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73348641395569" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="1f2b7cb8-302e-4f15-a9fe-594ea9146085" className="xa908f16c">전형 절차</div>
</div>
                <div data-layer="e7d8287a-fe13-4a46-8154-4bebb06364b9" className="x776faa96">임원 면접</div>
</div>
            <div data-layer="e7de5437-bfa4-4739-bfe7-e2954af2da7a" className="x10983">                <div data-layer="ed2b2efc-b490-4c3a-b879-7ada7b45252a" className="xc56e723c"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="8a8f26c6-3d2f-44d5-b822-3057af0c92da" className="xc3af3649">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="8e262f70-75f5-4875-9786-349e74c2745c" className="x0f8bf4bb">지원 방법</div>
                <div data-layer="cb65c6f8-2cda-4ebe-b42a-8a2d1155dedc" className="lines9e3ed3ec">                    <svg data-layer="d4ec7c79-eb93-41a0-86ed-a184bff049c4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14815a45c9e"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="6c2daeec-b43e-4152-a06d-3a4bac6255a6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142b16092c0"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="ba55f955-4f6b-4b71-a533-0612d5680418" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149489b9ecd"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="4d14c4cf-17cf-4cc9-8454-7cae5f2f3e66" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x143bcfa4ca6"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="4793a4cf-401f-41b0-b4a7-15d6ec20a517" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1442ad42858"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="c629b89b-90c9-401b-b88c-3c4e86b3a2d1" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="d70d3287-8fff-4622-8878-c4e1e0e925cb" className="x10987">                <div data-layer="908b3adc-0a99-420a-984a-6aa27a7f9eb3" className="x093b9630">컴퓨터 활용 능력<br />뛰어난 의사 소통 능력</div>
                <div data-layer="01f9a78d-5fd9-49b5-8758-5fa7ae3725c4" className="x6a5c2ef9">자격 요건</div>
                <div data-layer="fbe173b5-cb9a-4265-8ad9-4a7ac9ae6bba" className="linesb848a756">                    <svg data-layer="c07cbc48-3302-4adb-b4c6-1506732e054e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148ceca6ce4"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="e0b9a39b-77a2-4215-8cd9-cf18b10edcfc" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142dc30526f"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="5f53806a-8448-4318-ae9b-519135fa2ade" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x149827dafbd"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="9876ba5e-e433-4079-a976-80c08ba1df3e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x143ed3e24c3"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="9bbdd69a-42cf-4ac5-9842-8bb020434e25" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x14415acf50b"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="287a9bef-c591-4bc9-867a-d89afcf306a0" className="x10985">                <div data-layer="cecff82e-1fd3-424c-bf5c-5e196ad44d93" className="xMsOfficeGoogleDocsGoogleWorkspacegmailGoogleDriveGoogleMeetCalendarJira">모집분야 관련 전공자<br />MS Office 혹은 Google Docs 숙련자<br />커뮤니케이션, 문제 해결 능력 및 분석적 사고<br />Google Workspace(Gmail, Google Drive, Google Meet, Calendar) 사용 경험자<br />JIRA 사용 경험자<br />하나 이상의 외국어 커뮤니케이션 능력</div>
                <div data-layer="e3fcb99f-cfbc-49d0-8f78-452086a056de" className="x20c37462">우대 사항</div>
                <div data-layer="4d38ce1f-e0b8-4348-aefb-b28893aff18a" className="linesd375c565">                    <svg data-layer="8d924253-c413-4dad-b56c-0e3a32354293" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14868e25cbf"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="3b5c5679-bbfe-42a2-afbf-969adef07f8a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1427a8192fd"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="d7e79a9c-353c-4dcd-9304-b23253d5f9c9" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x1496b37988a"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="8e241e0d-74c0-4152-ab9e-8c5b1761c3b0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1437dd6b0f6"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="b45ed21d-ec29-4c7b-9b41-a820a8a875a5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144d10e0fca"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="dbbe3b95-7b19-41ce-837a-aedd019be682" className="x11012">                <div data-layer="611f153e-ff42-4f2b-9ad8-104397f808df" className="xce46feab"><span className="xce46feab-0">৹ 경영관리 보조 업무<br /></span><span className="xce46feab-13">    급여/세무신고/원천징수세액 신고 관련 자료 정리 및 취합 전달<br />    매출&매입관련 거래 관리<br />    본사의 연결회계 서포트<br />    기타 회계관련 업무 보조<br /><br /></span><span className="xce46feab-106">৹ 복리후생 제도 기획 및 운영<br /></span><span className="xce46feab-124">    건강검진, 독감 예방접종, 명절 선물 등<br /><br /></span><span className="xce46feab-152">৹ 시설 및 인프라 관리<br /></span><span className="xce46feab-166">    사무환경 관리<br />    자산 관리(소프트웨어 및 하드웨어)<br />    중고 장비 매각, 폐기 및 자산 재활용<br /><br /></span><span className="xce46feab-229">৹ 조직 구성원 서포트<br /></span><span className="xce46feab-242">    복리후생 제도 운영<br />    채용/신규 입사자/면접자 안내<br />    조직 내 설문 및 조사 진행<br />    리뷰 및 보상 서포트<br /><br /></span><span className="xce46feab-315">৹ 직장 내 법정 의무교육(ex. 직장 내 성희롱 예방 교육) 실시<br /></span></div>
                <div data-layer="b339cfcd-01bf-4c36-9887-d5c6015adc92" className="lines">                    <svg data-layer="f6e4a333-ac97-46bb-9358-4176cf7ec578" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 248.8359375 3.1904296875" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="07f7a598-d327-4ea8-bbc8-494e2d409faf" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 248.8359375 3.1904296875" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="fe6317f7-3133-49fa-ade8-84c0af37f929" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 310.4921875 3.1904296875" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="b7b29405-5f0e-4ff5-8bed-03cbcd6dd64f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 310.4921875 3.1904296875" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="ef5c41e6-a8e6-4cb9-bd94-6e443b497a59" preserveAspectRatio="none" viewBox="-1.5947265625 -1.59521484375 70.8076171875 3.1904296875" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="2b2f41f9-3ae4-4501-9f57-aeb8d64ac5a6" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdsupport.propTypes = {

}

Jdsupport.defaultProps = {

}


export default Jdsupport;
          