
import React from "react";
import PropTypes from "prop-types";

import "./jdgamedesignmsl.css";

class Jdgamedesignmsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="878e1e07-48d8-404c-ae28-118a4d4cfc71" className="jdgamedesignmsl">        <div data-layer="e0ee0ce6-d5c9-4c78-a59c-d9ef49792162" className="layer1">            <div data-layer="4d345dbe-23e0-4fbc-a6bf-683fd21d7f66" className="thinkSmartStudyGamesIpRpg">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />현재, 스마트스터디게임즈는 신작 RPG 게임과 라이트코어 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="bf259538-0f22-4333-86b6-9c9da0b9b174" className="x10981">                <div data-layer="bd98b4f0-b07b-4988-bc21-6c0bc19a0150" className="xdef51d87">서류 전형</div>
                <div data-layer="946be2ee-ff99-449b-9882-293e1b02198b" className="x91df6dee">실무 면접</div>
                <div data-layer="4b8c3019-f64e-4bca-94f2-51abb6cbe509" className="x94b650a2">최종 합격</div>
                <div data-layer="fc07b4d9-c711-48bf-b7e4-9ad0ad423b89" className="x10980">                    <div data-layer="feb1d1d0-f7b1-4109-bbe1-2301f435a8ca" className="x10979">                        <svg data-layer="0a1f2a92-51c7-420d-9a3b-da9ec9b7b215" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="d04ac952-234e-49a2-b6ba-6192d4ead4a9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="ad26ccb5-dd6a-4fc4-be1c-7beee0d59ac5" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="fb69b628-d33b-4dc0-9dcc-0c2aeeabec92" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="c66f4092-8f87-457d-8612-9a822d6a14c1" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="78ed5dfe-59fd-4ac1-a489-f8eb244e705c" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="a84850ea-6309-421c-ba46-04e74469577b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="ad5bc963-0322-4f0d-a75e-d22137b0a8e7" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="83d38ddb-df40-47b5-a169-b4359748f653" className="xd1742129">전형 절차</div>
</div>
                <div data-layer="a22db5de-df87-4469-9ddc-294c8de08111" className="x277dcb8b">임원 면접</div>
</div>
            <div data-layer="c8c0a02c-9e07-4e9b-abcb-f1ca6c3f7f1c" className="x10983">                <div data-layer="ac7be27b-f715-415e-8628-fb236bb7d74f" className="x38142ba0"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="d19ae4f5-9c4c-4ce6-a4ed-426b313b5bec" className="x89ceb3cc">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="5f03eeaa-85b0-4016-99db-3dae1499e148" className="x3b8eb1c5">지원 방법</div>
                <div data-layer="36f3026a-3891-4f0c-ade8-a79bb4680f03" className="lines5efc3874">                    <svg data-layer="84283cbe-9ce6-4b76-bc64-405eb2cc0f75" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14835b39db6"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="13248499-7d33-4591-bbf6-2f7b34b3ddd8" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1421b89be86"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="c34aacd7-237a-447f-9e0b-191c1f8bb293" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149a15a7e05"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="32dee048-725d-45a6-a448-b0ffc066a371" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1430682c961"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="1f98d941-54e8-4798-b47d-36601798ce7e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144e3c2f45d"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="d9373509-9630-495a-a2bf-57ce299153fa" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="110b1577-934b-4d23-a27d-7e0528970bd3" className="x10987">                <div data-layer="8c0aa5c7-5778-4228-9332-a0c4f4a496fe" className="xb06338a0">스토리, 콘텐츠 기획 경험이 있는 분<br />환경 및 우선순위 변화에 빠르게 적응 가능한 분<br />팀 중심의 적극적인 커뮤니케이션이 가능한 분<br />문제 해결 능력 및 분석적 사고를 가진 분<br />구체적인 업무 내용이 포함된 포트폴리오가 있는 분<br />자율과 책임을 기반으로 자기주도적으로 일하실 수 있는 분</div>
                <div data-layer="255bb9a1-93d6-49bb-bd7b-9b6eb4da6eb1" className="x2ddc79ab">자격 요건</div>
                <div data-layer="99401117-fec4-4557-bba3-d5a0fd403a41" className="lines3f1ecbd1">                    <svg data-layer="0a4af469-99de-482d-b1a0-5dc4d90456a1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1488e3113f0"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="708ae5ca-5b24-4f62-b5ac-9a0addd882d3" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1426b45c488"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="b7cdbf42-b194-46b8-8241-dc4faa44c3b1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1499b6ea594"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="eaefcfe3-12f0-454c-9ed8-d5a6b9599340" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14370fed0d5"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="1b6c5be4-7e9e-4748-bfaf-aca4561b8b9d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x14406872d20"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="56fa84fa-2a90-4e4f-8901-27033494e8e5" className="x10985">                <div data-layer="6d6ae552-7981-4023-a9af-22c375f1eca4" className="xRpgJiraUnity">몬스터 슈퍼 리그 플레이 경험이 있는 분<br />웹소설 작성 및 독자 피드백을 받아본 경험이 있는 분<br />모바일 RPG 라이브 경험이 있는 분<br />글로벌 게임 서비스 경험이 있는 분<br />JIRA, Unity 사용 경험이 있는 분<br />온라인 게임 산업 및 게임에 대한 관심이 있는 분</div>
                <div data-layer="f69d85d2-dcde-4d35-a3e4-add01c202180" className="xdfbf15e8">우대 사항</div>
                <div data-layer="d95791b9-8a96-4c86-99e8-f789827a8c85" className="lines49574c35">                    <svg data-layer="a5b86ee8-a8e9-46c2-910d-a6ebdaef43a8" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14856872d39"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="268cf15c-9223-431b-9685-da9a2f82dd5f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14250261794"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="1a10977c-7244-41ed-9cf6-cea323fe4fe0" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1496a3a569a"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="0f3ef188-b951-4108-a0f1-f50a814d9e33" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1430dd33cf7"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="e410bb64-e58d-4e56-892c-2067583db371" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144b76e7e1a"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="cb691009-0647-4cdf-958a-4ef04dc5dbc6" className="x11012">                <div data-layer="4e1d7802-6b0e-4987-a433-8cc133b035b1" className="xb484fd07">스타몬 스토리 및 시나리오 기획<br />콘텐츠 및 이벤트 기획<br />번역 관리<br />라이브 서비스 관리<br />데이터 관리 및 작성<br />업데이트 대응 및 모니터링</div>
                <div data-layer="4704a300-781b-4eb5-abe3-e02fd844e3de" className="lines">                    <svg data-layer="729da1fc-048d-4475-8fee-c1ec5540a8c0" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="7b6830b9-de53-4544-bcbb-fb218d4f3e2c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="242514aa-93b1-4983-be53-eba18297cd1f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="714dee1b-3b60-4d56-b3b6-fb6a3e0d9b7a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="9b4b0b4a-360c-4d14-a566-814bd68a5023" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="7ccae845-34cb-4526-8bd6-f67e175cc7bd" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdgamedesignmsl.propTypes = {

}

Jdgamedesignmsl.defaultProps = {

}


export default Jdgamedesignmsl;
          