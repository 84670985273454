
import React from "react";
import PropTypes from "prop-types";

import "./jdprogrammer.css";

class Jdprogrammer extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="df33de92-0e9b-4860-9cd8-7fa97d3bc348" className="jdprogrammer">        <div data-layer="49dfb24d-e74f-4b73-bba4-c24fe3784958" className="layer1">            <div data-layer="3fdcb675-f6c8-45f3-9a7d-732df741dd6a" className="thinkSmartStudyGamesIp2024Rpg">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="dc380da2-2f73-4861-8e81-fdc2529ff9ba" className="x10981">                <div data-layer="1fd014b2-5be5-426b-8c7c-acca9cda3321" className="x03c2afe4">서류 전형</div>
                <div data-layer="0f679018-ba26-4daa-b5d5-913fb56b0701" className="x53535dc3">실무 면접</div>
                <div data-layer="4714073e-9d3f-4046-8a95-7bca9ac2b582" className="xf8d638e9">최종 합격</div>
                <div data-layer="d57b2070-15d4-4dfd-9821-10b875cd4805" className="x10980">                    <div data-layer="5f0b0cfe-357e-48a6-9ea2-85e5cd6f7daf" className="x10979">                        <svg data-layer="bdc217f4-6f53-4715-a19d-3f0a9b5c47c3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 896.95703125 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="69c86fb5-1832-4e55-9af6-ad394318d80c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="70cbb63d-4977-4c35-8c35-12dee6341899" preserveAspectRatio="none" viewBox="-1.5966796875 -1.59716796875 356.603515625 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="6225d466-e68b-47d8-a697-e6524c11acbb" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 80.427734375 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="8452db6a-f97d-4dc8-aaf3-e1c7f0696192" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="7dff72ce-e5d1-4d20-8da6-e8de5226dbaa" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="1137c85a-6d5e-428b-af53-7621f4d9bd34" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="f7a627e4-f926-4452-bc14-8579c6f608f2" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="8c3562fc-e644-4112-8c78-532b70a4b2e3" className="x6f54cc16">전형 절차</div>
</div>
                <div data-layer="4f47491f-cc0e-474b-8c5b-0bb20619eb25" className="x31d8bc64">임원 면접</div>
</div>
            <div data-layer="32439592-0720-455b-b3db-6d9b5c1c07d0" className="x10983">                <div data-layer="b3e02ac1-1e01-42b7-a56d-62d8b48c61ea" className="x727350f7"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="ea0f244f-5704-4364-8c3a-66ddf86ade8b" className="xfe6f1590">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="7e31c82c-d9aa-45a8-bd65-ade4602cb9c0" className="xad54e1ed">지원 방법</div>
                <div data-layer="8c61e2db-dedd-47eb-90f7-bb3395711d7f" className="linesa5df7eeb">                    <svg data-layer="06f552b7-cb68-4150-8ee1-7d1a1b89c4f1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14840201392"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="9fdeb31d-13e5-4d4e-80bf-eb60c4c71b4a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142813d9d71"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="f90f7423-46b8-4dee-9a13-8421ba1dd83d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x14995ffbebe"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="432ccc64-5e5b-42d9-b106-41e7ea534fa5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x14322d8e92e"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="2de61304-478d-472d-b013-f41ac2d7c700" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1447b40257f"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="17262a8c-afa8-40ea-b541-77f45538bf31" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="fcbfe2ae-45c0-4858-8ee1-bb699fffc3a8" className="x10987">                <div data-layer="63eda396-7db8-4d0f-a96f-3542e080d487" className="c">C#을 능숙하게 사용 가능하신 분<br />개발에 대한 열정이 있고, 문제 해결에 재미를 느끼시는 분<br />새로운 언어, 환경에 대한 두려움이 없는 분<br />개발 문서 이해가 가능할 정도의 영어 능력<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="9f0dc3a6-d5e7-43e4-bf24-05f41cee941e" className="xb4f6b45a">자격 요건</div>
                <div data-layer="65b5f34f-bb35-4228-934d-32c45eb015ec" className="lines159b2e55">                    <svg data-layer="4f856d86-6fe6-4b9f-ac70-62adf471d8d6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1480eaaf7b3"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="48e51d5c-21cb-4e29-ac29-1f00afa67166" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14245e964d4"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="88c99f03-0c83-45b9-92f2-6186a1f6d058" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x14946ba99d9"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="3f9bce4e-d043-4b6f-b491-ef6b03cb9b0c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1430fe23397"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="fb3fa8a4-bddd-4776-b209-6c2e348385e0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1443d48ff4a"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="b38175b5-fe8d-46bd-9cee-2e6a3cdec9f0" className="x10985">                <div data-layer="65ee5eb0-fc22-4057-9a7c-a6f639a65fbb" className="xUnityAndroidiosDbDbAws">최초 개발 단계부터 런칭까지 경험자<br />Unity또는 기타엔진으로 서비스 오픈 및 유지보수 경험<br />모바일(Android/iOS) 플랫폼에 대한 이해와 개발 경험<br />서버 개발 경험 및 DB 최적화등 DB를 능숙하게 다룰 수 있는 분<br />AWS 사용 경험<br /></div>
                <div data-layer="fa7e4574-0a65-4936-8191-994cf22ec916" className="x9a096ec6">우대 사항</div>
                <div data-layer="dd91ad53-14d3-4099-baec-64bf070c0e28" className="lines6f053ef8">                    <svg data-layer="7d2a2b9d-b583-46d9-a51a-d27cd707105a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148b4e3420a"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="634c6ac8-5ef6-4f38-a319-38dd556b9646" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1422c11b167"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="4999112b-2d71-43cd-9545-a54b710cbd50" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149027175cb"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="e8a1f3f6-b75b-4f74-9534-80f5dabfbc78" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x143676fe8aa"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="a1034d37-547b-468f-90a0-10c8734895ac" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144f6c4c87a"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="fab3b259-a15b-43ec-88d0-eade456b2227" className="x11012">                <div data-layer="4801b0c4-8bba-4244-aa44-484bf7babc78" className="x3268ea7d">글로벌 타겟 모바일 프로젝트 라테시아의 개발 업무</div>
                <div data-layer="f8a8e2ef-c7b2-49f0-bbf2-26d7b4107fe5" className="lines">                    <svg data-layer="bd473d4e-6903-4c6d-9230-dbfcef284cf8" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="4bf84392-b551-42db-bbdb-a0fe0937bc28" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="d3b68d93-0fe3-4961-9786-51c46a1be3f0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="77f1eacb-4082-4330-b4ea-5acd3e74aebf" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="b54b4355-8e46-4b72-8cab-f3862ea35cf5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="c92bd70e-652c-4224-a3a5-43bf7ba7bb3d" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdprogrammer.propTypes = {

}

Jdprogrammer.defaultProps = {

}


export default Jdprogrammer;
          