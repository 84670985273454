
import React from "react";
import PropTypes from "prop-types";
import { HashLink as Link } from 'react-router-hash-link';
import VideoLocalPlayer from "../../videoLocalPlayer";

import MheaderImpl from "../../mheader_impl";
import "./mculture.css";
import McultureImpl from "./mculture_impl";
import YoutubePlayer from "../../youtubePlayer";

class Mculture extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  handleClick(target) {
    if (this.props.onClick) {
        let name;
        let id;
        let index = -1;
        if (target.search("::") > -1) {
            const varCount = target.split("::").length;
            if (varCount === 2) {
                name = target.split("::")[0];
                id = target.split("::")[1];
            } else if (varCount === 3) {
                name = target.split("::")[0];
                index = parseInt(target.split("::")[1]);
                id = target.split("::")[2];
            }
        } else {
            name = target;
        }
        this.props.onClick({ type: 'button', name: name, index: index, id: id });
    }
  }
    
  render() {
    
    return (
<McultureImpl>
          <div data-layer="cb72acbe-1f97-4386-8829-d3a065ac97a6" className="mculture">        <div data-layer="ff4bcbd0-a132-4d78-92b7-d3249f21f1f9" className="footer">            <div data-layer="127b3305-73d9-4551-ac69-797cb48dc112" className="x2252"></div>
            <div data-layer="3d7d66ed-e9f1-4e96-bc82-1663ceb3fa6a" className="smartStudyGamesCoLtdAllRightsReserved">Smart Study Games. Co, Ltd. All rights reserved.</div>
            <div data-layer="7ea842c8-6fb7-477a-9b6c-6b9383aef49e" className="logosmartstudygames2500x64091f87ce6"></div>
</div>
        <div data-layer="4fbfc7ab-af8b-48d8-a890-1689b7680bad" className="navitogames">            <div data-layer="96277ba6-391d-4094-af1c-31ae329bd156" className="x10"></div>
            <div data-layer="03d9ff19-e485-4f3d-9e05-ce0515045c80" className="x10929">                <div data-layer="446a904b-cd95-4f86-993a-feef2e1ca253" className="x17633"></div>
                <Link data-layer="4835b88f-e998-44b7-8ead-af4dae4c7204" className="x11011" to='/games'>                    <div data-layer="510e528b-cb1e-4d0b-86f4-c9c32b9090d0" className="x0a0ac30d">스마트스터디게임즈가 서비스 중인 <br />게임을 소개합니다.</div>
                    <div data-layer="f16eb6e8-f683-4ba5-9428-37c2b0df8f11" className="x17584"></div>
                    <div data-layer="8ac94630-d948-4599-acfb-14ab624acc3f" className="xfb282801">게임 둘러보기</div>
</Link>
</div>
</div>
        <div data-layer="fead934f-291f-4730-88d1-a29412facf16" className="contentbody">            <div data-layer="0687a551-5f42-49f9-af53-5e5f2e138341" className="moremovie">                <div data-layer="435b2cc8-c4ab-4fb1-8471-48e6994ee42a" className="artwallbg"></div>
                <div data-layer="ca87be7f-4214-4fe8-97db-1aed86763a14" className="twi001t3226289l"></div>
                <div data-layer="99c35496-657f-4dc7-9fe1-e65cec83e1bf" className="moremovieinner">                    <div data-layer="d0a67a50-6861-4f0c-97fa-69f4ef786e7c" className="ssgworkshop">                        <div data-layer="532ef6fb-682e-4db7-a7ca-70f9d1d21c6e" className="x2"></div>
                        <YoutubePlayer videoid='HUey7fnSaLg' title='2022_SSG_workshop' controls='1' targetWidth='682' targetHeight='411' targetY='1'>                            <div data-layer="1dfc59ca-0e54-40d9-9e3c-86295788a7e4" className="x17645"></div>
</YoutubePlayer>
</div>
                    <div data-layer="0e569999-6f45-4da5-b5ef-0f7cfc86121f" className="sandzinterview">                        <div data-layer="2637ff1d-962f-4644-9c0c-2029bd06b735" className="x2023042160111"></div>
                        <YoutubePlayer videoid='cMHq-VVtoBg' title='Sandz_Interview' controls='1' targetWidth='682' targetHeight='411' targetY='1'>                            <div data-layer="8b9df758-66c3-4c79-a4d6-19a97e3d3a73" className="x17644"></div>
</YoutubePlayer>
</div>
                    <div data-layer="69abf81b-5088-4e99-962e-b2f2da32a997" className="xe76bff11"><span className="xe76bff11-0">다른 영상 </span><span className="xe76bff11-6">더 보기</span></div>
</div>
</div>
            <div data-layer="938de7f4-6659-47c6-add8-e58dbb681865" className="expandablecontents">                <div data-layer="bec56368-22a0-40d0-aaaa-3fea677d0c0a" className="expandableend">                    <div data-layer="198adf55-103d-441a-a07d-a84fbb29d05e" className="buttonmore">                        <div data-layer="49ff9705-aa29-4894-8e68-1117d5c2a3ba" className="x2255"></div>
                        <div data-layer="651e8df0-6f1e-4d10-b900-f1a70f16f87b" className="moretext">간단히 -</div>
</div>
                    <svg data-layer="58d1da48-3311-4ac7-9e7f-90059906909b" preserveAspectRatio="none" viewBox="0 -0.5 680 1" className="x152"><path d="M 0 0 L 680 0"  /></svg>
</div>
                <div data-layer="2583adcb-ab13-4b06-a225-34646b9c06e2" className="expandableinner">                    <div data-layer="96e19a09-aa32-41e1-8b31-cc5ef2771dcc" className="x">                        <div data-layer="fed27971-618e-4585-b354-29d63c3b3bc5" className="x3095bb81">마지막으로 하고 싶은 말?</div>
                        <div data-layer="40b82618-2eee-41c7-8c9e-0664ef63557f" className="xRpg">저희는 하나의 장르에 국한되어 게임을 개발하고 있지는 않아요. 수집형 RPG 뿐 아니라 하이퍼캐쥬얼, 그리고 조금 더 코어한 게임류도 관심이 많고, 개발하려고 하고 있습니다. <br />많은 기대와 관심, 격려 부탁드립니다. 감사합니다.</div>
                        <div data-layer="54ef0447-165b-4e09-b998-5e957cdb2ff9" className="xRpg576fbb58">“앞서서 저희가 주력하고 있는 장르가 수집형 <br />RPG라고 말씀드렸는데요.”</div>
                        <svg data-layer="e5e0aa34-1ac7-47d3-bbfa-5debff21ff2e" preserveAspectRatio="none" viewBox="0 0 62 61" className="x238"><path d="M 31 0 C 48.12082672119141 0 62 13.65531730651855 62 30.5 C 62 47.34468078613281 48.12082672119141 61 31 61 C 13.87917327880859 61 0 47.34468078613281 0 30.5 C 0 13.65531730651855 13.87917327880859 0 31 0 Z"  /></svg>
                        <div data-layer="f4ab875d-716b-46e5-88fe-22716c6eee69" className="q9ed43fcc"><span className="q9ed43fcc-0"></span><span className="q9ed43fcc-0">Q</span></div>
</div>
                    <svg data-layer="bc487f60-0cee-4006-aa60-59769f47c6d1" preserveAspectRatio="none" viewBox="0 -0.5 680 1" className="x95"><path d="M 0 0 L 680 0"  /></svg>
                    <div data-layer="4ebe76ac-5e73-485c-b95f-0c74001408cf" className="x7715f245">                        <div data-layer="c707e3e8-fc72-4c2a-b888-bcbc9bb73e9a" className="x637b1dc6"><span className="x637b1dc6-0"></span><span className="x637b1dc6-0">스마트스터디게임즈가 추구하는 인재는?</span></div>
                        <div data-layer="2bbae339-4838-4b9e-ad32-04685f5ad796" className="xa3dd5e12">"나 레이드좀 하는데? 싶은 사람?"</div>
                        <div data-layer="b3164348-63c5-4216-bd79-b779067c7293" className="xb70caae1">성과를 내기 위해 끊임없이 노력하고, 자유롭게 피드백을 주고 받으면서 성장하는 사람이라면 언제나 저희의 레이드에 환영이죠! <br />저희 회사 홈페이지에 가시면, 인재상 체크리스트가 있는데요. 내가 그 인재다! 싶으면 적극 지원 부탁드립니다. </div>
                        <svg data-layer="21ffe18c-0c78-408d-8382-8de242c55974" preserveAspectRatio="none" viewBox="0 0 62 61" className="x216"><path d="M 31 0 C 48.12082672119141 0 62 13.65531730651855 62 30.5 C 62 47.34468078613281 48.12082672119141 61 31 61 C 13.87917327880859 61 0 47.34468078613281 0 30.5 C 0 13.65531730651855 13.87917327880859 0 31 0 Z"  /></svg>
                        <div data-layer="004130c9-8fa0-49b6-8846-684ce5e2e66d" className="q"><span className="q-0"></span><span className="q-0">Q</span></div>
</div>
                    <svg data-layer="2092461d-c066-420a-a945-b6849b202b65" preserveAspectRatio="none" viewBox="0 -0.5 680 1" className="x94"><path d="M 0 0 L 680 0"  /></svg>
                    <div data-layer="2e88479b-9d9a-4cb6-b576-930d113094a8" className="xfedc9fa4">                        <div data-layer="6499e962-20b1-457e-826f-ceabb52b52cb" className="x7030a64f">회사를 한 단어로 표현한다면?</div>
                        <div data-layer="27c8ca61-1375-4b16-ad44-afdf270f512a" className="xThinkSmartStudyGames">문자 그대로 스마트하게 생각하고 모두가 즐거운 게임을 위해 열심히 연구하고 노력하라라는 의미도 있습니다. 한편으로는 Think! ‘Smart Study Games’ 즉, 원 레이드 팀으로, 모두를 위해 생각하고 행동하자는 의미도 담고 있습니다.</div>
                        <div data-layer="5e73cb7b-ec89-490d-8271-f33139341cfa" className="tssgThinkSmartStudyGames">“TSSG? Think Smart, Study Games.”</div>
                        <svg data-layer="5c106c39-0044-462a-a353-234c66f02d5d" preserveAspectRatio="none" viewBox="0 0 62 61" className="x215"><path d="M 31 0 C 48.12082672119141 0 62 13.65531730651855 62 30.5 C 62 47.34468078613281 48.12082672119141 61 31 61 C 13.87917327880859 61 0 47.34468078613281 0 30.5 C 0 13.65531730651855 13.87917327880859 0 31 0 Z"  /></svg>
                        <div data-layer="2c40b4fc-f6bd-45ff-8e03-7df20dd9a1f8" className="q73e06e4a"><span className="q73e06e4a-0"></span><span className="q73e06e4a-0">Q</span></div>
</div>
                    <svg data-layer="62b259f5-8e49-4799-92cf-b1ca24315e11" preserveAspectRatio="none" viewBox="0 -0.5 680 1" className="x93"><path d="M 0 0 L 680 0"  /></svg>
                    <div data-layer="df94f66b-3fa6-45e9-89f0-f7451540aae6" className="x8da01caa">                        <div data-layer="c07c5e5a-c0f1-41bb-8bb3-3bf169e8a303" className="x442c61d4">우리가 추구하는 조직문화는 서로가 자유롭게 피드백을 주고받되, 나의 말이 상대에게 어떻게 들릴지 항상 고려하는 사람들이… 언제나 기꺼이 도움을 주고받는 그런 문화예요.그리고 목표 달성에 도움이 된다면 시간, 장소에 크게 구애받지 않아야 한다고 생각해요. 그래서 재택, 유연근무, 무제한 휴가 등의 문화가 생겨났죠. 사실 이런 문화는 효율성을 높여주는 수단이지 복지라고 생각하지는 않아요. 직원들이 능력을 최대한 발휘하도록 환경을 만들어주는 것은 회사의 당연한 의무와 역할이니까요.<br />아, 그리고 스스데이라고, 한 달에 한번 반나절동안 원하는 활동을 하게끔 지원하고 있어요. 스스데이가 꾸려지면 스포츠를 할 수도 있고 게임을 하기도 하고요. 구성원들이 즐거워하는 것을 보면 목표 달성에 한걸음 더 다가간 느낌을 받는것 같아요.</div>
                        <div data-layer="6ae93597-1fcd-4a97-9be5-74793573913c" className="xf3b98dd3">“스마트스터디 게임즈는 조직 문화에 노력을 많이 <br /> 기울이고 있는 회사예요.”</div>
                        <div data-layer="3ef65c5e-ff2a-4a1e-b4cc-87a61059871b" className="xd3b070ac">스마트스터디 게임즈만의 특징이 있을까요?</div>
                        <svg data-layer="981e0e04-987c-42f6-93f5-82225327c166" preserveAspectRatio="none" viewBox="0 0 62 61" className="x214"><path d="M 31 0 C 48.12082672119141 0 62 13.65531730651855 62 30.5 C 62 47.34468078613281 48.12082672119141 61 31 61 C 13.87917327880859 61 0 47.34468078613281 0 30.5 C 0 13.65531730651855 13.87917327880859 0 31 0 Z"  /></svg>
                        <div data-layer="d85b8c23-c8e8-4b69-a601-05c86cb22923" className="q85d4cb7f"><span className="q85d4cb7f-0"></span><span className="q85d4cb7f-0">Q</span></div>
</div>
                    <svg data-layer="f68d4eef-9c0a-4e8e-b999-e0189c587c79" preserveAspectRatio="none" viewBox="0 -0.5 680 1" className="x62"><path d="M 0 0 L 680 0"  /></svg>
</div>
                <div data-layer="30096436-7f5f-4a01-81ad-4ce3a8d43e9d" className="expandableouter">                    <div data-layer="6dbf39aa-cb44-43e7-beac-6a8de991f5af" className="x70bbc2d6">                        <div data-layer="dd9be6ab-8b2b-43c9-aec6-b6cf4f9e4fe3" className="xd6f18398"><span className="xd6f18398-0"></span><span className="xd6f18398-0">스마트스터디 게임즈는 어떤 회사인가요?</span></div>
                        <div data-layer="3fa03ddc-6fd1-4d56-927b-29f7e768a9ce" className="xRpgRpg">그래서 많은 분들이 스마트스터디 게임즈는 아기상어로 유아동 게임을 만드는 회사인가?’라고 생각하실 것 같아요.  아주 일부분만 정답이예요. 스스게는 유아동 콘텐츠 이미지를 탈피하고, RPG 장르를 전문적으로 만들기 위해 독립한 회사거든요.  주력은 미드코어 수집형 RPG이구요. 현재는 몬스터 슈퍼 리그라는 게임을 글로벌에서 서비스 하고 있어요. <br />스마트스터디 게임즈는 게임 개발을 레이드라고 생각하는 사람들이 모여서 만든 조직이예요. 레이드는 한 가지 큰 목표를 두고, 그 목표를 위해서 각자는 성장을 위해 깊은 노력을 하지만,  조직적으로는 다른사람과 커뮤니케이션을 굉장히 활발하게 해야하는 특징이 있잖아요. 게임 개발이 레이드랑 유사하다고 생각해요. <br />사실 기사도 한번 나갔지만, 스마트스터디 게임즈는 몬슈리와는 별개로 새로운 레이드팀을 꾸리고 있어요. 프로젝트 라테시아입니다. 몬슈리의 세계관과 IP를 공유하는 새로운 방식의 수집형 RPG예요. 앞으로 많은 관심과 기대 부탁드립니다.</div>
                        <div data-layer="805456f8-e513-4f76-a025-cb2b6ede9547" className="xcace5c45">"스마트스터디는 아기상어랑 핑크퐁이 제일 유명하<br />잖아요."</div>
                        <svg data-layer="d244958d-58bc-47e3-9c0e-ab28a599dce2" preserveAspectRatio="none" viewBox="0 0 62 61" className="x143"><path d="M 31 0 C 48.12082672119141 0 62 13.65531730651855 62 30.5 C 62 47.34468078613281 48.12082672119141 61 31 61 C 13.87917327880859 61 0 47.34468078613281 0 30.5 C 0 13.65531730651855 13.87917327880859 0 31 0 Z"  /></svg>
                        <div data-layer="9c6055bf-5def-4158-ba82-7f6d8c75467a" className="q59d12812"><span className="q59d12812-0"></span><span className="q59d12812-0">Q</span></div>
</div>
</div>
</div>
            <div data-layer="5ec961b5-9ebd-48bd-8193-9ce2e88bce93" className="x80722468">                <div data-layer="ef657ba9-1528-4023-b2d7-1e473f6bfefe" className="twi001t3226289l2fa8a789"></div>
                <div data-layer="d530b71c-dc33-4825-97df-39c24f503972" className="ceointerview">                    <div data-layer="adce45c9-6938-4bfc-bab0-2c2e82f0964e" className="x722aed98"></div>
                    <div data-layer="ea67a3d2-2b8e-45bf-99d9-6012f5cec027" className="x17613"></div>
                    <YoutubePlayer videoid='JeOjCn87EGc' title='ceo_interview' controls='1' targetWidth='682' targetHeight='411' targetY='1'>                        <div data-layer="03242403-23c9-4450-a989-348fa6e40fa2" className="x17643"></div>
</YoutubePlayer>
</div>
                <svg data-layer="2dffe373-bc5d-4f39-9052-7318f9768627" preserveAspectRatio="none" viewBox="0 0 53.27978515625 53.27978515625" className="playButton2"><path d="M 26.639892578125 0 C 11.95065593719482 0 0 11.95065593719482 0 26.639892578125 C 0 41.32912826538086 11.95065593719482 53.27978515625 26.639892578125 53.27978515625 C 41.32912826538086 53.27978515625 53.27978515625 41.32912826538086 53.27978515625 26.639892578125 C 53.27978515625 11.95065593719482 41.32912826538086 0 26.639892578125 0 Z M 40.45977783203125 27.37337684631348 L 20.92385864257812 40.69332504272461 C 20.77378845214844 40.79544448852539 20.59885215759277 40.84783554077148 20.42391777038574 40.84783554077148 C 20.28183746337891 40.84783554077148 20.13887023925781 40.81409454345703 20.00922584533691 40.74482727050781 C 19.71796035766602 40.59120178222656 19.53592300415039 40.28928375244141 19.53592300415039 39.9598388671875 L 19.53592300415039 13.3199462890625 C 19.53592300415039 12.99049949645996 19.71796035766602 12.6885814666748 20.00922584533691 12.53495788574219 C 20.30048561096191 12.380446434021 20.65213394165039 12.40175819396973 20.92474937438965 12.58646106719971 L 40.46067047119141 25.90640830993652 C 40.70220184326172 26.07157325744629 40.84783554077148 26.34596633911133 40.84783554077148 26.639892578125 C 40.84783554077148 26.93381690979004 40.70220184326172 27.20820999145508 40.45977783203125 27.37337684631348 Z"  /></svg>
                <div data-layer="98ca52c2-5eb0-455f-b89c-53c8c7dc11f1" className="x0ba2f567">&lt;스마트스터디 게임즈 이동진 대표님 인터뷰></div>
                <div data-layer="58049b25-5f76-4cb1-b712-f6efe1aa4747" className="x54700dfb">모회사 더핑크퐁컴퍼니(구 스마트스터디)에서 독립한 스마트스터디 게임즈! 왜 자회사로 독립했을까? 아기 상어 게임을 만드는 곳인가? 어떤 문화를 추구하며 어떤 사람들이 함께 일하고 있을까?<br />스마트스터디 게임즈에 대해 궁금한 점들을 대표님께 직접 물어보았습니다.</div>
</div>
            <div data-layer="d7cd9451-b83d-42b0-a8ab-1b7178adafef" className="x17c8f4ef">"자유롭게 피드백을 주고 받으면서<br />성장하는 사람이라면<br />언제나 저희의 레이드에 환영입니다."</div>
            <div data-layer="755a8ea0-f882-437c-8abe-0a2dffbc77e8" className="xa4595c1e">                <div data-layer="f1ff034d-7316-41b0-817a-5c1281d3e427" className="x8"></div>
                <VideoLocalPlayer videosrc='./assets/culture_top.mp4' top='0' originWidth='1920' originHeight='1080' viewHeight='1440' targetWidth='2560px' targetHeight='1440px' minWidth='1440px'>                    <div data-layer="d5e5c381-3b9f-4047-8162-6d622fe46098" className="dummy"></div>
</VideoLocalPlayer>
                <div data-layer="ca6c48dd-b7b4-46f1-8e4f-1b20e788b70f" className="blackmask"></div>
                <div data-layer="aa477703-4443-428d-96ec-de1f47e90c41" className="x1d2a84e4">조직문화</div>
                <div data-layer="debf05c4-bb39-4b20-b9be-17a627e773c5" className="xb1a03e1c">우리 서비스에 특화된 조직문화를, <br />우리만의 방식으로 만들고자 합니다.</div>
</div>
</div>
        <MheaderImpl><div data-layer="91be8679-305a-48d2-8540-8d9a065a1cf3" className="header">            <div data-layer="bf3fbca7-f38a-47d6-977b-aa9ca9a5c082" className="logosmartstudygames2500x640"></div>
            <div data-layer="ecd06dfa-3aa9-4953-8787-053cf0bd39b9" className="navibar">                <div data-layer="3c0dd6f2-9c53-4690-b207-2543ccb8ca42" className="x17579"></div>
                <div data-layer="74675dd8-5e09-4ce1-b6fc-b5d0e326d4f9" className="x17580"></div>
                <div data-layer="33807c25-004b-4c56-bf60-e60b60cbbe55" className="x17594"></div>
                <div data-layer="75f0828c-fed0-49c7-b8f8-1c9418f658e0" className="x17638"></div>
</div>
</div></MheaderImpl>
</div>

</McultureImpl>
    );
  }
}

Mculture.propTypes = {

}

Mculture.defaultProps = {

}


export default Mculture;
          