import React, {useEffect} from "react";
import { useMediaQuery } from "react-responsive"
import { useLocation, useHistory } from "react-router-dom";

import StickyHeader from './pages/stickyheader/stickyheader';
import Mstickyheader from './pages/mstickyheader/mstickyheader';
import StickyheaderImpl from './stickyheader_impl';

const StickyHeaderWrapper = (props) => {

    const isPc = useMediaQuery({
        query : "(min-width:1200px)"
      });
    
      const isTablet = useMediaQuery({
        query : "(min-width:768px) and (max-width:1199px)"
      });
    
      const isMobile = useMediaQuery({
        query : "(max-width:767px)"
      });

      const loc = useLocation();    

    useEffect(() => {
      
      if(loc.hash!=null && loc.hash.length>0) {
        return;
      }
      
      window.scrollTo(0, 0);
    }, [loc]);
    
    return (
        <div>
            {isPc && <StickyHeader />}
            {(isTablet || isMobile) && <Mstickyheader />}
        </div>
    );
};

export default StickyHeaderWrapper;