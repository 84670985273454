import React, { useRef, useState, useEffect, useCallback } from 'react';
import './humanres_impl.css';

const HumanresImpl = (props) => {

    const checkPosDelta = 20.63;

    const [arrIsChecks, setChecks] = useState([false, false, false, false, false, false, false, false]);

    const toggleSetChecks = useCallback((idx) => {
        setChecks(prevState => prevState.map((val, i) => idx === i ? !val : val))
        // console.log('toggle index.' + idx);
    })

    const recursivelyMod = (children) => {
        return React.Children.map(children, (childNode) => {
            
            if (typeof childNode === "string")
            {
                return childNode;
            }
            if (typeof childNode.props.children === "string" && childNode.props.className === "moretext")
            {
                return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
            }

            if(typeof childNode.props.className === "string" && childNode.props.className.indexOf("checkrect") > -1)
            {
                const num = childNode.props.className.substr(9, 1);
                const index = parseInt(num)-1;
                return React.cloneElement(childNode, {onClick: () => toggleSetChecks(index) }, recursivelyMod(childNode.props.children)); 
            }
            else if(typeof childNode.props.className === "string" && childNode.props.className.indexOf("checkbox") > -1)
            {
                const num = childNode.props.className.substr(8, 1);
                const index = parseInt(num)-1;

                if(arrIsChecks[index])
                {
                    const styled = {
                        top: `${checkPosDelta}px`
                    }
                    return (
                        <div>
                            <div className="checkboxselectedmod" style={styled}>
                                <div className="bg1"></div>
                                <div className="bg2"></div>
                                <svg preserveAspectRatio="none" viewBox="-0.7500000149011612 -0.7499999850988388 14.104248046875 11.5830078125" className="bgcheck"><path d="M 11.5498161315918 0.205966591835022 C 11.78460597991943 -0.06149456277489662 12.17259311676025 -0.06952041387557983 12.41640853881836 0.1880403459072113 C 12.63313579559326 0.4169833064079285 12.66299629211426 0.778709352016449 12.50205421447754 1.044112801551819 L 12.4327507019043 1.138676643371582 L 4.76149845123291 9.877364158630371 C 4.564173698425293 10.10214614868164 4.256890296936035 10.14481163024902 4.018030166625977 9.996164321899414 L 3.920371055603027 9.920718193054199 L 0.2132340967655182 6.422848701477051 C -0.04338398948311806 6.180716037750244 -0.07248032093048096 5.756224155426025 0.1482456028461456 5.474719047546387 C 0.3444464504718781 5.224493026733398 0.6719956994056702 5.171471118927002 0.922593355178833 5.332834720611572 L 1.012553334236145 5.403428077697754 L 4.280386924743652 8.485895156860352 L 11.5498161315918 0.205966591835022 Z"  /></svg>
                            </div>
                        </div>
                        );
                }
                else
                    return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
            }
            
            return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
        })
    }

    return (
        <div>
            {recursivelyMod(props.children)}
        </div>
    );
}

export default HumanresImpl;