import React, { useRef, useState, useEffect, useCallback } from 'react';
import './mhumanres_impl.css';

const MhumanresImpl = (props) => {

    const checkPosDelta = 43.5;

    const [arrIsChecks, setChecks] = useState([false, false, false, false, false, false, false, false]);

    const toggleSetChecks = useCallback((idx) => {
        setChecks(prevState => prevState.map((val, i) => idx === i ? !val : val))
        // console.log('toggle index.' + idx);
    })

    const recursivelyMod = (children) => {
        return React.Children.map(children, (childNode) => {
            
            if (typeof childNode === "string")
            {
                return childNode;
            }
            if (typeof childNode.props.children === "string" && childNode.props.className === "moretext")
            {
                return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
            }

            if(typeof childNode.props.className === "string" && childNode.props.className.indexOf("checkrect") > -1)
            {
                const num = childNode.props.className.substr(9, 1);
                const index = parseInt(num)-1;
                return React.cloneElement(childNode, {onClick: () => toggleSetChecks(index) }, recursivelyMod(childNode.props.children)); 
            }
            else if(typeof childNode.props.className === "string" && childNode.props.className.indexOf("checkbox") > -1)
            {
                const num = childNode.props.className.substr(8, 1);
                const index = parseInt(num)-1;

                if(arrIsChecks[index])
                {
                    const styled = {
                        top: `${checkPosDelta}px`
                    }
                    return (
                        <div>
                            <div className="checkboxselectedmod" style={styled}>
                                <div className="bg1"></div>
                                <div className="bg2"></div>
                                <svg preserveAspectRatio="none" viewBox="-0.7499999403953552 -0.7500000596046448 21.469482421875 17.4755859375" className="bgcheck"><path d="M 18.29899978637695 0.3263239860534668 C 18.67098999023438 -0.09742918610572815 19.28569793701172 -0.1101449802517891 19.67198944091797 0.2979224622249603 C 20.01536178588867 0.6606492400169373 20.06267166137695 1.23375141620636 19.80768203735352 1.654244422912598 L 19.69787979125977 1.804067134857178 L 7.543900012969971 15.64924335479736 C 7.23126745223999 16.0053768157959 6.74442195892334 16.07297515869141 6.365983009338379 15.83746433258057 L 6.21125602722168 15.71793079376221 L 0.337838351726532 10.17606735229492 C -0.06873558461666107 9.79244327545166 -0.1148344725370407 9.119897842407227 0.2348735630512238 8.673893928527832 C 0.5457251667976379 8.277447700500488 1.064679145812988 8.193441390991211 1.461714625358582 8.449098587036133 L 1.604243040084839 8.560943603515625 L 6.781648635864258 13.44466304779053 L 18.29899978637695 0.3263239860534668 Z"  /></svg>
                            </div>
                        </div>
                        );
                }
                else
                    return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
            }
            
            return React.cloneElement(childNode, [], recursivelyMod(childNode.props.children));
        })
    }

    return (
        <div>
            {recursivelyMod(props.children)}
        </div>
    );
}

export default MhumanresImpl;