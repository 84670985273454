import React, { useMemo, useLayoutEffect, useEffect, useState, useCallback } from "react";
import MetaTags from 'react-meta-tags';
import { throttle } from 'lodash'
import { isMobile, isTablet } from 'react-device-detect';

const ResponsiveModule = (props) => {

    const [scaleFactor, setScaleFactor] = useState(1.0);
    const [targetWidth, setTargetWidth] = useState('device-width');
    const [width, setInnerWidth] = useState(0);
    const [isPortrait, setIsPortrait] = useState(false);
    let mql = window.matchMedia("(orientation: portrait)");

    // const { NODE_ENV } = process.env as {[key as string]: string}
    // const isProd = NODE_ENV === 'production';

    const throttledResize = useMemo(
    () =>
        throttle(() => {

            setInnerWidth((isPortrait) ? window.screen.width : window.screen.height);

        }, 300),
    [width]
    );

    const stdMobile = '750';
    const handleScale = useCallback(() => {
        if(isMobile || isTablet)
        {
            if(width>=parseInt(stdMobile)) {
                setScaleFactor(1.0);
                setTargetWidth('device-width');
            }
            else
            {
                setScaleFactor(width / parseInt(stdMobile));
                setTargetWidth(stdMobile);
            }
        }
    });

    useEffect(() => {
        // re render

        if(width<=0)
            return;

        handleScale();

    }, [width]);

    mql.onchange = (e) => {
        setIsPortrait(e.matches);
    }
    
    useEffect(() => {
        // initial hit
        handleScale();
        setIsPortrait(mql.matches);
    }, []);
    
    useEffect(() => {
        
        setInnerWidth((isPortrait) ? window.screen.width : window.screen.height);
        
    }, [isPortrait]);
    
    useLayoutEffect(() => {
        window.addEventListener('resize', throttledResize);
        return () => {
            window.removeEventListener('resize', throttledResize);
        }
    }, [throttledResize]);

    const ViewportMeta = () => {
        
        const c = `width=${targetWidth}, initial-scale=${scaleFactor}`
        return (
            <meta name="viewport" content={c} />
        )
    }

    const consoleoutput = `isMobile = ${isMobile}\nwidth = ${width}\ntargetWidth = ${targetWidth}\nscaleFactor = ${scaleFactor} ori.portrait = ${isPortrait}`;

    return (
        <div>
            <div className="console_output"  style={{position:'fixed', left:'10px', top:'10px', zIndex:'100'}}>
                {/* {consoleoutput} */}
            </div>
            <MetaTags>
                <ViewportMeta />
            </MetaTags>
        </div>
    );
}
export default ResponsiveModule;
