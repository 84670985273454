
import React from "react";
import PropTypes from "prop-types";

import "./mjdeffectormsl.css";

class Mjdeffectormsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="054258a5-c1c7-4c5c-8393-e7e1a67f696e" className="mjdeffectormsl">        <div data-layer="856c1a3f-01c7-4aeb-8c88-943af342b077" className="layer1">            <div data-layer="b6f62482-e60c-453b-8288-98be81252f46" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="2f853e66-f8fd-4ce1-b938-781fb0938734" className="x10981">                <div data-layer="77ddc153-c888-4d55-9c6f-5472cfa0bcfc" className="x4ce0d770">서류 전형</div>
                <div data-layer="a23cbdea-98a2-4d9e-8c5c-d33de38de2c4" className="x0aafcbce">실무 면접</div>
                <div data-layer="064c94ae-f254-4e74-a378-81956b703b28" className="xfdf7c357">최종 합격</div>
                <div data-layer="d84e7bf6-3ed5-4513-88b5-c5410eb6102a" className="x10980">                    <div data-layer="3e37a203-208d-4836-8879-c8c78342e2e3" className="x10979">                        <svg data-layer="7ff22fa1-9f70-4c63-82a0-d66482ed46c6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 501.189453125 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="b4b159a5-8a50-4666-b164-382710585bfe" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 126.189453125 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="d89467fb-974e-4277-a9ee-22f4223ba836" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 213.189453125 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="f04362d7-4997-4014-b437-9313f6dd3ee7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 53.189453125 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="047b65e3-b6f1-42a2-bb50-6966896b4c2c" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="43799ac4-45aa-4c0e-8896-561086cabca2" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="25cd569c-04f5-4634-ab08-3cc8c5d1134e" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="82f05d03-44b3-4159-b531-3ffea4ef591d" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="642745ea-2410-4897-8d3d-0ef35aec6951" className="x4cffee1c">전형 절차</div>
</div>
                <div data-layer="2d645e88-c277-446c-803d-9a098d21e8a8" className="x8cb7fd16">임원 면접</div>
</div>
            <div data-layer="4dd20783-d717-4060-a1af-1bdae0662337" className="x10983">                <div data-layer="2493e102-aa69-41cf-896a-125c5cd23da8" className="x08d8ddd5"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="363f8c2e-dbbb-47fb-afbc-6afff713b07b" className="x0e989652">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="ed2449bb-a0f0-4318-b972-7a6f5bbfc31e" className="xe448c8d7">지원 방법</div>
                <div data-layer="8c124f40-d6bd-45be-a5ad-a6cda2c44fdc" className="lines6bef1ba8">                    <svg data-layer="367d3815-9f12-42fe-9cc4-a4cb2c0713eb" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x14850c97250"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b15247a0-a497-4df9-bf79-15f78abd1a56" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142fa5eef67"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e6495575-01f2-4e39-a191-2892860a2bbf" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x14944f7ddb5"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="e463693f-8292-40c3-81e4-f5951ff99b20" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143cae66820"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="1bd0fca4-4f73-48a9-9371-d9f148aa2530" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x1442d0172d7"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="e43a0316-15c4-4230-95f3-0376812b3d69" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="c0f7d44d-24d7-4d45-9008-f010dd13ecf8" className="x10987">                <div data-layer="278d764a-b634-4ad6-aa2f-221a95732687" className="x3UnityRpg">3년 이상의 모바일 게임 이펙터 경력<br />Unity 사용에 익숙한 분<br />카툰 스타일의 RPG에 대한 거부감이 없는 분 <br />평소 애니메이션을 즐겨보시는 분 <br />꼼꼼하고 책임감 있으신 분 </div>
                <div data-layer="124467aa-a27e-4397-9dfb-00a631ab8bc7" className="x5e47e5c9">자격 요건</div>
                <div data-layer="2a9bf967-9c42-4fa3-bd4e-900a42937c60" className="linesc90c97fe">                    <svg data-layer="dc17c807-6012-44bd-ac8c-c61766455779" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x14833c680d0"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="7523f854-20f2-4b10-a953-f703b756d9da" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x1428d563357"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="fb40eab4-9ac6-48fd-b44f-2e7e2c0b7f9e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149dcadadf8"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="b5c140e5-8b6a-4ab0-b12e-829c51d54a77" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x14361501539"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="9a112e8a-1c5e-455c-99a2-0c72315652b6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x14418396e57"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="ece134d7-85c4-41f1-a390-9234ae36afad" className="x10985">                <div data-layer="78167809-a1e5-460b-af4f-bc7dc1bda6c4" className="xf57fd33c">애니메이션 스타일의 이펙트 제작 유경험자<br />관련학과 전공자<br />영상 연출 혹은 씬 제작 경험자<br />원활한 커뮤니케이션이 가능하신 분 </div>
                <div data-layer="f37a1c63-83dc-4b96-bafd-7a6e84e2fe3d" className="x37828f24">우대 사항</div>
                <div data-layer="3929262f-cd28-4ede-ad12-8ec6719692b8" className="lines6916a43d">                    <svg data-layer="d01ab3a5-5410-4bba-81ad-d1e2565843ba" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x14833430f56"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="8802ef63-fb4f-45de-a90a-6fc99c04f03c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x1426683e7dc"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="4d6f9550-f7bc-4b42-a933-b9edee8e870a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x1496b09c765"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="6007be1c-fb4a-4449-803a-c0e96b11a692" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x1435163c32e"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="927628d9-397a-4fb3-991b-e06fffc91ece" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x144af062dc5"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="5bbd2f5a-a4cb-43f7-9cf3-caa2b2462752" className="x11012">                <div data-layer="814c14f4-62c9-4389-99c1-e4af27081b55" className="xRpgUi">글로벌 원빌드 수집형 RPG ‘몬스터 슈퍼 리그’의 전투 스킬 이펙트 제작<br />UI 및 화면 연출 씬 이펙트 제작</div>
                <div data-layer="290e64aa-eaeb-491c-8d4e-ebd7de2911f8" className="x">주요 업무</div>
                <div data-layer="0dd0f085-69ae-4a57-a2ea-b28c41e36f31" className="lines">                    <svg data-layer="af75b22e-ceed-441b-9552-99136940e24e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="a300ae50-b205-424b-a25d-6f8131d97be5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b0d7c25d-3006-4372-8181-0ac001437ca0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="7577b60e-e94a-4032-a81d-3bc783a41992" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="a4a13f6d-7eeb-42fd-8b1e-742bb6f5c27f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdeffectormsl.propTypes = {

}

Mjdeffectormsl.defaultProps = {

}


export default Mjdeffectormsl;
          