
import React from "react";
import PropTypes from "prop-types";
import { HashLink as Link } from 'react-router-hash-link';
import VideoLocalPlayer from "../../videoLocalPlayer";

import MheaderImpl from "../../mheader_impl";
import "./mhumanres.css";
import MhumanresImpl from "./mhumanres_impl";

class Mhumanres extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  handleClick(target) {
    if (this.props.onClick) {
        let name;
        let id;
        let index = -1;
        if (target.search("::") > -1) {
            const varCount = target.split("::").length;
            if (varCount === 2) {
                name = target.split("::")[0];
                id = target.split("::")[1];
            } else if (varCount === 3) {
                name = target.split("::")[0];
                index = parseInt(target.split("::")[1]);
                id = target.split("::")[2];
            }
        } else {
            name = target;
        }
        this.props.onClick({ type: 'button', name: name, index: index, id: id });
    }
  }
    
  render() {
    
    return (
<MhumanresImpl>
          <div data-layer="83816220-a5ec-47de-ab41-21fd6a2d8a69" className="mhumanres">        <div data-layer="d71aab11-1a70-4456-ae1f-c4e25b425b34" className="footer">            <div data-layer="d0711c64-a6fd-4f42-93d5-c1b403798ceb" className="x2252"></div>
            <div data-layer="c1492145-4ad1-422f-93bd-fbb5a8a96d0b" className="smartStudyGamesCoLtdAllRightsReserved">Smart Study Games Co., Ltd. All rights reserved.</div>
            <div data-layer="9310cc00-3b71-4e92-ba1c-fe4bd17f2dab" className="logosmartstudygames2500x6401a2a2f21"></div>
</div>
        <div data-layer="2030c9a4-4b5a-4edd-842e-583178f4347f" className="contentbody">            <div data-layer="a21693ff-f439-439a-a713-66d95d3a9d33" className="x9ab86578">                <div data-layer="c983b444-563e-4b48-a429-559cae9a64c9" className="x13"></div>
                <Link data-layer="1fc92a4a-31e4-4c16-8bad-e96b43138d0e" className="x10947" to='/recruit'>                    <div data-layer="bff26136-4c38-495a-a627-f9865dc2d2f1" className="x732d0489">우리가 추구하는 가치와 문화에 <br />공감하신다면 지금 지원하세요.</div>
                    <div data-layer="27c776da-4898-4b1b-bf5b-b49154af485f" className="x17584"></div>
                    <div data-layer="331effdd-b4ca-4bdd-a01f-a437043aca1a" className="x">지원하러 가기</div>
</Link>
</div>
            <div data-layer="0b78c036-9214-4c50-9cbd-8c2632e09d78" className="staffs">                <div data-layer="8d1ac0f0-7354-47bf-845d-168cda08d056" className="xed888af5"><span className="xed888af5-0">스마트스터디게임즈에서는 <br /></span><span className="xed888af5-14">이런 사람들이 함께해요!</span></div>
                <div data-layer="0a0fb908-792c-4de8-9849-ad8828c12011" className="staff1">                    <div data-layer="edb83227-1bf0-4e3c-8805-501481833d59" className="x17477"></div>
                    <div data-layer="93f40482-5297-4917-ab87-fca87a58bba5" className="xcd6a77e6">우리 회사는 자율을 기반으로 책임감<br />을 가지고 일하는 사람을 좋아해요.<br />이런 문화를 바탕으로 주도적으로 업<br />무를 수행하며 더 좋을 결과물을 만<br />들어내고 있죠!</div>
                    <div data-layer="15f8dec5-161b-458e-ba9c-642a21da7bd4" className="x36f468be"></div>
</div>
                <div data-layer="4cf68c90-6d2c-4940-89f0-7930553ac047" className="staff2">                    <div data-layer="c2a34fc0-3c12-424c-8030-5c5fc7568351" className="x17589"></div>
                    <div data-layer="0b70b0b9-8d0b-4331-9c65-e4c474a799ca" className="xeea76bdf"></div>
                    <div data-layer="ede18e22-e713-4bd3-be73-55a3eb3c156b" className="x6c1dbaba">회사에서 최고의 복지는 좋은 동료와 함<br />께 일하는 것이라고 생각합니다.<br />그런면에서 우리 회사는 최고의 복지를 <br />가지고 있다고 말할 수 있어요.</div>
</div>
                <div data-layer="0b7132ab-ecfe-4e0b-badb-a21231d6aa81" className="staff3">                    <div data-layer="50bc74e5-2ca6-4e40-98ec-0a80a8aad995" className="x17591"></div>
                    <div data-layer="a3d4ef9a-8581-4a9d-b1ec-959fa93536d0" className="xa1a4f501">상상을 뛰어넘는 폭 넓은 경험이 <br />가능해요. 누군가의 한계를 정의하지<br />않고, 무엇이든 가능하다고 <br />얘기해주는 회사에요.</div>
                    <div data-layer="80c6f349-0c1a-4919-8c07-b72ac63931ca" className="x1"></div>
</div>
                <div data-layer="6ac8bcbe-916e-49e0-8bcb-672e39df0207" className="staff4">                    <div data-layer="df768963-8339-4d71-8973-21987eece0a3" className="x17590"></div>
                    <div data-layer="8da48b52-de68-4e70-b705-bb713ac4e681" className="x6d5c5668">직원들에게 좋은 경험을 제공하고자 <br />끝없이 노력하는 회사에요.<br />스스 데이, 랜덤 티타임 등 직원들이 <br />함께 참여하는 새로운 조직 문화를 <br />만들어 나가고 있는 중입니다.</div>
                    <div data-layer="6a3fb3ad-7f7d-4975-807b-ff5c712c3cdf" className="x10f95d213"></div>
</div>
                <div data-layer="628c66b5-efc5-4257-b6bb-48393d6c1452" className="staff5">                    <div data-layer="9e6434db-729f-4099-888e-8ea725f6cdca" className="x17593"></div>
                    <div data-layer="26638748-ddc7-4245-8896-9ac381fcec1d" className="x4fd2cf7d"></div>
                    <div data-layer="5c42b1a9-5171-44bd-bbaf-eb31aff810b6" className="xc53cefde">일반적인 기업의 체계를 겪었던 경력<br />직 구성원들에겐 우리 회사의 사내문<br />화가 처음엔 조금 낯설 수 있습니다.<br />좋은 쪽으로요. 오셔서 함께 꿈을 펼<br />쳐 보아요!</div>
</div>
                <div data-layer="8bb9944e-1510-454e-8877-c236dea51bfa" className="staff6">                    <div data-layer="f6772fe3-65a9-4a5a-9e05-911402941101" className="x17592"></div>
                    <div data-layer="8e19fa20-efe8-443a-9e53-ebd7eb507f78" className="x813000ba">스마트스터디게임즈는 스스로 성장하고<br />있다는 걸 느끼게 해주는 회사입니다. <br />뿐만아니라 어떤것이든 도전해 볼 <br />기회를 줍니다. </div>
                    <div data-layer="009015b0-1af0-4c75-909d-78d63399c0df" className="xa7862636"></div>
</div>
</div>
            <div data-layer="97cbaf1b-b688-49ba-81a6-b4144948eac0" className="x14"></div>
            <div data-layer="36f293ce-2ca3-4006-9808-48caba8b4609" className="checklist">                <svg data-layer="236d890d-a41f-4f3e-bdd8-253fd583d874" preserveAspectRatio="none" viewBox="0 -0.5 682 1" className="x87"><path d="M 0 0 L 682 0"  /></svg>
                <svg data-layer="5cc08cc0-35d3-49aa-a220-bacaf8349a97" preserveAspectRatio="none" viewBox="0 -0.5 682 1" className="x85"><path d="M 0 0 L 682 0"  /></svg>
                <svg data-layer="ecd6f710-2927-4568-9993-f0eda6aff4a8" preserveAspectRatio="none" viewBox="0 -0.5 682 1" className="x86"><path d="M 0 0 L 682 0"  /></svg>
                <svg data-layer="80d68ea0-1bbf-41ff-813b-4ae93543f4ac" preserveAspectRatio="none" viewBox="0 -0.5 682 1" className="x91"><path d="M 0 0 L 682 0"  /></svg>
                <svg data-layer="19182700-9dbf-43b3-9154-cc9bd9ff4b47" preserveAspectRatio="none" viewBox="0 -0.5 682 1" className="x89"><path d="M 0 0 L 682 0"  /></svg>
                <svg data-layer="b96716dc-c908-4c30-b98c-e402a3b5d6ed" preserveAspectRatio="none" viewBox="0 -0.5 682 1" className="x90"><path d="M 0 0 L 682 0"  /></svg>
                <svg data-layer="27fcde45-bb4b-4ed9-a78d-1d8292eae3ce" preserveAspectRatio="none" viewBox="0 -0.5 682 1" className="x92"><path d="M 0 0 L 682 0"  /></svg>
                <svg data-layer="764c8489-b133-4b44-9c25-9e6d7a82b306" preserveAspectRatio="none" viewBox="0 -1 682 2" className="x83"><path d="M 0 0 L 682 0"  /></svg>
                <svg data-layer="c739f517-19be-43fb-8f44-ad4fbd0e9654" preserveAspectRatio="none" viewBox="0 -0.5 682 1" className="x84"><path d="M 0 0 L 682 0"  /></svg>
                <div data-layer="562fd8f0-8bff-4458-9434-3ae5bcf91a7b" className="checkboxselected">                    <div data-layer="e7aab0de-46ab-46bd-b686-f742188fad65" className="bg395b21c2"></div>
                    <div data-layer="0938819d-5805-4df8-b8c2-dcb7ee73c0f2" className="bg0595d9ef"></div>
                    <svg data-layer="8d2d3e33-75fd-423f-ba30-2429132a618c" preserveAspectRatio="none" viewBox="-0.7499999403953552 -0.7500000596046448 21.469482421875 17.4755859375" className="checkdbf21458"><path d="M 18.29899978637695 0.3263239860534668 C 18.67098999023438 -0.09742918610572815 19.28569793701172 -0.1101449802517891 19.67198944091797 0.2979224622249603 C 20.01536178588867 0.6606492400169373 20.06267166137695 1.23375141620636 19.80768203735352 1.654244422912598 L 19.69787979125977 1.804067134857178 L 7.543900012969971 15.64924335479736 C 7.23126745223999 16.0053768157959 6.74442195892334 16.07297515869141 6.365983009338379 15.83746433258057 L 6.21125602722168 15.71793079376221 L 0.337838351726532 10.17606735229492 C -0.06873558461666107 9.79244327545166 -0.1148344725370407 9.119897842407227 0.2348735630512238 8.673893928527832 C 0.5457251667976379 8.277447700500488 1.064679145812988 8.193441390991211 1.461714625358582 8.449098587036133 L 1.604243040084839 8.560943603515625 L 6.781648635864258 13.44466304779053 L 18.29899978637695 0.3263239860534668 Z"  /></svg>
</div>
                <div data-layer="5d195a05-efa7-4232-8166-3dbbf190c1e1" className="checkrect8">                    <div data-layer="66fe8e90-d08b-4396-9956-aa78d9b6e8b6" className="x687b11f7">자신의 성장에 만족할 줄 모르는 사람 </div>
                    <div data-layer="f339623e-d898-43f7-ac40-11ea0ce52e8d" className="x17626"></div>
                    <div data-layer="6d0255dc-4cff-4332-9959-c88be6330734" className="checkbox8">                        <div data-layer="d9dcd613-31c8-47ad-abcd-e3e953dd1d57" className="bg0cae7c29"></div>
                        <div data-layer="35b9cd07-2c40-42bf-ba4b-7aa3f88c0edf" className="bg"></div>
                        <svg data-layer="5b414909-c24a-41b4-b260-cfe2c7b5408f" preserveAspectRatio="none" viewBox="-0.7499999403953552 -0.7500000596046448 21.469482421875 17.4755859375" className="check"><path d="M 18.29899978637695 0.3263239860534668 C 18.67098999023438 -0.09742918610572815 19.28569793701172 -0.1101449802517891 19.67198944091797 0.2979224622249603 C 20.01536178588867 0.6606492400169373 20.06267166137695 1.23375141620636 19.80768203735352 1.654244422912598 L 19.69787979125977 1.804067134857178 L 7.543900012969971 15.64924335479736 C 7.23126745223999 16.0053768157959 6.74442195892334 16.07297515869141 6.365983009338379 15.83746433258057 L 6.21125602722168 15.71793079376221 L 0.337838351726532 10.17606735229492 C -0.06873558461666107 9.79244327545166 -0.1148344725370407 9.119897842407227 0.2348735630512238 8.673893928527832 C 0.5457251667976379 8.277447700500488 1.064679145812988 8.193441390991211 1.461714625358582 8.449098587036133 L 1.604243040084839 8.560943603515625 L 6.781648635864258 13.44466304779053 L 18.29899978637695 0.3263239860534668 Z"  /></svg>
</div>
</div>
                <div data-layer="61029aae-a51f-4c10-9e1d-756d3869ed99" className="checkrect7">                    <div data-layer="587b6631-ac12-4d58-91b0-8b2d79c36520" className="x0a55547e">도움을 주겠다는 생각으로 피드백을 하는 사람</div>
                    <div data-layer="b4eb6108-1ccf-408d-8494-43f52ad3a05c" className="x17625"></div>
                    <div data-layer="608ea989-60fc-4dc3-9b06-e5a4488d0b27" className="checkbox7">                        <div data-layer="37095e9c-bd9b-4134-8b30-f3ce67d18d22" className="bgac102b0b"></div>
                        <div data-layer="a832a033-2b6d-4ea0-9a0c-22a45cadd857" className="bgd54d57b7"></div>
                        <svg data-layer="b731b853-c14c-4204-b898-1f01a3e549b8" preserveAspectRatio="none" viewBox="-0.7499999403953552 -0.7500000596046448 21.469482421875 17.4755859375" className="check392d73c8"><path d="M 18.29899978637695 0.3263239860534668 C 18.67098999023438 -0.09742918610572815 19.28569793701172 -0.1101449802517891 19.67198944091797 0.2979224622249603 C 20.01536178588867 0.6606492400169373 20.06267166137695 1.23375141620636 19.80768203735352 1.654244422912598 L 19.69787979125977 1.804067134857178 L 7.543900012969971 15.64924335479736 C 7.23126745223999 16.0053768157959 6.74442195892334 16.07297515869141 6.365983009338379 15.83746433258057 L 6.21125602722168 15.71793079376221 L 0.337838351726532 10.17606735229492 C -0.06873558461666107 9.79244327545166 -0.1148344725370407 9.119897842407227 0.2348735630512238 8.673893928527832 C 0.5457251667976379 8.277447700500488 1.064679145812988 8.193441390991211 1.461714625358582 8.449098587036133 L 1.604243040084839 8.560943603515625 L 6.781648635864258 13.44466304779053 L 18.29899978637695 0.3263239860534668 Z"  /></svg>
</div>
</div>
                <div data-layer="c742632c-c58c-4356-936e-14babf16ce35" className="checkrect6">                    <div data-layer="2968508d-d44f-427e-b7a4-bae36d43badf" className="x56acc7c6">대표가 아닌, 유저가 게임의 주인임을 인정하는 <br />사람</div>
                    <div data-layer="fe8fdb1f-0132-4fa7-b5ea-ea099c24a654" className="checkbox6">                        <div data-layer="e8bd9f86-b862-4425-bc47-42a19d1a3011" className="bg47084feb"></div>
                        <div data-layer="b7ec367e-ccff-4879-8e6a-565ff2b7add7" className="bged9274c3"></div>
                        <svg data-layer="633f4575-3c8b-4bc7-9dc5-5344f5a1ae30" preserveAspectRatio="none" viewBox="-0.7499999403953552 -0.7500000596046448 21.469482421875 17.4755859375" className="check28013c51"><path d="M 18.29899978637695 0.3263239860534668 C 18.67098999023438 -0.09742918610572815 19.28569793701172 -0.1101449802517891 19.67198944091797 0.2979224622249603 C 20.01536178588867 0.6606492400169373 20.06267166137695 1.23375141620636 19.80768203735352 1.654244422912598 L 19.69787979125977 1.804067134857178 L 7.543900012969971 15.64924335479736 C 7.23126745223999 16.0053768157959 6.74442195892334 16.07297515869141 6.365983009338379 15.83746433258057 L 6.21125602722168 15.71793079376221 L 0.337838351726532 10.17606735229492 C -0.06873558461666107 9.79244327545166 -0.1148344725370407 9.119897842407227 0.2348735630512238 8.673893928527832 C 0.5457251667976379 8.277447700500488 1.064679145812988 8.193441390991211 1.461714625358582 8.449098587036133 L 1.604243040084839 8.560943603515625 L 6.781648635864258 13.44466304779053 L 18.29899978637695 0.3263239860534668 Z"  /></svg>
</div>
                    <div data-layer="cec0e8e7-1f61-4f82-9878-04ed64525636" className="x17624"></div>
</div>
                <div data-layer="220cec9a-2b42-46b3-bb09-b6184e17b7af" className="checkrect5">                    <div data-layer="e8a7bec9-6cda-40e0-9c79-ac247e20f30d" className="x19384e4f">쓰레기는 먼저 본 사람이 줍는다 같이 기본적인 <br />인식이 있는사람</div>
                    <div data-layer="1f1daf29-9319-4b22-bf5c-787c4b0b0686" className="checkbox5">                        <div data-layer="73ab4205-46ff-4e17-9baa-d531f181f521" className="bgc60d92c4"></div>
                        <div data-layer="f58ef7c0-401f-49a5-9a30-fef9c7c26bfb" className="bgf854c213"></div>
                        <svg data-layer="d7e1d5c1-03e9-465f-b0d7-7a2d1e975234" preserveAspectRatio="none" viewBox="-0.7499999403953552 -0.7500000596046448 21.469482421875 17.4755859375" className="checkc728fcec"><path d="M 18.29899978637695 0.3263239860534668 C 18.67098999023438 -0.09742918610572815 19.28569793701172 -0.1101449802517891 19.67198944091797 0.2979224622249603 C 20.01536178588867 0.6606492400169373 20.06267166137695 1.23375141620636 19.80768203735352 1.654244422912598 L 19.69787979125977 1.804067134857178 L 7.543900012969971 15.64924335479736 C 7.23126745223999 16.0053768157959 6.74442195892334 16.07297515869141 6.365983009338379 15.83746433258057 L 6.21125602722168 15.71793079376221 L 0.337838351726532 10.17606735229492 C -0.06873558461666107 9.79244327545166 -0.1148344725370407 9.119897842407227 0.2348735630512238 8.673893928527832 C 0.5457251667976379 8.277447700500488 1.064679145812988 8.193441390991211 1.461714625358582 8.449098587036133 L 1.604243040084839 8.560943603515625 L 6.781648635864258 13.44466304779053 L 18.29899978637695 0.3263239860534668 Z"  /></svg>
</div>
                    <div data-layer="a2a2f3af-9e28-4987-9651-e088a76e47d3" className="x17623"></div>
</div>
                <div data-layer="75b601a7-fddc-4dd7-ae29-de70050715ab" className="checkrect4">                    <div data-layer="a94a5727-c87f-4d59-980b-18d88be247e3" className="xc38bc85c">당연한 것들에 문제 제기를 하며, 해결 방법을 <br />찾아가려는 사람</div>
                    <div data-layer="c69778cf-3ed3-4325-a6c4-848ab41386b1" className="x17622"></div>
                    <div data-layer="985e253c-3071-43d3-8f57-10406bc34ddd" className="checkbox4">                        <div data-layer="45af7670-966d-470a-b3ac-4b52cefc72b5" className="bga4ac60a9"></div>
                        <div data-layer="fbd61d26-7025-4e5a-bf04-d2c2d6f41648" className="bgecbc4f9d"></div>
                        <svg data-layer="f959530d-6683-4502-90eb-840aea986cf3" preserveAspectRatio="none" viewBox="-0.7499999403953552 -0.7500000596046448 21.469482421875 17.4755859375" className="check551e3419"><path d="M 18.29899978637695 0.3263239860534668 C 18.67098999023438 -0.09742918610572815 19.28569793701172 -0.1101449802517891 19.67198944091797 0.2979224622249603 C 20.01536178588867 0.6606492400169373 20.06267166137695 1.23375141620636 19.80768203735352 1.654244422912598 L 19.69787979125977 1.804067134857178 L 7.543900012969971 15.64924335479736 C 7.23126745223999 16.0053768157959 6.74442195892334 16.07297515869141 6.365983009338379 15.83746433258057 L 6.21125602722168 15.71793079376221 L 0.337838351726532 10.17606735229492 C -0.06873558461666107 9.79244327545166 -0.1148344725370407 9.119897842407227 0.2348735630512238 8.673893928527832 C 0.5457251667976379 8.277447700500488 1.064679145812988 8.193441390991211 1.461714625358582 8.449098587036133 L 1.604243040084839 8.560943603515625 L 6.781648635864258 13.44466304779053 L 18.29899978637695 0.3263239860534668 Z"  /></svg>
</div>
</div>
                <div data-layer="cdec57d2-dfd9-40a8-92e7-909155c113bc" className="checkrect3">                    <div data-layer="6c47b885-aa7d-40ce-ad98-fb98938432bb" className="x9fbfdaeb">내가 가진 특별함만큼 다른 구성원이 가진 특성을 <br />이해하는 사람</div>
                    <div data-layer="d3605114-bc7f-4017-a662-569d350b26b1" className="checkbox3">                        <div data-layer="f2b0d478-787a-4c96-a730-0eec8e5fa198" className="bg7fa1c64c"></div>
                        <div data-layer="83e7e43f-ae48-48e2-a135-0b22c355fd9f" className="bged6b6158"></div>
                        <svg data-layer="cc328c15-4880-42a4-8e54-09416aef6f3f" preserveAspectRatio="none" viewBox="-0.7499999403953552 -0.7500000596046448 21.469482421875 17.4755859375" className="check11a4a679"><path d="M 18.29899978637695 0.3263239860534668 C 18.67098999023438 -0.09742918610572815 19.28569793701172 -0.1101449802517891 19.67198944091797 0.2979224622249603 C 20.01536178588867 0.6606492400169373 20.06267166137695 1.23375141620636 19.80768203735352 1.654244422912598 L 19.69787979125977 1.804067134857178 L 7.543900012969971 15.64924335479736 C 7.23126745223999 16.0053768157959 6.74442195892334 16.07297515869141 6.365983009338379 15.83746433258057 L 6.21125602722168 15.71793079376221 L 0.337838351726532 10.17606735229492 C -0.06873558461666107 9.79244327545166 -0.1148344725370407 9.119897842407227 0.2348735630512238 8.673893928527832 C 0.5457251667976379 8.277447700500488 1.064679145812988 8.193441390991211 1.461714625358582 8.449098587036133 L 1.604243040084839 8.560943603515625 L 6.781648635864258 13.44466304779053 L 18.29899978637695 0.3263239860534668 Z"  /></svg>
</div>
                    <div data-layer="9e2abae5-7b71-42e5-ab21-125b680add1a" className="x17621"></div>
</div>
                <div data-layer="0e4cb3e3-f3e5-41b4-92a5-75466fe6dec1" className="checkrect2">                    <div data-layer="c9b00274-9e5f-4838-948c-50279bb76063" className="xa0b0ea10">피드백을 받아들이거나 확신이 있다면 피드백<br />에 반대할 수 있는 용기가 있는 사람</div>
                    <div data-layer="33c45f13-5cac-4a97-bf8b-b540734d229c" className="checkbox2">                        <div data-layer="88f6f95a-6790-4058-a04d-1a80ca364f10" className="bg52bd33e2"></div>
                        <div data-layer="a73948f7-939e-4f58-aa1a-4b2e259deafe" className="bg9901a72c"></div>
                        <svg data-layer="ce3ad54f-58f6-42e1-b825-79cf447c628c" preserveAspectRatio="none" viewBox="-0.7499999403953552 -0.7500000596046448 21.469482421875 17.4755859375" className="checkdd1d6619"><path d="M 18.29899978637695 0.3263239860534668 C 18.67098999023438 -0.09742918610572815 19.28569793701172 -0.1101449802517891 19.67198944091797 0.2979224622249603 C 20.01536178588867 0.6606492400169373 20.06267166137695 1.23375141620636 19.80768203735352 1.654244422912598 L 19.69787979125977 1.804067134857178 L 7.543900012969971 15.64924335479736 C 7.23126745223999 16.0053768157959 6.74442195892334 16.07297515869141 6.365983009338379 15.83746433258057 L 6.21125602722168 15.71793079376221 L 0.337838351726532 10.17606735229492 C -0.06873558461666107 9.79244327545166 -0.1148344725370407 9.119897842407227 0.2348735630512238 8.673893928527832 C 0.5457251667976379 8.277447700500488 1.064679145812988 8.193441390991211 1.461714625358582 8.449098587036133 L 1.604243040084839 8.560943603515625 L 6.781648635864258 13.44466304779053 L 18.29899978637695 0.3263239860534668 Z"  /></svg>
</div>
                    <div data-layer="79017067-d0a7-47ac-bae3-82fcc545e025" className="x17620"></div>
</div>
                <div data-layer="597bd8f7-ef69-4b51-95b6-aa84acb9e421" className="checkrect1">                    <div data-layer="c5a9cca4-1f6f-493d-a72a-30d8d6d5d438" className="x00e3a50d">혼자서 해결할 수 없는 문제에 스스럼없이 도움을 <br />요청할 수 있는 사람</div>
                    <div data-layer="c44eb8c2-ddeb-4193-9736-8aeeae86e848" className="checkbox1">                        <div data-layer="1d0bd54e-e178-462f-a867-3529429e615d" className="bge699a809"></div>
                        <div data-layer="cfcda49e-1c2b-40ed-aee8-5b248ac76e8b" className="bg808f35f7"></div>
                        <svg data-layer="ad9f83e6-08e7-46cf-8205-1de71b5df400" preserveAspectRatio="none" viewBox="-0.7499999403953552 -0.7500000596046448 21.469482421875 17.4755859375" className="check4a2eb3b0"><path d="M 18.29899978637695 0.3263239860534668 C 18.67098999023438 -0.09742918610572815 19.28569793701172 -0.1101449802517891 19.67198944091797 0.2979224622249603 C 20.01536178588867 0.6606492400169373 20.06267166137695 1.23375141620636 19.80768203735352 1.654244422912598 L 19.69787979125977 1.804067134857178 L 7.543900012969971 15.64924335479736 C 7.23126745223999 16.0053768157959 6.74442195892334 16.07297515869141 6.365983009338379 15.83746433258057 L 6.21125602722168 15.71793079376221 L 0.337838351726532 10.17606735229492 C -0.06873558461666107 9.79244327545166 -0.1148344725370407 9.119897842407227 0.2348735630512238 8.673893928527832 C 0.5457251667976379 8.277447700500488 1.064679145812988 8.193441390991211 1.461714625358582 8.449098587036133 L 1.604243040084839 8.560943603515625 L 6.781648635864258 13.44466304779053 L 18.29899978637695 0.3263239860534668 Z"  /></svg>
</div>
                    <div data-layer="654b2eac-9d89-4896-b191-9b2f1d652e46" className="x17619"></div>
</div>
</div>
            <div data-layer="b682af65-2d21-4235-8f28-1db0cfbec6b6" className="x4"><span className="x4-0">4개 이상 해당되는 스스게의 <br />피가 흐르는 당신</span><span className="x4-26">,<br /></span><span className="x4-28">지금 바로 초대합니다!</span></div>
            <div data-layer="e19bcccb-075a-4c41-8649-5ee6802ada2f" className="x369fd48e">                <div data-layer="ac51f862-9c4c-41f1-b7e0-55fa240767f2" className="x15"></div>
                <VideoLocalPlayer videosrc='./assets/humanres_top.mp4' top='0' originWidth='1920' originHeight='1080' viewHeight='1440' targetWidth='2560px' targetHeight='1440px' minWidth='1440px'>                    <div data-layer="a52ec0ac-8e98-45b4-a14d-86a4ef3f2733" className="dummy"></div>
</VideoLocalPlayer>
                <div data-layer="25460251-c87c-43c6-8327-9325767666d2" className="blackmask"></div>
                <div data-layer="178e4888-d8ef-4348-acf6-238f10c4ab86" className="x10928">                    <div data-layer="4b136985-a52d-4a0e-80d0-cd2c29b9fe69" className="x8268fc37">인재상</div>
                    <div data-layer="0954ab14-350c-4dcd-9e34-eb9cb7a670b1" className="xa74bfc04">혼자하면 망상, 모이면 꿈, 이루면 현실</div>
</div>
</div>
</div>
        <MheaderImpl><div data-layer="dfaded5d-0797-4963-9856-ade9a43eef73" className="header">            <div data-layer="bda70f45-ea07-475e-8548-2b5ea1de64f0" className="logosmartstudygames2500x640"></div>
            <div data-layer="972db56c-4986-47e9-bb39-4056deea7a36" className="navibar">                <div data-layer="d3412b8a-e3b8-455f-9fde-228c435a6da2" className="x17579"></div>
                <div data-layer="a52f188c-5cdd-4590-afa1-f4a6f024e899" className="x17580"></div>
                <div data-layer="ad44ac8f-3eed-40d3-873c-cfa3778964bd" className="x17594"></div>
                <div data-layer="b9b786c5-d96a-4a2e-a17a-46d109cf02ac" className="x17638"></div>
</div>
</div></MheaderImpl>
</div>

</MhumanresImpl>
    );
  }
}

Mhumanres.propTypes = {

}

Mhumanres.defaultProps = {

}


export default Mhumanres;
          