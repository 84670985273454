
import React from "react";
import PropTypes from "prop-types";

import "./jdgameDesignerLevel.css";

class JdgameDesignerLevel extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="a3050498-e7fa-4aa0-96ff-bea3fc6a6620" className="jdgameDesignerLevel">        <div data-layer="7789249e-1b74-4906-9e84-f00f74467ea4" className="layer1">            <div data-layer="bd4cf567-7bd5-4060-8259-cdb2091c7331" className="thinkSmartStudyGamesIpRpg">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />현재, 스마트스터디게임즈는 신작 RPG 게임과 라이트코어 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="f544dbe0-51f0-47f1-9968-6e1b41e7360b" className="x10981">                <div data-layer="3996d274-e63b-42df-acf6-253a49ad1176" className="xdd4b8965">서류 전형</div>
                <div data-layer="5865974c-aeb9-480a-afc1-01222deecea5" className="xda19952c">실무 면접</div>
                <div data-layer="e0d586f3-1b40-47bd-b3ee-0c7f1928cff6" className="xb3dadb7c">최종 합격</div>
                <div data-layer="e92a54ff-5000-4fac-a3a1-cba6a3b8e798" className="x10980">                    <div data-layer="2d18befa-5196-4d99-b6e4-c08f59bb35b6" className="x10979">                        <svg data-layer="0d2b0a83-2b7c-4ac7-b2a0-e7f14d58d149" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="a9e8e3d4-2cc5-48fb-8859-2f2600f6c403" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="d29b3a7a-af41-4461-9413-dc85634951f3" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="db8870ed-be14-460c-96d8-673fa039d75d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="5864dd38-b7f8-4498-be24-a7e04bb2810e" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="ee71d261-fe21-43cf-802f-cc96ee6f790e" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="233dc062-536e-4713-b115-96eea3f94480" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="f37ec421-097b-4faa-a368-c4a82a7864df" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="7db5c217-4b04-4089-a6ec-5e039bf3abe2" className="x0ac304fa">전형 절차</div>
</div>
                <div data-layer="26faf5e9-950d-430e-b91b-66518577440a" className="xff60fa0f">임원 면접</div>
</div>
            <div data-layer="8cbd99b1-d732-4f6e-8cf9-8439d44b9dfb" className="x10983">                <div data-layer="dc2b83d1-d1e5-4f7c-8bb5-953b7e71c4e1" className="x4e606714"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="18f54166-63ea-4acf-bd32-14add7f0eb7f" className="xd0d0906b">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="10856585-ca35-4bde-8c90-163c93a680f8" className="xf07d574d">지원 방법</div>
                <div data-layer="0d0eb06c-945a-4224-9a58-651795f558af" className="lines0d7b3ccb">                    <svg data-layer="7face4bc-9e8e-4bfe-9114-be4c1552b567" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1480af6d15d"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="87e0791d-663b-4514-b905-6b1c4b59bc7e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1424b690873"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="b209430b-4d9d-4ed0-8044-790e44adf80b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149daa100e8"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="b3edcb7b-1cf9-4938-a99f-e5c735ae0452" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1431ec9146d"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="07503e5c-77c9-4922-8ef1-5ecb5396940a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144e65d71fc"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="7439a70d-3127-4a1b-b454-d613881dac59" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="7834fdb7-e45a-4cf3-8069-d48d94386a99" className="x10987">                <div data-layer="132e6aef-24c8-4efb-a3ca-686ce0466f7f" className="x3Rpg">관련 업무 경력 3년 이상인 분<br />맵에 다양한 콘텐츠를 효과적으로 분배하는 레벨 디자인 경험이 있는 분<br />맵 탐험에 필요한 재미요소를 직접 기획하고 개발을 진행해본 경험이 있는 분<br />모바일 수집형 RPG에 대한 깊은 이해를 가진 분<br />자율과 책임을 기반으로 자기주도적으로 일하실 수 있는 분<br />원활한 의사소통과 협업 능력을 중요하게 여기는 분<br />구체적인 업무 내용이 포함된 포트폴리오가 있는 분</div>
                <div data-layer="d7398bca-8a41-4995-be54-70df38563eca" className="x18ebfeae">자격 요건</div>
                <div data-layer="f1cdea05-5b68-4700-943e-7be74a193614" className="lines20bd57cd">                    <svg data-layer="041c1888-052d-49e4-9aab-9a368c6e17fc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148bce97fb5"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="c762ee31-05fe-4145-8e29-bfb3dbc4ca72" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1420886a881"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="0341aa25-a931-437b-9d23-15ce89f6113b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1494826a553"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="da0e4225-9dcf-418c-b28f-4c6603667a4e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143d870377b"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="1e062afa-e5bf-4a13-8c74-9692cfde9c49" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144f6795d7d"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="7ab8a15c-986e-4dc4-8e4e-43b892a135b6" className="x10985">                <div data-layer="9841f27a-78ce-401a-96bd-3000c2bcc747" className="xRpgRpgRpg">넓은 맵을 모험하며 플레이하는 RPG 게임을 다양하게 경험해본 분<br />최근 출시된 캐릭터 수집형 모바일 RPG 게임을 폭넓게 플레이한 분<br />모바일 수집형 RPG 게임의 라이브 운영 경험이 있는 분<br />최초 개발 단계부터 런칭까지의 경험을 보유한 분</div>
                <div data-layer="52699f10-719a-4395-bb08-02959a0b206e" className="x7ddd456d">우대 사항</div>
                <div data-layer="b7b072c9-c007-4821-9ada-bfcf069fa88c" className="linesdb5aced5">                    <svg data-layer="d7810d25-e06c-43dd-b87c-b1e62741c79b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148d14c874c"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="f0d065cf-2eb7-4bad-a2cd-e0f89f70c064" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142040ea7d9"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="5417efaa-026f-41ab-ac6f-eff88dbb4c9b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149be4f844d"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="4759c84b-7b6c-473e-a553-c6f3d7e638a9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143b5a37401"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="7243d52c-da34-4181-99ae-7cff538e2828" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x14422034f93"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="96b3a22a-b1c5-4b5e-b116-92cdfa381a87" className="x11012">                <div data-layer="ba59194d-9258-4bb0-b467-c53731bd5ee5" className="xRpg">캐릭터 수집형 RPG 게임에서 게임을 풍부하게 만들어 줄 탐험 공간 레벨 디자인<br />공간 탐험 경험을 다채롭게 만들어 줄 레벨 디자인에 필요한 콘텐츠 기획</div>
                <div data-layer="a0d79b27-6eb9-4d33-a3aa-7232b0556f1b" className="lines">                    <svg data-layer="4c9b5d66-a952-4c76-ad0a-dbae2cae9834" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="437309b6-7b37-44af-9464-c0f05506b549" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="d59f495e-0364-47fc-bdf7-741d2b6fd5a6" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="69e3557c-28e1-4acc-afbe-1b4f32070a1a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="80fd7f6b-75e1-4abf-bef7-b2e5a30dfdb7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="f8b98f05-0409-4989-8b7b-c749b6667df2" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

JdgameDesignerLevel.propTypes = {

}

JdgameDesignerLevel.defaultProps = {

}


export default JdgameDesignerLevel;
          