
import React from "react";
import PropTypes from "prop-types";

import "./jdeffectormsl.css";

class Jdeffectormsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="f47a9502-a6fe-4a8d-b061-300c9f8c30cf" className="jdeffectormsl">        <div data-layer="669dd96a-4dc5-484c-986a-c836b6cbaf11" className="layer1">            <div data-layer="5ae33cd0-dd07-4b3b-9b46-ab24d7ff7389" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="4af650d8-079f-452e-9131-a7ed44ac8919" className="x10981">                <div data-layer="266f204b-1d11-484d-b68c-017d5d71a4e2" className="x317d1ac5">서류 전형</div>
                <div data-layer="492c2af9-9009-4518-b04f-928809fadc44" className="x256729e1">실무 면접</div>
                <div data-layer="1cda6164-d81b-40d2-afd5-a0660c9bfbfa" className="xf7a034c8">최종 합격</div>
                <div data-layer="fefcf322-d9df-44ac-b51c-f07844566884" className="x10980">                    <div data-layer="460e741a-30f9-4971-9d39-4c3fe0651793" className="x10979">                        <svg data-layer="8123a6ae-62d0-4bff-9b18-b5aa8ea33f20" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 896.95703125 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="15d7444a-fd56-46c7-958f-4f17885a8658" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="96dbe248-88a7-4fe1-a38b-7ee6fd1f93f9" preserveAspectRatio="none" viewBox="-1.5966796875 -1.59710693359375 356.603515625 3.6617431640625" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="642e3f1f-9608-4f87-bc0e-a5c37b9a50a2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 80.427734375 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="6d9bd6c8-89c4-4402-a30d-f8707f8012f1" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="0b530f08-ef32-4b38-aeed-1b9808d8ecdf" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="1b1dfe31-9947-4b5b-98c7-66addef613cc" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="558405c6-f572-40ce-bd62-fd7474df7282" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="85cc4a00-2040-4773-98ba-8d6cd615e7d2" className="x0d75fa65">전형 절차</div>
</div>
                <div data-layer="99fa13bc-7413-48ba-9145-90ef78d56d9e" className="x8723b513">임원 면접</div>
</div>
            <div data-layer="65b2cdc9-b33c-4b78-9f24-02fd1e7784dd" className="x10983">                <div data-layer="0f8b153d-8d7f-46ea-8058-9ae8d8f4fc6d" className="x5e927bdd"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="f82391f3-b53d-4d94-822b-6edb0dbc6af3" className="xdb885480">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="a86e3903-d492-49af-8528-01e24964d2a8" className="x969d8dd1">지원 방법</div>
                <div data-layer="e9393aad-19c1-4398-bcc1-695741923f7d" className="lines9c0863ef">                    <svg data-layer="1c799619-9c00-45d5-a1a5-6d3e92b6d3d1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 248.8359375 3.19000244140625" className="x148d85e9b96"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="5e298818-454d-40db-878c-2dbbb48b4abe" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 248.8359375 3.19000244140625" className="x142f8658773"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="1b858015-7a47-4474-80a3-fde1e1e7d4a0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 310.4921875 3.19000244140625" className="x149b919ac0a"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="69adeaaf-d749-4397-9ef4-69283327d5fe" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 310.4921875 3.19000244140625" className="x143bd293087"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="b285b9fb-82c4-4908-955d-e422a09c9330" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 70.8076171875 3.19000244140625" className="x144692e3210"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="98086c8c-193e-4ff4-a18f-3298d1794c2e" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="d3ba95e0-d011-40b4-b697-8e200a09a397" className="x10987">                <div data-layer="181ce1e0-bad2-4ba9-8fe9-84a84a783c84" className="x3UnityRpg">3년 이상의 모바일 게임 이펙터 경력<br />Unity 사용에 익숙한 분<br />카툰 스타일의 RPG에 대한 거부감이 없는 분 <br />평소 애니메이션을 즐겨보시는 분 <br />꼼꼼하고 책임감 있으신 분 </div>
                <div data-layer="db8932e2-e57f-4077-80f8-d5fa198f9aae" className="x8dda46ce">자격 요건</div>
                <div data-layer="28eb14c5-e9e5-4b37-acaf-cd83ad70a8b1" className="lines379f3de3">                    <svg data-layer="e814980f-66c4-4fc8-8899-f50f8d7a71ea" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14871d1e828"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="340f519a-8a72-438b-a126-a6a2a3724c6f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14241c31d1c"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="ce19360d-a61c-4cb1-9bcd-a87423a5e43d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1493be4509c"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="387fefef-9075-4e90-a538-8ec182b9c3d5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x143731d99e7"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="9dd58254-b425-4922-8b9c-bd47e5212eed" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144b77398af"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="df208ac6-5ed2-4de7-ae79-513fadc16965" className="x10985">                <div data-layer="9fde24e5-4aa5-4008-8d93-abb274a7bafa" className="x3bb75d11">애니메이션 스타일의 이펙트 제작 유경험자<br />관련학과 전공자<br />영상 연출 혹은 씬 제작 경험자<br />원활한 커뮤니케이션이 가능하신 분 </div>
                <div data-layer="1fd4bec7-741a-4419-a69f-07ef670ac341" className="x54a7ffd3">우대 사항</div>
                <div data-layer="d0b6bde3-623b-4e18-97e4-71521040d158" className="lines5535c35d">                    <svg data-layer="29fd483b-12d7-4edb-b915-4037c6b79b87" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1482c00dbc8"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="382b3c20-fcec-450e-be67-b636ad6191be" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142375851dd"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="f6d004b0-04b1-4f3c-83c0-5e4af34f96ce" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149351747e5"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="12e797f2-01d4-4ee1-a7f5-77be2214d4de" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1431ae53aa4"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="a5af130a-8831-4d04-92bc-a4c4d5dc9528" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144e1a4bb01"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="946de6d6-eeb6-4a3f-9d8f-64da5d6dd249" className="x11012">                <div data-layer="be0ee2bd-e0aa-433c-97ef-b947dad1966e" className="xRpgUi">글로벌 원빌드 수집형 RPG ‘몬스터 슈퍼 리그’의 전투 스킬 이펙트 제작<br />UI 및 화면 연출 씬 이펙트 제작</div>
                <div data-layer="becebb9e-66c4-4f4a-baed-27f55c2686d6" className="lines">                    <svg data-layer="f1336e12-015c-4ed3-b5d9-c9cf539e52f4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="dd6687d1-edf3-48f9-b476-f212d1a7ed80" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="46afd718-778e-435f-9802-37c0d4a1f018" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="e7be79d0-e99d-4f5b-a0a4-056c31d4eea5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="a03a02d3-6694-40fe-8079-9cb33a033116" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="41b8f230-304d-488a-a997-cec41ab20b66" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdeffectormsl.propTypes = {

}

Jdeffectormsl.defaultProps = {

}


export default Jdeffectormsl;
          