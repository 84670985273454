import React, { useMemo, useState, useLayoutEffect, useEffect, useCallback } from "react";
import { throttle } from 'lodash'


const VideoLocalPlayer = (props) => {

    const [width, setInnerWidth] = useState(0);
    const [viewW, setViewWidth] = useState(0);
    const [transY, setTranslateY] = useState(0);

    const handleFit = useCallback(() => {
        
        const orgW = parseInt(props.originWidth);
        const orgH = parseInt(props.originHeight);
        const viewH = parseInt(props.viewHeight);

        // target width
        setInnerWidth(window.innerWidth);
        setViewWidth((orgW<window.innerWidth) ? orgW + 'px' : 'auto');
        
        // calc
        const tx = (viewH - orgH) / 2;
        setTranslateY((tx<0) ? tx : 0);
    });

    const throttledResize = useMemo(
    () =>
        throttle(() => {

            handleFit();

        }, 300),
        [width]
    );

    useLayoutEffect(() => {
        window.addEventListener('resize', throttledResize);
        return () => {
            window.removeEventListener('resize', throttledResize);
        }
    }, [throttledResize]);

    useEffect(() => {
        // initial hit
        handleFit();

    }, []);

    useEffect(() => {

        // console.log('transY.' + transY);
        
    }, [transY]);

    return (
        <div className="videoframe" style={{top:`${props.top}px`, width: `${viewW}`, height:`${props.viewHeight}px`, position: 'absolute', overflow:'hidden', display:'block', marginLeft:'50%', transform: `translate(-50%, 0px)`}}>
            <center>
            <video muted="muted" autoPlay="autoPlay" loop="loop" webkit-playsInline="webkit-playsInline" playsInline="playsInline" style={{width:`${props.targetWidth}`, height:`${props.targetHeight}`, minWidth:`${props.minWidth}`, transform: `translate(px, ${transY}px)`}}>
                <source src={require(`${props.videosrc}`)} type="video/mp4"/>
            </video>
            </center>
        </div>
    );

};

export default VideoLocalPlayer;