
import React from "react";
import PropTypes from "prop-types";

import "./mjdcharacterModelermsl.css";

class MjdcharacterModelermsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="d86066df-fa1f-4932-ab02-769858eeaedd" className="mjdcharacterModelermsl">        <div data-layer="4dfea3b9-5e6d-4eaa-a7b8-b471db0ad45e" className="layer1">            <div data-layer="0f744512-70f5-4c66-83eb-8e4d4786ecbf" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="93cf840f-e61f-494c-b1ea-5a3f45d0c3d0" className="x10981">                <div data-layer="f500a2db-828e-4837-b026-c5be3315f618" className="x3771f6f7">서류 전형</div>
                <div data-layer="948731a9-0a56-4501-8d7f-8b1a5704ad7c" className="x9fef622c">실무 면접</div>
                <div data-layer="d1b90b5d-3bd0-4e41-b54e-a56ac5fe6643" className="xd978948c">최종 합격</div>
                <div data-layer="2154cbc4-d68c-4bd0-a7b2-de4642fdc77b" className="x10980">                    <div data-layer="d578f4c6-f3e5-408a-8e0f-9f5ce98a2525" className="x10979">                        <svg data-layer="c66d505d-7ded-48bd-8d35-b3acee88764a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 501.189453125 3.19000244140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="19730e56-6e57-4f09-a9c8-65e5ba0adfab" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 126.189453125 3.19000244140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="cca03b93-f15c-4d5d-a53a-f4151d19ff14" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 213.189453125 3.19000244140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="791ca84f-0285-4aef-b27f-1c8b652d8bc4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 53.189453125 3.19000244140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="ded04bd7-8a7d-408c-bac1-5e78e3d68090" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="245fd96d-b7b6-456c-914f-fc859f8b40eb" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="ee780c30-038d-42e1-92c8-bbbe151f1976" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="78dc7075-c5e7-4dad-a87f-8b730c50527d" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="520e9389-477f-47f0-b81c-2b18a0987fb3" className="x567e3934">전형 절차</div>
</div>
                <div data-layer="1b64afbd-d1b0-44cf-ab2f-8609a7f55398" className="x6b4b3efe">임원 면접</div>
</div>
            <div data-layer="cc9dfc12-5fd8-49d2-a030-ebcc41bb653c" className="x10983">                <div data-layer="d075915a-7db2-4aed-8e45-281b1bed170b" className="xa5d737de"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="451eee38-fa23-4cfe-b278-a8ba64914855" className="x59332be9">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="6cc15569-da57-4c84-9379-4840179c291e" className="x1d58748e">지원 방법</div>
                <div data-layer="1dd877c4-09a7-4e65-9dac-d739106f6a29" className="lines6b2f5364">                    <svg data-layer="6e2cd84e-f263-4ba1-bf99-3b81df2b5fe0" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.189971923828125" className="x1482f0e6321"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="c82d7167-9e97-4e3f-91e6-d9984b76210b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.189971923828125" className="x142eaa23768"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="bc1ba70c-0b29-4054-9118-d4dd788d5fb8" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.189971923828125" className="x149000127dc"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="5ac7bb3d-771d-465e-a686-72b9d4bd3f8d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.189971923828125" className="x1432068857c"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="521e2a8a-794a-420a-94a2-f33162eea197" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.189971923828125" className="x1449b68f3dd"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="76f367f1-8797-4457-bf06-c5b3194455e6" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="e3f44f54-4afe-4d6f-95eb-e31bc0767632" className="x10987">                <div data-layer="028ed1d0-fbdf-4cdf-964e-19a0916b84cf" className="x3dsMax">3ds MAX 사용에 능숙하고, 캐주얼한 매핑 작업이 가능하신 분<br />재패니메이션풍의 카툰 스타일에 익숙하신 분 <br />다양한 체형의 캐릭터나 몬스터 작업이 익숙하신 분 </div>
                <div data-layer="4e60eace-2a00-4ecd-b4f1-e461dd999cc9" className="x717bea29">자격 요건</div>
                <div data-layer="88ab43d6-0000-48c0-8250-109533394c8e" className="linesf40b0c61">                    <svg data-layer="607a22ca-60c1-4f04-b5fb-88963caf7f8f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x14872b5efcd"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="8d115cff-f86d-43a8-9197-91a9dc10df9e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x142763a5be3"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="1f1f61f5-e947-409f-87e9-424a970fac10" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x14965e3a6f3"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="a868c2b1-de61-4c4d-88ea-d8006fcde7cc" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x1437ff89cb8"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="302916ea-3d8a-454f-bdd8-e1f7c71ced2e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x144583c7791"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="5d4bb528-f522-46d2-af91-d712e53da8d8" className="x10985">                <div data-layer="de767b82-14dc-4143-8180-f874dc01ab64" className="xUnity">평소 애니메이션을 즐겨보시는 분<br />Unity 사용에 거부감이 없으신 분<br />관련학과 전공자<br />원활한 커뮤니케이션이 가능하신 분 </div>
                <div data-layer="0ae64930-54ee-429b-9a30-9668587251e6" className="x89e455a1">우대 사항</div>
                <div data-layer="7873a042-f2b1-457f-b4f1-3115e6ed816a" className="lines6f60bb56">                    <svg data-layer="64f2111d-bc78-4f4f-88db-ba250aa8598d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x148093d6bda"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="ded14a44-c3a4-4209-b957-1ac024aa8473" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x142bb2fd1d9"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b82e417e-b0b2-42ea-9e9a-c12fcb4d8252" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149586c5e0f"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="2e98f1c9-2c8d-43d4-b9c7-864613e8efee" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x143080ab1fb"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="8b2ef191-971d-4f6f-bb95-a0a9619995af" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x1441de6cf0f"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="989bb576-a10b-42e7-b4ff-38680bdb464d" className="x11012">                <div data-layer="0fb49799-2698-474b-b13c-f0ab0ec3cb24" className="xRpg">글로벌 원빌드 수집형 RPG ‘몬스터 슈퍼 리그’의 캐릭터 및 몬스터의 모델링</div>
                <div data-layer="29452304-2ca0-437f-9204-bc7e90e7ba5d" className="x">주요 업무</div>
                <div data-layer="6bc74e0e-6a04-4e87-ae3d-bb6b7b46d0b8" className="lines">                    <svg data-layer="0dd7e8fe-f85d-457e-832e-b3312bfd3e06" preserveAspectRatio="none" viewBox="-1.5947265625 -1.5950002670288086 143.189453125 3.190000534057617" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="3a26ba50-ce6e-48f4-ac4a-791f9df3ffb9" preserveAspectRatio="none" viewBox="-1.5947265625 -1.5950002670288086 143.189453125 3.190000534057617" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="f36c9b03-c25d-4c28-a729-d6d2f986c419" preserveAspectRatio="none" viewBox="-1.5947265625 -1.5950002670288086 173.189453125 3.190000534057617" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="628557a3-e670-4432-a520-53857b815020" preserveAspectRatio="none" viewBox="-1.5947265625 -1.5950002670288086 173.189453125 3.190000534057617" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="21f8df41-2e7a-4a09-a2b8-d46767c5d3dd" preserveAspectRatio="none" viewBox="-1.5947265625 -1.5950002670288086 47.189453125 3.190000534057617" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

MjdcharacterModelermsl.propTypes = {

}

MjdcharacterModelermsl.defaultProps = {

}


export default MjdcharacterModelermsl;
          