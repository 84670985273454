
import React from "react";
import PropTypes from "prop-types";

import "./jdta.css";

class Jdta extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="fe1daac0-9e69-469c-9404-6d848fb8c6e1" className="jdta">        <div data-layer="6c608743-f97b-42ec-9751-ed72353f5b67" className="layer1">            <div data-layer="7537f64a-440c-4992-bf68-c5acfd096cf1" className="thinkSmartStudyGamesIpRpg">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />현재, 스마트스터디게임즈는 신작 RPG 게임과 라이트코어 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="816c33b1-3e22-4b0f-a3a8-36535a1ae788" className="x10981">                <div data-layer="f09e8921-8bb3-4879-b01c-19d6ee7ee485" className="x915470bc">서류 전형</div>
                <div data-layer="55523ee5-ed2c-457e-895d-a5a709a29b11" className="xbd878b5a">실무 면접</div>
                <div data-layer="39cdc28d-5303-427a-8243-24ae48bb0807" className="xd25a9898">최종 합격</div>
                <div data-layer="fe832e65-aee6-41ff-bc85-84489d2d4ddd" className="x10980">                    <div data-layer="f87a311f-30c1-4ff2-b368-9974e7249e89" className="x10979">                        <svg data-layer="888805a8-c5e9-45d5-92bf-ad13c922416a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 896.95703125 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="22d518ca-01e5-4c45-b381-ddec676eb08b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="c2fd5117-c838-4074-8e81-44a7f7d1d28b" preserveAspectRatio="none" viewBox="-1.5966796875 -1.59716796875 356.603515625 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="7ebdf1e9-4426-4ece-b6a4-d1b3ec7231c2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 80.427734375 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="39760e2b-07a9-4711-97e8-3ed59e36a8e9" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="d1fca648-4f16-42cc-a6a7-d22888f0f133" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="e3d584b5-365b-4a17-8ba4-bf7a17519a33" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="210ffd1f-4e48-4a50-a43a-941a3a844ebc" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73324227333069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="4e2937a8-f3ba-45c0-8413-83c82efe0166" className="xc60536bb">전형 절차</div>
</div>
                <div data-layer="495f520a-9f27-4d34-bc25-64a526b523d1" className="xd508b145">임원 면접</div>
</div>
            <div data-layer="aafbef4c-11b9-48cc-b809-5d45a487bb01" className="x10983">                <div data-layer="4ca5f348-f700-4110-b361-8392fb9dfce2" className="x66af8675"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="c23644f0-21dd-4a6b-a6de-38d59ecb8253" className="xe6922ac7">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="22181a82-d8d0-43ae-a536-2eb93162bd62" className="x85c8d749">지원 방법</div>
                <div data-layer="af9fb91c-e8f2-4fe5-bce2-c531e274e108" className="lines8d13308b">                    <svg data-layer="dd122391-6e46-4805-a359-cc8ab884012b" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148b6e12066"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="a6fb5421-2112-4188-82d3-5681868010a6" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142871529aa"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="38197d35-9006-4e34-8d61-dfb5ae3b0ede" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x1498dbe7c81"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="ae068844-fd21-4a8e-943e-9be17d799f18" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x143099a5cf0"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="73aa1ff3-263e-44ca-b556-ca7ee6624dba" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1448bdf52dc"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="5459d01e-ef40-4997-9e0a-4e709738d721" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="2dd33cff-e601-40d1-ad80-74aa03393784" className="x10987">                <div data-layer="37aa77a7-f5e0-46b6-a922-309cd9e209de" className="technicalArtist3Unity">Technical Artist 연관 경력 3년 이상이신 분<br />노드 기반 셰이더 분석 및 작성 능력이 있으신 분<br />Unity 최적화 지식 및 경험을 갖추신 분<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="e9fb7cbc-ade3-40ef-81ad-a535a77cae5e" className="xc50e5b95">자격 요건</div>
                <div data-layer="9e8e3ca0-5db2-4c0e-aeca-c4bc0ae3114c" className="linesbdeca2a7">                    <svg data-layer="ee1ad438-8326-4a55-852b-0ec91f4962b4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148b6fc7043"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="1c2263aa-98aa-4c10-964d-1bdcb3e0c306" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x14244e6900b"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="5327626b-b28e-4b98-8fed-7ca25869fa21" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x149ad1b91ae"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="45f62854-9c6a-455a-a88c-4b5b20bd4ce3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x14314e0d7e5"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="d1253847-9f20-4120-8996-1a79e0a9082f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x1443a8491fc"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="93a5b6a5-c626-456f-b7af-422179e7aea7" className="x10985">                <div data-layer="e38924e3-3164-47a9-87ca-40f26cc28aee" className="unityUrp">Unity URP기반 프로젝트 참여 경험이 있으신분<br />카툰렌더링 기반의 프로젝트 참여 경험이 있으신 분<br />그래픽 파이프라인과 엔진 기능에 대한 이해도가 높으신분<br />개발 단계부터 출시까지 경험이 있으신 분<br />문제 해결결능력 / 문서 작업능력 / 커뮤니케이션 능력이 좋으신 분</div>
                <div data-layer="5531e56e-b6c7-4026-ae98-198115b98ab9" className="x067c9bd3">우대 사항</div>
                <div data-layer="4fd58ea2-63c3-47f6-9bfe-7d42adda59c4" className="linesdf4cb4f3">                    <svg data-layer="7a40f612-fc7c-40f8-8311-d47330a44dc4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148a89961f9"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="8c7ef109-bcc7-44ab-8806-5f85187431a1" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x1421bbe120e"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="01c17187-2655-4e16-983d-a2ad393230ae" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x14910ad2e6a"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="c3e2eab3-9470-4f16-bb8c-8837b39c1920" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4921875 3.18994140625" className="x143fabe0dc1"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="1b873111-f049-483a-b063-f69fa5a1eae9" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144d5706e3a"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="b44602e8-0438-487c-8990-449ead2dcf52" className="x11012">                <div data-layer="ab7a9944-b4b7-4b15-a3df-6944b9cff5cb" className="xTa">글로벌 타겟 모바일 프로젝트 라테시아의 TA 업무<br />아트팀 기술 지원 및 파이프라인 구축과 개선</div>
                <div data-layer="ebc76c0f-9753-4284-afbb-8439cd22f3ae" className="lines">                    <svg data-layer="7d3d01d5-ed5e-4ce0-8f15-ff7044d8c128" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="96550df3-66f1-4468-a550-7b04f21daba2" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 248.8359375 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="26f885bf-1242-4676-9fb5-37655275b554" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="73454310-a0ce-41b1-a96c-f969039ad873" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 310.4912109375 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="604693dd-d474-46be-9012-13c9e31bb8e9" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 70.8076171875 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="d418872c-70e4-4f88-84cf-3d98f7454946" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

Jdta.propTypes = {

}

Jdta.defaultProps = {

}


export default Jdta;
          