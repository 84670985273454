
import React from "react";
import PropTypes from "prop-types";

import "./mjdeffector.css";

class Mjdeffector extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="28c31c2d-9551-4796-bd5b-5bc5287568c6" className="mjdeffector">        <div data-layer="43d3eaae-3aa7-4655-9d76-318392e304fd" className="layer1">            <div data-layer="5247846c-82b4-45c3-9d03-1b37cfaa01ce" className="thinkSmartStudyGamesIp2024Rpg">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2024년, 스마트스터디게임즈는 신작 RPG 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="6a6d6c11-3fd1-43b4-a6c5-50b50713d9ce" className="x10981">                <div data-layer="0e2e8752-7a50-4bca-a7f5-4580faadad0e" className="xe6e8ae32">서류 전형</div>
                <div data-layer="fcfea1d6-db5d-4ef2-a2f7-d2623aed81df" className="x65df4cd2">실무 면접</div>
                <div data-layer="76c89a00-6ae8-43b4-b6cd-bdd26cb30e1b" className="xc0ea695a">최종 합격</div>
                <div data-layer="0cfce1d8-2aa8-4f10-a67a-afa5fa743dfd" className="x10980">                    <div data-layer="05ff9f52-afbb-4925-ac06-0f2e0af88784" className="x10979">                        <svg data-layer="4e90d555-6854-4601-89f5-8a13c124902e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="9f82f49f-24d3-473a-9cca-fafdb1dfbe7b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="51a8e470-b9c7-460f-b580-175b90235e7e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="c5a18b07-4512-4eea-ab37-4baa587a84a7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="f061e840-203b-48bc-a025-34937cc015e5" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="31e2d72f-0132-49d0-93aa-6ce33652764f" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="9814a529-0e0a-439f-96b4-1dd865d87080" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="43d1cfcb-2291-44eb-9139-88813038336c" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="6da67f47-fefc-4003-af42-4f52de3ae637" className="xf60a65fa">전형 절차</div>
</div>
                <div data-layer="7d4768e2-f3fc-4c9a-be08-d976e03a856c" className="xac07f5f0">임원 면접</div>
</div>
            <div data-layer="1e626997-f511-4996-9037-f34625717443" className="x10983">                <div data-layer="761528bc-6f9d-4056-811a-3035abf91389" className="x33051f0d"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="019f0937-9ecb-4c75-be4f-b2fdb031b048" className="x7f71cd4f">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="e3412c13-a1b7-4ed4-b7f2-5b28c17b6d66" className="xfa58031e">지원 방법</div>
                <div data-layer="35093169-0ecb-42fb-ae0b-460efa811527" className="lines86ae4f3b">                    <svg data-layer="b5cdd6bc-5b94-4770-85a7-a6cba6435f07" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14851eebe31"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e5e1acba-7be4-4796-b03b-ffbc4cd6a4f1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142c327231b"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="4fde078e-42e3-4933-b540-64bf40234e22" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149d1a5b5ef"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="ab9718a7-2c04-48d5-ad6a-abd594b34912" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143d1a64af6"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d82cee3e-d1dc-4fd4-8d9e-3cedeaff62d9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144674296d5"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="f7b8deaa-ecb6-426d-84c2-36c5a300bf0e" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="6a24c648-ed1d-4952-8cd2-433f1fa93d6d" className="x10987">                <div data-layer="ea3992d1-4b0b-46ce-adf5-3960f3ddd4c3" className="x3Unity">관련 업무 경력 3년 이상<br />Unity 엔진에서의 이펙트 제작 경험<br />카툰 스타일 이펙트가 포함된 포트폴리오<br />자율과 책임기반의 자기주도적인 분 <br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="bef05ecc-9d00-4cb4-8c13-456b15a7cce7" className="xdaabd023">자격 요건</div>
                <div data-layer="92a26efd-5240-48c0-b495-e8c007809f90" className="lines966837f2">                    <svg data-layer="afe7aee8-67af-4488-a778-e6187eb7a7d1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1487794fd5d"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e16047dc-5524-416e-9f07-e8bb8bec57c1" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1426d206493"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="319d6978-b042-4d34-8240-d6d99cb877fa" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149614ef20a"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="439aaf54-3999-43b7-aa7c-1ccd32722be6" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1432dab321d"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d05906ca-db42-46b7-be41-2814675eea6a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1447d37a9a5"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="d673e988-3ddb-4441-9d94-18332c5e73c9" className="x10985">                <div data-layer="9047adb1-6129-4a76-bb66-50a4de693f60" className="xd6deb82e">최초 개발 단계부터 런칭까지 경험자<br />이펙트 리드 경험</div>
                <div data-layer="47fa667b-7fdc-46d9-bb30-ce8ccf4ecbc1" className="x4bad9028">우대 사항</div>
                <div data-layer="c933e278-2809-45e5-8298-e3b831e10a4e" className="lines07685959">                    <svg data-layer="783db594-8b38-44e0-8817-9d07aa285f88" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14843023176"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="a0f592db-daf4-4cd8-9bd9-c003f39e976d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142149b33c4"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="615d2a0e-4d3f-49d2-9575-d3d3023877e2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1497be89d9b"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="2d65adb0-5201-408e-8e12-36ef52638a83" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x14303a33395"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="6af8d026-4af3-47b8-9b94-2e33118a0b1b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1449ae39a6e"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="cd9fa789-b93e-4bad-982c-6ccbedd53f94" className="x11012">                <div data-layer="14843be9-24d9-43c9-b60c-8edee1c7c51e" className="xL">글로벌 신규 프로젝트 L의 이펙트 제작 업무</div>
                <div data-layer="0ff56b23-d7f9-4d4d-98b1-9a6c6d5d25b0" className="x">주요 업무</div>
                <div data-layer="d97e57bc-97c4-4153-8108-f0cf33865349" className="lines">                    <svg data-layer="3c2864a2-bb60-4381-9208-dcb60c9b35fa" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e7b47cbf-c7dd-49ed-a6c3-cdd9019356ce" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="6373afc9-be7c-4394-89a2-6411f94d0fe9" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="68bc9ba8-2a40-43ec-b9bf-64d46f5f88d6" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="5cc165a0-0956-4047-83ef-8832a78ed149" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdeffector.propTypes = {

}

Mjdeffector.defaultProps = {

}


export default Mjdeffector;
          