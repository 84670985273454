
import React from "react";
import PropTypes from "prop-types";

import "./jdgameDesignerBattle.css";

class JdgameDesignerBattle extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="b8e3c994-56c0-4c15-8408-3d5369e5a8f0" className="jdgameDesignerBattle">        <div data-layer="ad39a55e-03d5-4633-89e4-bd8b0c102246" className="layer1">            <div data-layer="c478f806-7a6f-4adb-8ae6-50fdda02980a" className="thinkSmartStudyGamesIpRpg">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />현재, 스마트스터디게임즈는 신작 RPG 게임과 라이트코어 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="6c1b5563-eaee-4972-9278-0f0b2df0d637" className="x10981">                <div data-layer="a5da949c-242e-4507-beff-aa4827de7f2b" className="x54583fac">서류 전형</div>
                <div data-layer="fd2efc43-b8a5-4330-bf7f-8d8ba8755dda" className="x3420803d">실무 면접</div>
                <div data-layer="f7c54cdb-ab8a-41a1-9e8c-126e13d8dd88" className="x7f1dd11e">최종 합격</div>
                <div data-layer="5094b851-fa0f-4cbb-ab87-397cd5956979" className="x10980">                    <div data-layer="5e9ff2da-f00b-4b56-a10e-180dcd1c2651" className="x10979">                        <svg data-layer="6b639e54-30c3-4f0e-8ef6-1cbd7682f5d4" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 896.958984375 3.18994140625" className="x151"><path d="M 0 0 L 893.7677612304688 0"  /></svg>
                        <svg data-layer="d2c4bf71-aec2-47c1-9229-22325064c876" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x126"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                        <svg data-layer="9ff91446-737e-40c7-bc14-e78c42742e71" preserveAspectRatio="none" viewBox="-1.59765625 -1.59716796875 356.60546875 3.661865234375" className="x150"><path d="M 0 0.467529296875 L 353.41015625 0"  /></svg>
                        <svg data-layer="8ab9938a-a50e-4d1f-bc1f-7db219c98388" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 80.4296875 3.18994140625" className="x127"><path d="M 0 0 L 77.23847198486328 0"  /></svg>
                        <svg data-layer="221fb91a-8a14-45e0-9c85-baeb7d1be119" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x239"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="e7f7aec2-0100-475c-83ed-e6cec0a38988" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x240"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="60eeaf3d-a1f4-4860-b5aa-e19ac4ddb576" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x241"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
                        <svg data-layer="e63df643-2c91-400c-85c4-23ceba6d4d78" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 27.73421883583069 27.73360848426819" className="x242"><path d="M 11.4743013381958 0 C 17.81138229370117 0 22.9486026763916 5.137219905853271 22.9486026763916 11.4743013381958 C 22.9486026763916 17.81138229370117 17.81138229370117 22.9486026763916 11.4743013381958 22.9486026763916 C 5.137219905853271 22.9486026763916 0 17.81138229370117 0 11.4743013381958 C 0 5.137219905853271 5.137219905853271 0 11.4743013381958 0 Z"  /></svg>
</div>
                    <div data-layer="386d4524-7886-4a92-ab90-668fb5acc236" className="x82a98e17">전형 절차</div>
</div>
                <div data-layer="f649b924-7dce-4caa-b41c-26702991ace0" className="x53911278">임원 면접</div>
</div>
            <div data-layer="c01390d7-b31b-4743-9bb6-52db88263323" className="x10983">                <div data-layer="e16db38a-2407-4cbc-be25-c4a437a75ed2" className="xa3558947"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="bee8bc75-43ee-482b-baf1-432a7b09abc5" className="x20c0c6e9">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="7c0c4b69-192e-47bf-ae0c-25aef0f6772f" className="x0e34fd67">지원 방법</div>
                <div data-layer="d4a567f4-043f-427d-8cc5-131561ad3848" className="linesd4a80070">                    <svg data-layer="0d1bc59d-86f7-4ea1-97f0-7c3e33f39835" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14879a7c1bd"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="0c6dfdea-6add-4b1c-bb83-a34f81174cbe" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14298e8ab43"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="2e377237-9b41-4db4-b881-b2558d8c2fed" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149c1f3acf9"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="93445308-3d51-40de-84c6-3e8a777bf1db" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143df23956d"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="45f6b700-bd66-4908-a54e-dfed0f90f5b7" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144f61624ec"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="6b0b7689-4234-4138-a35b-cd643146c3b0" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="5344d5a1-8095-4ba1-8487-0a223c1d4eba" className="x10987">                <div data-layer="347bebe2-91f4-4b41-a514-25f015ba59aa" className="x5Rpg">전투 또는 캐릭터 기획 업무 경력 5년 이상<br />전투 시스템 또는 전투 콘텐츠 기획 경험<br />모바일 수집형 RPG 게임에 대한 이해도가 있으신 분<br />구체적인 업무 내용이 포함된 포트폴리오<br />자율과 책임기반의 자기주도적인 분<br />원활한 의사소통과 협업을 중요하게 생각하시는 분</div>
                <div data-layer="9cc2b5c5-ef5b-473a-9041-0deb06b317e1" className="x8f5bb826">자격 요건</div>
                <div data-layer="16d2aeed-2ff3-41ab-8d33-accba1cbe288" className="lines3cbaa2a8">                    <svg data-layer="32e981b4-fcf3-4b87-a03e-244c31464887" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1487ebb1ae1"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="c3607cc8-3aa5-4e66-9975-187d64a312f4" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142d9af645e"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="ca7ef45c-2ddf-4654-81ae-e2b8e0be7423" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x1499979d14b"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="1243e453-269b-4913-95bb-81dc3dd7f3fe" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143f1315f2b"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="98d10186-5c74-457b-a6fe-86a3ea3720c2" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x1442ca93b02"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="ff1faa81-61de-471a-9cfc-1179641987b5" className="x10985">                <div data-layer="b9726e9b-ec9c-4e23-9e64-fa5af8d8911e" className="xRpgRpg">전투 레벨 디자인 경험<br />성장 밸런스 설계 경험<br />최근 출시한 캐릭터 수집형 모바일 RPG 게임을 다양하게 플레이<br />모바일 RPG/전략 게임 라이브 경험자<br />최초 개발 단계부터 런칭까지 경험자</div>
                <div data-layer="84148a78-4633-429f-989f-2eaf372d31dc" className="x7a66fa01">우대 사항</div>
                <div data-layer="5f2d2c96-76f8-4602-a711-5fc812f94d43" className="linesd9346f28">                    <svg data-layer="5160eedb-9cc7-4c89-9e1a-5e5a0c58d640" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x14892d98eb8"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="31cd6b9b-0393-4ac2-bb3d-61e0ee822e2d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x1421259b884"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="9f15c5b1-c214-4160-bc85-92c0fd9d1e51" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149a37654e2"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="74b8b9f6-e714-4ddf-a971-63a847099691" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x14342fb840b"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="1f45a3f0-9c62-42ae-8b74-ebc3dbb44a07" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144b724028b"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
</div>
            <div data-layer="1d0a43d1-32b8-4ea1-82bc-656cbc485782" className="x11012">                <div data-layer="eb5b15f4-f34d-4ff7-8f3e-7bef749704b7" className="xRpgIp">캐릭터 수집형 RPG 기반 실시간 전투 시스템 및 전투 콘텐츠 기획<br />몬스터 슈퍼리그 IP 기반 캐릭터의 설정 및 스킬 기획</div>
                <div data-layer="4e1f7ea7-08dd-4a4e-9ea0-021da4275442" className="lines">                    <svg data-layer="c1323888-6d64-4df6-a851-83af8b50df4c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x148"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="6bf8c5c4-e01e-4056-8c45-15eb3a3dbb2c" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 248.837890625 3.18994140625" className="x142"><path d="M 0 0 L 245.6466217041016 0"  /></svg>
                    <svg data-layer="5bf04cb1-932c-48cd-9b52-91a350f36f82" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x149"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="cc278992-7c49-48bf-8330-82bd24ae9b7a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 310.4921875 3.18994140625" className="x143"><path d="M 0 0 L 307.3019714355469 0"  /></svg>
                    <svg data-layer="6539cc60-0f4c-46af-b82d-fbfd892d3f38" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 70.80859375 3.18994140625" className="x144"><path d="M 0 0 L 67.61768341064453 0"  /></svg>
</div>
                <div data-layer="2fd5b06b-d290-4a62-8b12-073f97ffd6f9" className="x">주요 업무</div>
</div>
</div>
</div>

    );
  }
}

JdgameDesignerBattle.propTypes = {

}

JdgameDesignerBattle.defaultProps = {

}


export default JdgameDesignerBattle;
          