
import React from "react";
import PropTypes from "prop-types";

import "./mjdprogrammermsl.css";

class Mjdprogrammermsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="8c0758ce-5762-4e95-ae98-986a31bff3fa" className="mjdprogrammermsl">        <div data-layer="610d48ff-adfd-49cf-a352-cce1d298212c" className="layer1">            <div data-layer="10e4e8b1-9e87-4a03-b45b-f8811212fb76" className="thinkSmartStudyGamesIp2022RpgPvp">"Think Smart Study Games!"<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="aa67a718-fc4a-492d-a0dd-ba1af3b4b029" className="x10981">                <div data-layer="38392471-9874-4f31-8cfd-c87f96d814e3" className="xc4c8caa1">서류 전형</div>
                <div data-layer="48ac6a55-0f52-4151-a791-50c3da13db4f" className="xf2a36ce6">실무 면접</div>
                <div data-layer="9b41bf31-cdc6-4096-a32d-b456bf2000b3" className="x7664ff20">최종 합격</div>
                <div data-layer="da042bea-e2a1-40e6-ba94-471b44e04bce" className="x10980">                    <div data-layer="a10bac2f-0ceb-4e1c-bf23-a6adf6805aec" className="x10979">                        <svg data-layer="83f3feb9-516e-4462-a62b-d3d34241ebd4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 501.189453125 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="0e64ddf0-29d0-4bea-a8cc-1dde78caf5d4" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 126.189453125 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="2178b1ba-3794-4a91-9239-86926326a755" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 213.189453125 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="e68059e0-a11d-431e-99ef-3eb4d101bc6e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 53.189453125 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="a49d7f3f-c3d8-4a48-84a6-62ba33d2a085" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="e0dfa3fb-d75d-45d1-af0a-1f6a884df28f" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="bacb5cbf-d564-44f6-88ac-1dca2a06323b" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="6faee70b-8953-4de4-94fe-6842c8bd308f" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="978b0b36-e3cf-4d4e-ad87-852349b61cbe" className="x5eacdff8">전형 절차</div>
</div>
                <div data-layer="46faa5dd-69b6-46f7-90b4-16d4cee87bb0" className="x1aad93ba">임원 면접</div>
</div>
            <div data-layer="54bc1198-83d0-42bf-b55d-43e2e591d443" className="x10983">                <div data-layer="b9d0ed68-ddcf-460d-801a-2d36ba70a9f4" className="x913601bb"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="b612b603-b0ec-4509-8e57-cf13dc78549f" className="x14d27f3a">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="a5537f0c-3890-4677-86bd-ed48f87838ed" className="xd5150535">지원 방법</div>
                <div data-layer="bfbd11c0-7f2d-429f-997b-cbf8af43f815" className="lines1a534f74">                    <svg data-layer="a495daaa-529d-4740-8ddc-a0c1211a808c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x1485596e8d6"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b29ee823-7774-4189-9b08-774c4f49908f" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x1422a4c437f"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="91e3011b-6113-4a8e-97fb-49507ee662ea" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1494a330bf3"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="95957d1c-9a53-407d-a887-3fb76abd60e8" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x143e361df20"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="01550a4c-9bc2-4271-b399-99ec11691887" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x1449e8f13cd"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="381e1191-3365-45dc-a020-502d21da2d47" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="f02a007a-d8df-4aa8-918e-f5e807a3bf31" className="x10987">                <div data-layer="a22506ab-34f6-4a6b-974a-2342052524a5" className="x3CC">미드레벨 이상의 업무 능력 / 3년 이상의 모바일 게임 개발 경력<br />C++, C#, Python 언어 중 하나를 능숙하게 사용하는 분<br />개발에 대한 열정이 있고, 문제 해결에 재미를 느끼는 분<br />꼼꼼하고 책임감 있는 분<br />새로운 언어, 환경에 대한 두려움이 없는 분<br />팀 단위 작업 및 타 직군과의 협업에 대한 경험이 있는 분</div>
                <div data-layer="02d9afa1-8ea4-4dd0-94b5-8958e55237ef" className="xe94c67c6">자격 요건</div>
                <div data-layer="0ea7e73a-1ae2-43cc-a9e0-ea411eb56515" className="linesb2ab799b">                    <svg data-layer="53f65ce9-a1cb-480d-8496-41c0915803c5" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x148274e3aec"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="9b8397cd-a696-4ed3-a2b4-a271c7d9f280" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x1429c001b6e"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="33bb1502-df30-4f36-b2f7-992b1a932cb3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149433c8c64"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="bc319317-6b52-48be-a65b-548414510490" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x1435a51531e"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="ec809f4a-b16a-402f-8fef-e52ae8454267" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x1443ab31bea"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="7218bef0-a077-4706-8249-051bab519de3" className="x10985">                <div data-layer="58ff89ef-88fd-4d17-bf91-04a732ede860" className="unityAndroidiosDbDbAwsGraphics3d">Unity 또는 기타엔진으로 서비스 오픈 및 유지보수 경험<br />모바일(Android/iOS) 플랫폼에 대한 이해와 개발 경험<br />모바일 상용 프로젝트 라이브 서비스 경험<br />서버 개발 경험 및 DB 최적화등 DB를 능숙하게 다룰 수 있는 분<br />AWS 사용 및 실 서비스 경험<br />Graphics 및 3D 경험이 풍부한 분<br />각종 개발 및 형상관리툴 이해와 경험<br />해외 서비스 경험<br />하나 이상의 외국어 커뮤니케이션 능력</div>
                <div data-layer="199b709c-36ca-43b7-993d-f6ff892eb206" className="x31648fa5">우대 사항</div>
                <div data-layer="c97904e1-91e5-4087-9d70-dffa149a620a" className="linesb779b880">                    <svg data-layer="341e50fb-2607-4315-8eeb-248137bb4f6c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x148814e3c6c"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="ce12dd85-42f5-4c87-8149-319523b6641d" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 143.189453125 3.18994140625" className="x142517bb496"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="7eb5d39e-aac5-4570-842d-6c1afa997c9e" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x149b8bdbfad"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="b94c3066-257f-45b7-80ee-846657cfb71c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 173.189453125 3.18994140625" className="x1435ec959b7"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="7c02deb2-f7d6-4508-9c3a-fc3257a1f1d7" preserveAspectRatio="none" viewBox="-1.5947265625 -1.594970703125 47.189453125 3.18994140625" className="x14407a8371d"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="cde7a212-b6e9-405d-a23d-2175ac95888d" className="x11012">                <div data-layer="dcb56a43-cdca-4dc7-858c-1c609294fbb5" className="xAndroidiosRpgUnity">글로벌 서비스 중인 모바일(Android/iOS) RPG게임 몬스터슈퍼리그의<br />클라이언트 및 서버 개발 (Unity 기반 어플리케이션)</div>
                <div data-layer="c19c2f6d-78eb-4acb-a580-847ae056cc79" className="x">주요 업무</div>
                <div data-layer="74f09b9c-dfb4-4db1-aad2-223c88b033c7" className="lines">                    <svg data-layer="e9becd30-cd3b-4981-abfc-f9326a831e4c" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="9794879c-5912-498f-9c57-b4efad8376c3" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 143.189453125 3.19000244140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e4c0c41b-a8b0-4310-b510-755c0945d93a" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="6cf842e4-285f-477d-a14d-e2c6ed35e145" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 173.189453125 3.19000244140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="c0b18fa5-6dd5-41e7-8e94-0f318cc95473" preserveAspectRatio="none" viewBox="-1.5947265625 -1.595001220703125 47.189453125 3.19000244140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdprogrammermsl.propTypes = {

}

Mjdprogrammermsl.defaultProps = {

}


export default Mjdprogrammermsl;
          