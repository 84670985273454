
import React from "react";
import PropTypes from "prop-types";

import "./mjduimsl.css";

class Mjduimsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="1cf1d88a-1c92-4af0-a5ad-20f5b4889030" className="mjduimsl">        <div data-layer="97bc359e-771a-40fb-b07b-3304b976db8e" className="layer1">            <div data-layer="312f37cc-2a51-41ff-ad95-24a0329f760e" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="ad89f0b0-662a-41e2-ad50-de5c4451a71d" className="x10981">                <div data-layer="54cde765-06a5-455f-b76d-b822353c2491" className="xab569043">서류 전형</div>
                <div data-layer="afc8574f-f557-44db-bf3a-7d77ba0c8022" className="xf7276dad">실무 면접</div>
                <div data-layer="00666cc4-5f1a-4a14-a875-2ec614cd6ff8" className="x75c32cfe">최종 합격</div>
                <div data-layer="ab3ccffe-3f72-4d53-9744-27b2d93530a7" className="x10980">                    <div data-layer="3e8c721d-ab39-4b21-a0fe-9dc91c00d070" className="x10979">                        <svg data-layer="7f8ea443-1e2b-4401-b05f-1f5a39d28766" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="ed476201-5beb-4b7e-957d-25b2804b57af" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="5651b7b7-83c8-4b77-af9d-18c87330cb2e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="4afb4b0e-3b40-4e4b-bd89-cbb11d5a8098" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="94bdc4d7-aa9b-4adf-a7bf-7a09855b65a2" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="62875969-8f3c-4b47-b72b-192f4367e73c" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="fd8f412e-b15e-428e-93f0-e7507c82e2a3" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="6d71ecd7-3273-4058-b4fa-e910e76695ad" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="cb9716fe-f0b0-40ad-b54f-8643f85c19c3" className="xc7db240b">전형 절차</div>
</div>
                <div data-layer="3524a1ce-0fbc-4ab8-9991-eae432ba9ae7" className="xcfd715c5">임원 면접</div>
</div>
            <div data-layer="afb544a2-db01-44e6-a5a6-c20af03be1ad" className="x10983">                <div data-layer="a1053cce-451d-4e8f-82c7-3900c493a05c" className="x37b2e562"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="edd5298e-fb93-44bd-ab26-afb092155f41" className="x9c2e8014">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="a070baaf-ea37-414a-93b1-88afffb2c3b9" className="x20726eaa">지원 방법</div>
                <div data-layer="40faa53d-67c3-4806-83bf-4f367d7da681" className="lines8a404f0b">                    <svg data-layer="297d33fa-d745-41df-aac0-466762fa5d84" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14865e3d431"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="c0982fb2-e3bc-474d-b67b-6db98fc49771" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142ad6dd474"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="9917180a-92b5-4c00-b3d5-39e2baf5348d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149c81624dc"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="8dcb0215-4e2e-4f9b-b936-b92480ef0d72" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143853df01e"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="db121aaf-8590-4c57-b49b-719eb9d5c51b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x14449b0c0d9"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="af58517e-015c-4892-b04e-c6103378908f" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="337b4920-e71f-4f18-bc32-4436ba87fd03" className="x10987">                <div data-layer="5e7e3853-9465-4a33-b516-142775e20652" className="x1UiUiUi">1년 이상의 모바일 게임 UI 디자인 경력<br />그래픽 툴 및 유니티등을 능숙히 다룰 수 있으신 분<br />UI 디자인/설계에 대한 이해가 있으신 분<br />꼼꼼하고 책임감 있으신 분<br />UI 리소스 드로잉 및 이미지 편집에 능하신 분</div>
                <div data-layer="e3620148-e537-4257-9df1-b31f3c5660d1" className="xcf23b98b">자격 요건</div>
                <div data-layer="a186ecd9-04c6-4ce8-a854-15082a987d9b" className="linesbb4f9a38">                    <svg data-layer="668d520d-bc3b-45be-bfee-b1a6a513e976" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x1481365280a"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="aa4bf5a4-4224-485e-b95d-50ee01b0f942" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x142610cc6c6"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="ab482647-7497-470b-a99e-fd664010acdf" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x149c4c29041"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="a16beec3-51bd-4b63-b2f3-166d030175b6" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x14394e08dde"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="9eed4fca-b2ba-4aa9-b4ce-80bc3dbdc333" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 47.19140625 3.19000244140625" className="x144a789001a"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="0895550d-774f-4fb2-a99e-ea1be272128b" className="x10985">                <div data-layer="c52259d2-81c3-431a-bd25-e4cc19a63bff" className="xRpgUiUnity">캐주얼 RPG의 UI 제작 경험 우대<br />Unity 사용 경험 우대<br />모바일 사용 프로젝트 라이브 서비스 경험 우대<br />원활한 커뮤니케이션 능력 우대</div>
                <div data-layer="abfb5a0f-a324-439e-90cd-ef800fbadc3c" className="x68dd7171">우대 사항</div>
                <div data-layer="09f02fde-ba8e-40b9-9991-31408d54fbea" className="lines1a71129d">                    <svg data-layer="9455bc56-ab50-4122-943d-079c7bcd5280" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x1489795dd87"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="3c074d48-62df-4e63-a827-421d2e412fd7" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x1422ab324f4"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="aeb4c602-c0d0-4c0f-b8fa-ad2ce7c21438" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x1498ba07323"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="ffd990e8-25bf-49e2-9848-209cf544034f" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x14377f3f5b7"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="3d976d9b-6672-45e0-95cf-4a93c6620759" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 47.19140625 3.19000244140625" className="x144d2a71a82"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="fb3d74bb-cf70-4016-b799-95f73e40fbc5" className="x11012">                <div data-layer="5dde50db-df65-48e3-a50b-410902e6027c" className="xRpgUi">글로벌 원빌드 수집형 RPG ‘몬스터 슈퍼 리그’의 UI 디자인 및 제작</div>
                <div data-layer="1b7f0b1d-05ce-4036-b174-4b43791d179a" className="x">주요 업무</div>
                <div data-layer="be163d9b-5c8a-48e5-aa2b-a41df7fc306a" className="lines">                    <svg data-layer="bb7735d8-aa52-48ec-a692-2666306ddade" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e18a1aa0-a695-4c43-a73d-67d491235b59" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 143.19140625 3.19000244140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="6ddd3127-3ee3-4fc6-8b73-da19a5483b8e" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="3c9d5564-dcba-4c97-bd73-6094aee01fbe" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 173.19140625 3.19000244140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="e2e50e7d-69eb-4a7f-85a7-7cd2b89de65c" preserveAspectRatio="none" viewBox="-1.595703125 -1.595001220703125 47.19140625 3.19000244140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjduimsl.propTypes = {

}

Mjduimsl.defaultProps = {

}


export default Mjduimsl;
          