
import React from "react";
import PropTypes from "prop-types";

import "./mjdgamedesignmsl.css";

class Mjdgamedesignmsl extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="2becf099-39c6-4e80-b60b-3911a71a9e26" className="mjdgamedesignmsl">        <div data-layer="87806b59-d69f-4305-97ca-a2ea1e908705" className="layer1">            <div data-layer="87dc1cb2-f4a3-425a-a842-b52b4a16d40f" className="thinkSmartStudyGamesIpRpg">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />현재, 스마트스터디게임즈는 신작 RPG 게임과 라이트코어 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="1be28882-dea7-42d2-b9d9-356331c319e8" className="x10981">                <div data-layer="32e4ed9c-41f9-4e71-9ee6-1e4668b5b9eb" className="xe08cb4fe">서류 전형</div>
                <div data-layer="4f210a34-3df5-43ed-b67f-fec08c515795" className="x70049a71">실무 면접</div>
                <div data-layer="1950c15a-1ff9-4964-9030-d992db0ea441" className="xaf190942">최종 합격</div>
                <div data-layer="885f3956-51b6-41c5-957d-5dd1bc367239" className="x10980">                    <div data-layer="8b76c091-764d-4c48-abfc-18080cc35136" className="x10979">                        <svg data-layer="e2b3cb4e-cc41-44aa-a6fb-7299a9a9291e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="d20171c8-0187-4709-9b89-d1bb160a811b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="e82f4897-b5ad-4cca-a181-0ef0c4af1a8f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="f9b8b01d-8189-48ee-9a0a-73ce1b993202" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="cc70e464-80da-4455-b964-ba330c66df49" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="fe676a58-aef1-488e-9ebb-99bfd05359c8" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="ec22df05-85b6-4659-99a7-d608ce236d00" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="a932c412-3d12-428c-8afc-c564505a4d09" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="253ef65c-32d6-4a31-889c-6bf66aba2517" className="x508d55dc">전형 절차</div>
</div>
                <div data-layer="f62f1c35-1e1c-4561-9b65-1639401c9b91" className="x27f3d38d">임원 면접</div>
</div>
            <div data-layer="621009f3-768f-48ae-a372-0f496892d671" className="x10983">                <div data-layer="56d7de4c-d8a4-4e3b-afd2-903f1a6f13ea" className="xf979bfa9"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="a5aeadbb-bc80-435f-891b-61f38211b4e5" className="xb4b03eaa">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="a828501b-7eee-4019-8b3e-903b5d6ba498" className="x489dda96">지원 방법</div>
                <div data-layer="b33e2bcf-4934-4d74-a814-a1abfa4ef0fe" className="linesbc977b3b">                    <svg data-layer="29515e2d-1433-4bf0-9cf3-e52bbcc4470b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1482b9194fa"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="8ad07f8e-1916-4017-8737-a6f1157c288b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142b5ae9e56"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b6e712e6-c814-40e8-b82a-f4361fa66965" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149ae30a6a8"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="35a3e9d7-446a-4261-a7bb-13f793fcb2b4" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1437054f282"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="72d26988-eac7-4c65-919c-116c0092f790" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144e69883d9"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="bc874141-c702-4475-bb99-dfe8f0f840de" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="c5ca8da9-6dc6-41aa-97a8-0a9996e6c91b" className="x10987">                <div data-layer="80aa709f-d210-4eff-b3d8-ec8a5bd2d473" className="x3ae81aca">스토리, 콘텐츠 기획 경험이 있는 분<br />환경 및 우선순위 변화에 빠르게 적응 가능한 분<br />팀 중심의 적극적인 커뮤니케이션이 가능한 분<br />문제 해결 능력 및 분석적 사고를 가진 분<br />구체적인 업무 내용이 포함된 포트폴리오가 있는 분<br />자율과 책임을 기반으로 자기주도적으로 일하실 수 있는 분</div>
                <div data-layer="1b1f7be4-5ebe-4729-9e1b-0b7f9f26e87f" className="x346a3122">자격 요건</div>
                <div data-layer="b18c4681-0a34-4298-86c2-f8bf3bf52964" className="lines1ac81091">                    <svg data-layer="1baf6fa4-897a-4b28-99ba-166bcd8a997a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1489eacb515"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="d32881b9-15e3-498e-8e88-549f2ee0b16d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1428e83bbe7"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="855e967b-fd02-4955-9840-e872b45bce3b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1499a353020"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d48f4ed0-1142-4270-baa8-37f800f84f45" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x14376294812"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="cef26700-1e08-4f21-a4e3-67e4bfbde921" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144875d0d79"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="78ded690-bef5-402f-a3de-26dd4dc17122" className="x10985">                <div data-layer="c2a34a6c-287e-4f65-90b3-6a9b45485031" className="xRpgJiraUnity">몬스터 슈퍼 리그 플레이 경험이 있는 분<br />웹소설 작성 및 독자 피드백을 받아본 경험이 있는 분<br />모바일 RPG 라이브 경험이 있는 분<br />글로벌 게임 서비스 경험이 있는 분<br />JIRA, Unity 사용 경험이 있는 분<br />온라인 게임 산업 및 게임에 대한 관심이 있는 분</div>
                <div data-layer="0e03ebe2-5c23-447a-8479-3b2e5d4ea1af" className="x81e58a9b">우대 사항</div>
                <div data-layer="cb3afd7e-60df-4793-b0d6-31e926eb651c" className="lines8cb5ce1d">                    <svg data-layer="73c23c3e-c80f-4817-b1a2-4dda944e2286" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148cc8ca68c"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="35bb4446-d8d5-446e-aab8-c93b7492fb3b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1425fda4325"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="80284ad6-c6dc-4d14-be58-8960dbc167a3" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149721bba01"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="359e9ca8-6711-44e0-907d-324ba8a46040" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143ab471c41"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="570c81db-5e60-496a-b125-ac25fdee54ce" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144e2ffae34"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="35c3fbc0-edeb-4de4-80c5-65265b4a4e3c" className="x11012">                <div data-layer="01b22f48-e34c-4eb9-9b67-a06f9725af49" className="x9a2a0e2f">스타몬 스토리 및 시나리오 기획<br />콘텐츠 및 이벤트 기획<br />번역 관리<br />라이브 서비스 관리<br />데이터 관리 및 작성<br />업데이트 대응 및 모니터링</div>
                <div data-layer="1cd9239a-ee1d-4604-92e7-b4dce7143213" className="x">주요 업무</div>
                <div data-layer="6627f4ad-6640-4d78-96c3-414e56f7384a" className="lines">                    <svg data-layer="8d61dfc4-2266-41a1-b7f5-f9f84ba56d22" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="8129d481-1bfe-472b-b608-bbdbc58ef44d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="a23f8f55-3a68-4e27-a9b6-e06f470bf7fc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="b4a79a5a-bce9-4dff-8282-6d358a29fba8" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="de86650b-01b4-4c9d-bf63-85d72a2d1539" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdgamedesignmsl.propTypes = {

}

Mjdgamedesignmsl.defaultProps = {

}


export default Mjdgamedesignmsl;
          