
import React from "react";
import PropTypes from "prop-types";

import "./mjdmobileui.css";

class Mjdmobileui extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  render() {
    
    return (
          <div data-layer="b717a64e-cf70-4140-a100-367344381e9d" className="mjdmobileui">        <div data-layer="71ffa7b1-dc8b-4827-8f68-84b2d4bb2ee6" className="layer1">            <div data-layer="bbd05f37-c6a8-4ec1-ba40-cca54190b285" className="thinkSmartStudyGamesIp2022RpgPvp">“Think Smart Study Games!”<br />스마트스터디게임즈는 글로벌 콘텐츠 기업 더핑크퐁컴퍼니의 자회사로, 핑크퐁과 아기상어 IP를 활용한 캐쥬얼 게임과 더불어 몬스터 슈퍼 리그를 서비스 중에 있습니다. <br /><br />2022년, 스마트스터디게임즈는 신작 RPG 게임과 미드코어 PVP 게임 프로젝트를 진행하고 있습니다. 저희와 함께 글로벌 게임 시장 진출을 목표로 하실 분들은 주저없이 지원해주세요!</div>
            <div data-layer="be2b23fd-9ad5-4761-aa79-018fc7f443ef" className="x10981">                <div data-layer="a9eec113-c242-457d-bfc5-470bedc837cf" className="x61aa9acc">서류 전형</div>
                <div data-layer="bdb3950e-2720-4380-a2fe-a2b0c0fbfdd3" className="xad7aad93">실무 면접</div>
                <div data-layer="b2eaff44-f0f1-4ae5-ac2e-3fd55731425a" className="x6db9190c">최종 합격</div>
                <div data-layer="04ecf67b-56cc-4fde-8b1b-3c192c273d2d" className="x10980">                    <div data-layer="7880aba6-c72c-4a96-9990-05b18f2db75d" className="x10979">                        <svg data-layer="0d5a6cbd-5eb5-4056-bcde-8428e684cc81" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 501.19140625 3.18994140625" className="x151"><path d="M 0 0 L 498 0"  /></svg>
                        <svg data-layer="ecc84074-2090-48dd-98e4-d459b0ea0272" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 126.19140625 3.18994140625" className="x126"><path d="M 0 0 L 123 0"  /></svg>
                        <svg data-layer="38ea4600-52d4-4fde-ac9f-a74bbf07d2ea" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 213.19140625 3.18994140625" className="x150"><path d="M 0 0 L 210 0"  /></svg>
                        <svg data-layer="2f1c50d6-03a0-47b1-bdfe-a47fc2210345" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 53.19140625 3.18994140625" className="x127"><path d="M 0 0 L 50 0"  /></svg>
                        <svg data-layer="2c545f86-c9d3-4b8d-a301-57fc71b928fb" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x239"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="95ecd9f1-4a39-45a1-b37a-20ec8a03d172" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x240"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="41050b99-722b-4af6-b44a-0fea0b673254" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x241"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
                        <svg data-layer="b188e062-73c9-4191-a85c-82c816fa9d64" preserveAspectRatio="none" viewBox="-2.3925000429153442 -2.3925000429153442 19.78500008583069 19.78500008583069" className="x242"><path d="M 7.5 0 C 11.64213562011719 0 15 3.357864856719971 15 7.5 C 15 11.64213562011719 11.64213562011719 15 7.5 15 C 3.357864856719971 15 0 11.64213562011719 0 7.5 C 0 3.357864856719971 3.357864856719971 0 7.5 0 Z"  /></svg>
</div>
                    <div data-layer="4b05c9e8-78bf-41a9-88dc-e23590541381" className="xb57cb197">전형 절차</div>
</div>
                <div data-layer="a94350b1-4cf9-42fe-86bb-5eeeb39b02d0" className="xa83f9cca">임원 면접</div>
</div>
            <div data-layer="5af30929-b88a-4d3f-99eb-8af9437feee2" className="x10983">                <div data-layer="f8ddcb94-38f9-486e-88c0-bbb98e99d82c" className="x3f9fd1a5"><br /><br />개인 정보는 최소한만 적어주세요. 주민번호도 적지 않습니다.<br />담당했던 업무와 역할, 사용 기술을 중심으로 작성해주세요.</div>
                <div data-layer="1c04f2c6-00cd-49be-9614-502d7549e6b8" className="x7883a54c">지원방법 : 홈페이지 접수<br /></div>
                <div data-layer="4f6bb23d-0888-4c84-b953-11d90af55793" className="x3e5c0925">지원 방법</div>
                <div data-layer="64123b7d-67fa-4e93-b4e6-3208c8702084" className="linese1202d0f">                    <svg data-layer="719c2863-5b7b-45fc-98c8-1d2d6c857c62" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14875defb76"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="81fc245f-a40d-4434-a987-81703573b7d0" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1422252073e"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="aa56ee75-f611-4f30-b847-b62b60ff99a4" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1492c2daf8c"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="d65d8150-8c87-4f69-9e02-74314a8dee07" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1435b510cc8"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="b8dde110-c501-4d14-bb49-56e55ffdb3cc" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1448c9212b0"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="ffb3e914-8a52-40c7-8b82-cb19d37e83f0" className="xHr"><span className="xHr-0"><br />채용문의 : </span><span className="xHr-8">hr@smartstudygames.com</span></div>
            <div data-layer="a613531d-bb0c-4e63-8ece-713d5d115f2e" className="x10987">                <div data-layer="d5a92d90-ad9f-4846-b474-b8dbdcca813b" className="xUiUi3Ui">그래픽 (포토샵, 일러스트레이터)툴들을 능숙히 다룰 수 있는 분<br />UI 설계 및 인터렉션 디자인에 대한 이해가 있는 분<br />UI 리소스 드로잉 및 이미지 편집에 능한 분<br />3년 이상의 모바일 게임 UI 디자인 경력</div>
                <div data-layer="54af25e8-7c3b-4abc-a4b7-b9787c8747ec" className="xafc272b4">자격 요건</div>
                <div data-layer="fcb7a5ae-b729-4717-964c-8a735f58c535" className="lines81a8544f">                    <svg data-layer="7c8f9b54-ce19-42ff-8304-dc09d63e9b2d" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x14868ca76fd"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="2e005ca8-2070-4d85-bc7d-9f483a4db75e" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142830350e7"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="b6f53565-5406-4fd1-a95b-0333016e78a6" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149e5b65eeb"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="bbd15b42-d7e0-4e47-b44d-74de9eba421f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x1438c832225"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="5e530cfe-06f6-4109-8fcd-3bbf52fd0e0a" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x1444b2c1904"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="d536c2cc-4567-4e19-8683-e78f182a0751" className="x10985">                <div data-layer="2ce3d3d6-205c-4f5f-8b29-1f4141cfc114" className="xRpgUiUnity">캐주얼 또는 반실사 풍 RPG의 UI 제작 경험<br />모바일 게임 프로젝트 라이브 서비스 경험<br />Unity 사용 경험</div>
                <div data-layer="c94713dc-709e-47b1-9505-577a3d7f1b7b" className="xd322e79e">우대 사항</div>
                <div data-layer="dc1a5778-b34b-4326-b87f-534661ae8061" className="lines4682004c">                    <svg data-layer="2fede9af-a006-494b-9e36-3a62370e12a5" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1486c09a41b"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="afca6067-8629-4197-bee2-7c2ff8186304" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x1421a4bc099"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="e1fd0e39-70b6-4d08-9f37-8f87a5196000" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149b1009b02"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="f527f724-5d64-40e9-8bb4-09080c35d6ea" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143d5c9e51f"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="89a3ff88-a6da-41c1-a8dc-cb9d262dfd8f" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144db91b814"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
            <div data-layer="575e5d09-6a9a-4dee-a204-1afe9b83c3ba" className="x11012">                <div data-layer="0aee17db-c39f-4fd8-a4c0-3f1619afa828" className="xLUi">글로벌 서비스를 목표로 하며 하늘세계를 배경으로 하는<br />모바일 게임 프로젝트 L의 UI 디자인 및 제작</div>
                <div data-layer="95d487b9-04e1-44e8-9ac6-adb8b23a52fe" className="x">주요 업무</div>
                <div data-layer="d0741111-d9ab-4b1b-bee3-4b3778267eb1" className="lines">                    <svg data-layer="d8890e51-313d-4802-a5f6-7956a729234b" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x148"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="86168398-03cc-4910-a4a3-d845f64dc564" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 143.19140625 3.18994140625" className="x142"><path d="M 0 0 L 140 0"  /></svg>
                    <svg data-layer="15c28723-a933-4274-bbf2-3116e57eebeb" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x149"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="5c03c25e-735f-44a9-a47b-d71cc156e6cd" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 173.19140625 3.18994140625" className="x143"><path d="M 0 0 L 170 0"  /></svg>
                    <svg data-layer="14ab8b78-a3f9-471e-9733-6790522a9b48" preserveAspectRatio="none" viewBox="-1.595703125 -1.594970703125 47.19140625 3.18994140625" className="x144"><path d="M 0 0 L 44 0"  /></svg>
</div>
</div>
</div>
</div>

    );
  }
}

Mjdmobileui.propTypes = {

}

Mjdmobileui.defaultProps = {

}


export default Mjdmobileui;
          