
import React from "react";
import PropTypes from "prop-types";
import { HashLink as Link } from 'react-router-hash-link';
import VideoLocalPlayer from "../../videoLocalPlayer";

import MheaderImpl from "../../mheader_impl";
import "./msupport.css";

class Msupport extends React.Component {

  constructor(props) {
      super(props);
      this.state = {

      };
  }
  
  handleClick(target) {
    if (this.props.onClick) {
        let name;
        let id;
        let index = -1;
        if (target.search("::") > -1) {
            const varCount = target.split("::").length;
            if (varCount === 2) {
                name = target.split("::")[0];
                id = target.split("::")[1];
            } else if (varCount === 3) {
                name = target.split("::")[0];
                index = parseInt(target.split("::")[1]);
                id = target.split("::")[2];
            }
        } else {
            name = target;
        }
        this.props.onClick({ type: 'button', name: name, index: index, id: id });
    }
  }
    
  render() {
    
    return (
          <div data-layer="2209faa6-2953-453d-8e71-68f96e3c9a1c" className="msupport">        <div data-layer="57c0d891-5673-4469-832d-a392d32bbccc" className="footer">            <div data-layer="ad2c7636-44ce-44cb-bd8f-1d3fb1cfd775" className="x2252"></div>
            <div data-layer="c1c77e05-9b16-4540-beec-d559ea7d619b" className="smartStudyGamesCoLtdAllRightsReserved">Smart Study Games Co., Ltd. All rights reserved.</div>
            <div data-layer="3a7879c8-76e4-4be8-a6eb-bb3d481890e9" className="logosmartstudygames2500x64017188a9f"></div>
</div>
        <div data-layer="171c5c7b-c618-4dca-978c-5e4571748de3" className="x1e3b308c">            <div data-layer="ccef6c06-e9a9-4c4d-85ae-fd260c3da0c5" className="x26f9ad74"></div>
            <Link data-layer="98502d21-737d-4129-8667-3d831bd0e36d" className="x10946" to='/humanres'>                <div data-layer="43b6f0f1-728a-40c3-92b4-819ab17e966c" className="xf68560b2">혼자하면 망상, 모이면 꿈, <br />이루면 현실</div>
                <div data-layer="a711410d-11e0-4ba9-8d0b-152b2171182f" className="x17584"></div>
                <div data-layer="118e4cfc-1cd8-47a9-b127-165b8b5c623c" className="x">인재상 알아보기</div>
</Link>
</div>
        <div data-layer="41a9545a-9a1b-4726-bc53-54e75431d07e" className="contentbody">            <svg data-layer="21e887f6-e29f-47a4-b221-daf1baebb611" preserveAspectRatio="none" viewBox="0 -0.5 751 1" className="x37"><path d="M 0 0 L 751 0"  /></svg>
            <svg data-layer="03e7c226-4b86-49e5-b83e-e764c660a101" preserveAspectRatio="none" viewBox="0 0 750 4291.1298828125" className="twi001t3226289l"><defs><pattern id="img-twi001t3226289l" patternContentUnits="userSpaceOnUse" width="100%" height="100%"><image xlinkHref={require('./assets/twi001t3226289l.png')} x="0" y="0" width="750.00px" height="4291.13px" /></pattern></defs><path d="M 0 0 L 750 0 L 750 4291.1298828125 L 361.0849609375 4291.1298828125 L 0 4291.1298828125 L 0 0 Z" fill="url(#img-twi001t3226289l)" /></svg>
            <div data-layer="09fab17b-8255-477f-897e-dc954caf35ab" className="artwallbg"></div>
            <div data-layer="15266bf4-5088-4e51-a286-d88ff988043b" className="imagesliderculture">                <div data-layer="8844f59b-6db1-457d-bf40-2da4ef3678ba" className="x8b890189">(스마트스터디 스크린샷 설명입니다)</div>
                <div data-layer="8b582d85-3366-489f-9933-0455696f7d11" className="x3">                    <div data-layer="a709c1d1-4503-4499-adb7-d581ffc2a24a" className="imageview"></div>
                    <div data-layer="d15101af-6469-4729-b4e2-e76e409b0f66" className="imageview65f0d4d9"></div>
                    <div data-layer="7dc70b8b-9261-469a-81ac-f21c0043e27b" className="imageview5d2f0986"></div>
                    <div data-layer="930a64e8-5089-4180-a373-4b35e23329ab" className="imageview53492fed"></div>
                    <div data-layer="be9a8cc4-729e-4352-980c-26f798bce2b0" className="imageviewd6ade0f5"></div>
                    <div data-layer="6317066b-d9f5-4c11-ade4-a6c8441f6944" className="imageviewf177cfad"></div>
                    <div data-layer="c5fc45a4-f349-4a21-98c0-208b8db9f71d" className="imageview91571c8e"></div>
</div>
</div>
            <div data-layer="f37f83d6-a336-45ff-beae-82c1e9861089" className="xc46dd92b"><span className="xc46dd92b-0">스마트스터디게임즈<br /></span><span className="xc46dd92b-10">스스데이 엿보기</span></div>
            <div data-layer="af1f5016-1885-42ff-9464-0f221f2944c8" className="x90afb7db">                <div data-layer="3369a584-0a41-426a-997c-1d54778b6c60" className="x532c576a">                    <div data-layer="a3f3a446-2d01-49f1-b07c-65c9825aee16" className="x11028">                        <div data-layer="da443f84-bde3-476f-8260-02b934e6f610" className="x8c54544a">나만의 캐릭터 명함, 관계사 할인, 쾌적한 하드웨어, 온보딩 퀘스트, 세미나 지원, 성과급, 명절선물 지급, 경조사, 업무 관련 도서, 야근식대, 야근택시, 건강검진비 일부 지원 등  </div>
                        <div data-layer="74537618-aed5-4f17-92a2-36fa6809b952" className="xb2a5c788">당연히 이것도 포함</div>
</div>
                    <svg data-layer="8ff04b5b-03e8-48ca-a8d7-28fa306a5ea5" preserveAspectRatio="none" viewBox="0 0 166 166" className="x227"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="88658695-fb0b-4671-9ebc-4963be17bfc4" className="x10911"></div>
</div>
                <div data-layer="a8809248-c299-4b3d-9f00-e5e72c67c81f" className="xe0a3b0a6">                    <div data-layer="8cdc08a8-4ff5-4c7b-a819-6a45905a6a9c" className="x11026">                        <div data-layer="87fb1b5b-4131-4664-bb89-eed976ac0275" className="x737bfbb7">음료 한잔으로 미팅을 더욱 더 활발하게 진행해보세요. 일하다 배가 고파질 땐, 스낵바에서 무제한으로 제공되는 간식으로 재충전하세요.</div>
                        <div data-layer="5c6b5490-7dbe-4b94-af68-25dcca44c419" className="x7abbbfbd">미팅 음료 및 스낵바</div>
</div>
                    <div data-layer="99515bae-f395-4f9c-8eeb-902ad8f54bd4" className="x10902"></div>
                    <svg data-layer="7a2af0c6-479b-4b4f-a19d-cd433a204171" preserveAspectRatio="none" viewBox="0 0 166 166" className="x225"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="6433455d-397a-4875-9db9-ee5114b3d81a" className="x10897"></div>
                    <div data-layer="14773bf6-1490-4398-873d-98218e26bfd9" className="x7960b1e7"></div>
</div>
                <div data-layer="e12bff09-9f78-4be7-9927-c251c4d9d4b3" className="x5480268e">                    <div data-layer="e42d1924-850e-44f2-bc5f-2a561936e0a8" className="x11025">                        <div data-layer="2e0b7fb2-ca3c-413a-89bc-c5835f279646" className="x9023cbcb">연차와 경력에 상관없이, 업무 능력에 따라 리드 및 시니어 레벨로 대우합니다. 우수한 사람에게 더 많은 권한을 드려요. 자율과 책임은 우리의 중요한 가치입니다. </div>
                        <div data-layer="dbd6a71a-1f80-4fc2-b08d-c32e3271d50b" className="x3af6a7bf">업무 능력에 따른 파격적인 대우</div>
</div>
                    <svg data-layer="30dc3b41-d916-402e-9682-71c2087d2c97" preserveAspectRatio="none" viewBox="0 0 166 166" className="x221"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="079cd063-a6d6-464f-a86a-c2761455dc24" className="x10886"></div>
</div>
                <div data-layer="fe8d92a3-faa5-47cb-892b-9a855bf1755c" className="x727d1c11">                    <div data-layer="e56bbcef-77cb-4ff3-830b-164fb02d7491" className="x11024">                        <div data-layer="c7906767-b6bc-427f-a0fa-4a8db94088a3" className="x7c8659f7">새로운 구성원의 합류를 축하합니다! 수습 기간 후 정식으로 합류하게 된 구성원들에게는 특별한 선물과 감사 카드를 드려요.</div>
                        <div data-layer="1c8fb2f7-5136-4cb8-b0dd-21409edbfbfe" className="x45a6cea7">수습 통과 선물</div>
</div>
                    <svg data-layer="6ef584a8-7723-46b6-bcb8-e6183d702043" preserveAspectRatio="none" viewBox="0 0 166 166" className="x223"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="9bf02658-f50b-4885-947f-64525a685392" className="x10920"></div>
                    <div data-layer="fde7d803-3b55-4490-940d-849dce6cac59" className="xb9343343"></div>
</div>
                <div data-layer="0be78274-1310-437d-abdc-68380f9c32c6" className="xdfd5a375">                    <div data-layer="736ec649-ad64-4a92-adaa-af24c2edef6b" className="x11023">                        <div data-layer="e53f7599-503f-4126-9802-0193537f0840" className="x4">다른 구성원들과 즐거운 시간도 우리의 경쟁력입니다. 4명 이상의 동료를 모아 매달 한 번씩 특별한 활동을 기획해 보세요. 회사에서의 지원금은 덤! </div>
                        <div data-layer="b378abef-42b3-4634-9707-597535f07a16" className="xb2ad2089">스스데이</div>
</div>
                    <svg data-layer="4afcea09-831b-4e3e-99a7-7eeb6e72ee47" preserveAspectRatio="none" viewBox="0 0 166 166" className="x226"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="52737d40-51a3-4582-b095-f81aab8473f7" className="x10910"></div>
                    <div data-layer="7c2bf3e6-fb37-4841-8348-790de74fae2a" className="xa8f47731"></div>
</div>
                <div data-layer="ed98793f-b7c3-4cfa-8d79-5201bc2557eb" className="x9163a04f">                    <div data-layer="7615d097-e171-4b34-8a13-f42458d4ac68" className="x11027">                        <div data-layer="a24559ff-1634-48b3-9208-e13e3b6d7939" className="x4249ecfe">멋지고, 실현 가능성 있는 아이디어라면, 얼마든지 신규 프로젝트를 진행할 수 있어요. 다른 구성원들의 지원과 협업을 이끄는 프로젝트의 리더가 되어보세요.</div>
                        <div data-layer="bab9cfa1-0ea8-4648-851b-a42ff04cf376" className="x024d36e4">구성원이 주도하는 신규 <br />프로젝트 기회</div>
</div>
                    <svg data-layer="d8e3fa15-35be-4c2d-ba4c-a6ddabd5d1be" preserveAspectRatio="none" viewBox="0 0 166 166" className="x219"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="c2e81a09-33a5-43de-a178-d3b3485c7528" className="x10864"></div>
</div>
                <div data-layer="d987f700-492b-475e-8aa5-4248bed5a4f5" className="x4188b8cf">                    <div data-layer="8855935f-1fce-4152-b5ca-17fed5d12860" className="x11022">                        <div data-layer="0d415b8d-5dc4-4329-9232-a260ddc24aeb" className="x0911">09시와 11시 사이, 내가 가장 효율적으로 일할 수 있는 시간에 출근하세요. 충분한 커뮤니케이션이 된다면 더 일찍, 더 늦게 출근할 수 있어요.</div>
                        <div data-layer="422d1619-13f9-44d9-ba76-0901e1a75492" className="x250e5ec1">유연 출퇴근</div>
</div>
                    <svg data-layer="96968fe4-3e8e-48fe-8117-edcca0f5fe3e" preserveAspectRatio="none" viewBox="0 0 166 166" className="x222"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="baad88f2-ef58-4bcd-8f07-0ae5c57261b9" className="x10895"></div>
                    <div data-layer="42f31b5b-8722-4d14-a64b-1e85dd93998a" className="x33d4325d"></div>
</div>
                <div data-layer="5ae10874-dd7a-4806-93b7-9cbab6576503" className="x6d0c7f63">                    <div data-layer="4da76845-fd00-4ee2-ab6f-2696120fa88c" className="x11021">                        <div data-layer="205ab2cb-59e5-42de-b366-46341a061b74" className="xb91fb98e">원격근무</div>
                        <div data-layer="75dbfbc7-7249-49e8-b6d2-a845fe08bee5" className="x8b4128dd">업무 효율성을 유지할 수 있다면 사무실이 아니어도 어디서든 일할 수 있어요.</div>
</div>
                    <svg data-layer="43a31d16-68a1-487c-b0fa-3b5d317be301" preserveAspectRatio="none" viewBox="0 0 166 166" className="x220"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="c00cb541-65cf-4037-8120-c7bd12aa8e6e" className="x10877"></div>
                    <div data-layer="c8daf0fe-310b-4cfe-a908-848b62500ac2" className="xf94b00f4"></div>
</div>
                <div data-layer="89bb406a-77d3-4246-b00f-ded316de8dc3" className="x721c5569">                    <div data-layer="10cdc943-0eec-4cb6-8c21-08c765563bbf" className="x11021a8373fce">                        <div data-layer="19380edc-8e48-4b67-8be8-5171eb39f69d" className="xd75de240">별명+님 호칭</div>
                        <div data-layer="39dfcfa8-73e3-42b5-925b-6ac070ba839b" className="x3d2e5773">우리는 각자의 개성을 존중하며, 모든 사람이 자유롭게 의견을 이야기할 수 있기를 바라요. </div>
</div>
                    <svg data-layer="11792f65-3f6d-4f62-b260-651cd9f51786" preserveAspectRatio="none" viewBox="0 0 166 166" className="x2203b4681a7"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="fe94fb00-51d6-4458-82df-b841820c67cb" className="x1087751c112d6"></div>
</div>
                <div data-layer="877391d5-6c7d-45bb-a95a-650420a2ccdf" className="x4d920768">                    <div data-layer="33e5e45c-3b32-49f7-8efb-97f99186293e" className="x11020">                        <div data-layer="bb8c455d-b3bb-4e59-90c2-4310165c0002" className="x23"><span className="x23-0"></span><span className="x23-0">연차 일수를 계산할 필요 없이 휴가를 떠날 수 있어요. 동료의 동의가 된다면 2~3주의 장기 휴가도 가능해요.</span></div>
                        <div data-layer="0c384160-2794-4f34-8db6-e20e57a8865f" className="x19a0a019">무제한 휴가 </div>
</div>
                    <svg data-layer="a7e38b65-1052-47f3-8564-690cccd07366" preserveAspectRatio="none" viewBox="0 0 166 166" className="x218"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="c3c97dd2-b5ba-4a3e-b31d-e3bcfce36d8b" className="x10865"></div>
</div>
                <div data-layer="683ffb4a-e885-4d77-9ca6-0145824d6c6e" className="xcd72e398">                    <div data-layer="721c71e9-e62e-4237-ab88-e498ff627ba2" className="x11019">                        <div data-layer="7e0cf883-3b0f-40af-b2dd-8d9d9f84ab2c" className="x3aad27e5">수평적, 자율적 의사 결정에는 그만큼 더 많은 정보가 필요합니다. 우리는 의사 결정에 필요한 정보들을 가감없이 모든 구성원들에게 공유해요.</div>
                        <div data-layer="0518cb2c-e82f-400f-bde8-63b78a84b4b6" className="x37c6b350">투명한 정보 공유</div>
</div>
                    <svg data-layer="311c5b08-63b4-4e77-b7d4-601679821725" preserveAspectRatio="none" viewBox="0 0 166 166" className="x217"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="d634ebea-3f0a-49f4-8b11-ff5de3d7a085" className="x90ff31ed"></div>
                    <div data-layer="53efc5ef-1dd2-4f5f-b940-c78f529c8027" className="x10840"></div>
</div>
                <div data-layer="71233a49-17af-4a53-864c-1483e066c6d4" className="x770c3176">                    <div data-layer="d6a6d374-559d-4e19-bdf0-a4c74f623b01" className="xd98c36fe">                        <div data-layer="2e7e24c6-1748-4b6b-ac44-064caf1418d9" className="x4da077f3">우리에게는 신뢰와 자율성이 가장 중요한 가치입니다. 서로를 신뢰하며,  자율적으로 일할 때 더 나은 성과와 즐거움을 느낄 수 있다고 믿는 사람들입니다. </div>
                        <div data-layer="70cc07db-f7f2-41ae-a7da-07706a52ef9d" className="x8e0e41d6">자율과 책임</div>
</div>
                    <svg data-layer="d58e1737-1a1d-4281-868c-a1a6e91a6b7e" preserveAspectRatio="none" viewBox="0 0 166 166" className="x28"><path d="M 83 0 C 128.8396301269531 0 166 37.16036987304688 166 83 C 166 128.8396301269531 128.8396301269531 166 83 166 C 37.16036987304688 166 0 128.8396301269531 0 83 C 0 37.16036987304688 37.16036987304688 0 83 0 Z"  /></svg>
                    <div data-layer="5eca072e-16d8-42e3-81e7-42d29cd304ea" className="xImg"></div>
</div>
</div>
            <div data-layer="2c1e7add-437f-4e43-a850-b821f2f641d9" className="xcbe2c136"><span className="xcbe2c136-0">스마트스터디게임즈 </span><span className="xcbe2c136-10">사내지원</span></div>
            <div data-layer="a985d2d9-944d-4ca0-a3f8-1e5d6687d974" className="x5ef5202f">스마트스터디게임즈가 전 과정을 지원합니다!</div>
            <div data-layer="f80c1291-09ee-4c79-b393-540979137685" className="x67886fbc">                <div data-layer="c60458a4-b789-45ae-9c12-b67066182296" className="x16"></div>
                <VideoLocalPlayer videosrc='./assets/support_top.mp4' top='0' originWidth='1920' originHeight='1080' viewHeight='1440' targetWidth='2560px' targetHeight='1440px' minWidth='1440px'>                    <div data-layer="cd2e10cf-d1dd-467e-96cf-d4202063f9b5" className="dummy"></div>
</VideoLocalPlayer>
                <div data-layer="b540cab1-f7c6-44e6-ac39-e915ac4076ea" className="maskbg"></div>
                <div data-layer="4f51c002-1cec-4e86-8cfb-f1ab39645c96" className="x5c7431d7">                    <div data-layer="f290f2e9-b138-4b82-8a7d-e67691630c19" className="x0e186f94">사내지원</div>
                    <div data-layer="70cb5422-43c6-4ddf-b5c9-c5de9daedf04" className="xcd092b7a">스마트스터디게임즈가 지원합니다!</div>
</div>
</div>
</div>
        <MheaderImpl><div data-layer="a98fa089-72e7-40ea-b980-bb752f77b39a" className="header">            <div data-layer="4172cd57-30aa-4cd5-ba00-4b614b742735" className="logosmartstudygames2500x640"></div>
            <div data-layer="981c2cd2-5222-4d32-ad5b-576fa2f1f25a" className="navibar">                <div data-layer="2cbfa17a-945e-4b33-b80d-912794236e49" className="x17579"></div>
                <div data-layer="bee58fde-354c-4c01-a8c2-e839951864e2" className="x17580"></div>
                <div data-layer="c0d7ab51-f590-4865-bc32-f3764890a5bb" className="x17594"></div>
                <div data-layer="7f233abe-1d84-433a-8dc1-bc1232a8a5c1" className="x17638"></div>
</div>
</div></MheaderImpl>
</div>

    );
  }
}

Msupport.propTypes = {

}

Msupport.defaultProps = {

}


export default Msupport;
          